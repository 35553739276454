import { Dialog } from "quasar"
import { createEventHook } from "@vueuse/core"
import { Component } from "vue"

export const useModal = (component: Component) => {
  const okHook = createEventHook()
  const cancelHook = createEventHook()
  const hideHook = createEventHook()
  const dialog = shallowRef()

  const show = (params = {}) => {
    dialog.value = Dialog.create({
      component: component,
      componentProps: params,
    })
      .onOk((data) => okHook.trigger(data))
      .onCancel(() => cancelHook.trigger(undefined))
      .onDismiss(() => hideHook.trigger(undefined))
  }

  const hide = () => {
    dialog.value?.hide()
  }

  return {
    show,
    hide,
    onOk: okHook.on,
    onCancel: cancelHook.on,
    onHide: hideHook.on,
  }
}
