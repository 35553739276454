import { defineStore } from "pinia"

export const useQuickTillStore = defineStore("quickTill", () => {
  const enabled = ref(localStorage.getItem("quickTillEnabled") === "true")
  console.log("store inited", enabled)

  const setEnabled = (v: boolean) => {
    enabled.value = v
    localStorage.setItem("quickTillEnabled", String(v))
  }

  return {
    enabled,
    setEnabled,
  }
})
