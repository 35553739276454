<template>
  <QLayout view="hHh lpr fff" container>
    <QHeader :class="$style.header">
      <QBtn
        :class="$style.backButton"
        unelevated
        flat
        color="white"
        @click="emit('cancel')"
      >
        <QIcon name="arrow_back" />
      </QBtn>
      <h2 :class="$style.heading">
        <slot name="title" />
      </h2>
    </QHeader>

    <QPageContainer>
      <QPage :style-fn="styleFunction" :class="$style.page">
        <slot> </slot>
      </QPage>
    </QPageContainer>

    <template v-if="$slots.bottom">
      <QFooter :class="$style.footer" elevated>
        <slot name="bottom" />
      </QFooter>
    </template>
  </QLayout>
</template>

<script setup>
const emit = defineEmits(["cancel"])

const styleFunction = (offset, height) => {
  const pageHeight = height - offset
  return `height: ${pageHeight}px`
}
</script>

<style lang="scss" module>
.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 3rem;
  padding: 0;
  overflow: hidden;
  background-color: #65626b;
}

.backButton {
  position: absolute;
  left: 0;
  height: 100%;
  color: white;
}

.heading {
  display: block;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  max-width: 70%;
  margin: 0 auto;
  font-size: $text-lg;
  font-weight: bold;
  line-height: 1.5em;
  color: white;
  text-align: center;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.footer {
  background-color: white;
}

.page {
  overflow: auto;
}
</style>
