<template>
  <div :class="$style.container">
    <QuickTillLineItems />

    <QuickTillInput v-model.number="currentItem" :class="$style.quickTillInput">
      <template
        v-for="(product, position) in productPositions"
        v-slot:[`position-${position}`]
        :key="product.id"
      >
        <template v-if="product">
          <!-- <template v-if="+position === 0">
            <QuickTillProductButton
              :color="product.color"
              plus-icon-size="3.5em"
              :disabled="!isProductClickEnabled(product)"
              @click="addItemToCart(product)"
              @longpress="
                onPositionLongpress({ positionId: +position, product: product })
              "
            >
              {{ product.name }}
            </QuickTillProductButton>
          </template>
          <template v-else> -->
          <QuickTillProductButton
            :color="product.color"
            plus-icon-size="1.8em"
            :disabled="!isProductClickEnabled(product)"
            @click="addItemToCart(product)"
            @longpress="
              onPositionLongpress({ positionId: +position, product: product })
            "
          >
            {{ product.name }}
          </QuickTillProductButton>
          <!-- </template> -->
        </template>
        <template v-else>
          <QuickTillEmptyPositionButton
            :disabled="!isEmptyPositionClickEnabled"
            @longpress="
              onPositionLongpress({ positionId: +position, product: product })
            "
          />
        </template>
      </template>
    </QuickTillInput>

    <div elevated :class="$style.footer">
      <QBtn
        color="primary"
        unelevated
        :class="$style.payNowButton"
        type="submit"
        no-caps
        :disabled="!shoppingCart.isValid"
        :loading="productsLoading"
        @click="payNow"
      >
        Pay {{ shoppingCart.totalCostAsString }}
      </QBtn>
    </div>
  </div>
</template>

<script setup>
import { useQuickTill } from "@/composables/quickTill"
import { usePageTitle } from "@/composables/pageTitle"
import { useQuasar } from "quasar"
import { BaseApi } from "@/api"
// importing CreateOrderFormModal breaks vite's hmr
import CreateOrderFormModal from "@/components/forms/CreateOrderFormModal.vue"

import { useShoppingCartStore } from "@/pinia/shoppingCart"

import { useQuickTillPositionModal } from "@/components/modals/QuickTillPositionModal/useQuickTillPositionModal"
import { useQuickTillPositions } from "@/composables/useQuickTillPositions"
import { useQuickTillProducts } from "@/composables/useQuickTillProducts"

import { useQuickTillMultiplierModal } from "@/components/modals/QuickTillMultiplierModal/useQuickTillMultiplierModal"

import { useCashierStore } from "@/pinia/cashier"

const cashierStore = useCashierStore()
const quickTillProducts = useQuickTillProducts()
const quickTillPositions = useQuickTillPositions()
const emit = defineEmits(["ok", "cancel", "checkout"])

const { lineItems } = useQuickTill()

const currentItem = ref(0)

const shoppingCart = useShoppingCartStore()

const addItemToCart = (product) => {
  if (!currentItem.value) return
  shoppingCart.addItem({
    product: product,
    priceOverride: currentItem.value,
  })
  currentItem.value = 0
}

const { setPageTitle } = usePageTitle()
setPageTitle("Quick Till")
// watch(
//   lineItems,
//   (v) => {
//     if (v.length === 0) {
//       setPageTitle("Items")
//     } else {
//       setPageTitle(`Items: ${v.length}`)
//     }
//   },
//   { immediate: true }
// )

// pay now button
const $q = useQuasar()
const paying = ref(false)

const quickTillPositionsWithProducts = computed(
  () => quickTillPositions.withProducts
)

// put in composable?
const quickTillProduct = ref({})
const productsLoading = ref(false)

const unsetPositions = [0, 1, 2, 3, 4]
const productPositions = computed(() => {
  return Object.fromEntries(
    unref(unsetPositions).map((value) => [
      value,
      quickTillPositionsWithProducts.value.find(
        ({ positionId }) => positionId === value
      )?.product,
    ])
  )
})

const payNow = () => {
  paying.value = true

  $q.dialog({
    component: CreateOrderFormModal,
  })
    .onDismiss(() => {})
    .onOk(() => {})
}

const refreshData = () => {
  quickTillPositions.revalidate()
  quickTillProducts.revalidate()
}

const addMultipleProductsToCart = (product, quantity) => {
  for (let index = 0; index < quantity; index++) {
    shoppingCart.addItem({
      product: product,
      priceOverride: currentItem.value,
    })
  }
  currentItem.value = 0
}

const onPositionLongpress = ({ positionId, product }) => {
  if (
    !shoppingCart.hasItems &&
    !currentItem.value &&
    cashierStore.currentCashier.hasEditPermission
  ) {
    addProductToPosition(positionId)
  } else if (currentItem.value) {
    const quickTillMultiplierModal = useQuickTillMultiplierModal()
    quickTillMultiplierModal.onOk((number) =>
      addMultipleProductsToCart(product, number)
    )
    quickTillMultiplierModal.show({ productName: product?.name, price: currentItem.value })
    // Multiple select

    return
  } else {
    // Do nothing
    return
  }
}

const quickTillPositionModal = useQuickTillPositionModal()
quickTillPositionModal.onOk(() => refreshData())
const addProductToPosition = (positionId) => {
  quickTillPositionModal.show({ positionId })
}

onMounted(() => {
  quickTillPositions.reloadFromCache()
  quickTillProducts.reloadFromCache()
})

const isProductClickEnabled = () => {
  return currentItem.value || canEditProducts.value
}

const canEditProducts = computed(
  () => !shoppingCart.hasItems && !currentItem.value
)

const isEmptyPositionClickEnabled = computed(() => canEditProducts.value)
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: inherit;
  min-height: inherit;
  background-color: white;
}

.footer {
  width: 100%;
  padding: 0.5rem;
}

.payNowButton {
  width: 100%;
  // padding: 0.125rem 0.25rem;
  margin: auto;
  font-size: $text-lg;
  font-weight: 700;
  border: 2px;
  border-radius: 0.5rem;
  box-shadow: 1px 2px 3px #65626b;
}

.subtotal {
  padding-top: 8px 0;
  font-size: 20px;
  font-weight: bold;
  color: #b20b63;
  text-align: center;
}

.quickTillInput {
  padding: 0.5rem;
  // // height: 20rem;
  // margin-bottom: 0.5rem;
}

.productContainer {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.25rem;
  width: 100%;
  padding: 0.25rem;
}
</style>
