<template>
  <QDialog ref="dialogRef" @hide="onDialogHide">
    <QCard :class="['q-dialog-plugin', $style.container]">
      <QCardActions align="right">
        <QIcon name="close" class="cursor-pointer" @click="onCancelClick" />
      </QCardActions>
      <QForm @submit="onOKClick">
        <QCardSection horizontal>
          <QCardSection :class="$style.fieldTitleContainer">
            <h1 :class="$style.fieldTitle">Uncollected only:</h1>
          </QCardSection>
          <QCardSection :class="$style.fieldOptionsContainer">
            <QToggle v-model="readyOnlySelection" />
          </QCardSection>
        </QCardSection>
        <QCardSection horizontal>
          <QCardSection :class="$style.dateTitle">
            <h1 :class="$style.fieldTitle">Date:</h1>
          </QCardSection>
          <QCardSection :class="$style.fieldOptionsContainer">
            <QInput
              v-model="dateSelection"
              :rules="dateRules"
              mask="date"
              dense
            >
              <template v-slot:append>
                <QIcon name="event" class="cursor-pointer">
                  <QPopupProxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <QDate v-model="dateSelection">
                      <div class="row items-center justify-end">
                        <QBtn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </QDate>
                  </QPopupProxy>
                </QIcon>
              </template>
            </QInput>
          </QCardSection>
        </QCardSection>
        <QCardActions align="right">
          <QBtn color="accent" flat label="Clear All" @click="clearSelection" />
          <QBtn
            text-color="accent"
            color="grey-4"
            unelevated
            label="OK"
            type="submit"
            padding="xs lg"
          />
        </QCardActions>
      </QForm>
    </QCard>
  </QDialog>
</template>

<script>
import { useDialogPluginComponent } from "quasar"
</script>

<script setup>
const props = defineProps({
  readyOnly: {
    type: Boolean,
    default: false,
  },
  date: {
    type: [String, undefined],
    default: undefined,
  },
})
const emit = defineEmits(["ok", "hide"])
const readyOnlySelection = ref(false)
const dateSelection = ref()
const dateRules = ref([(v) => !v || /^-?\d+\/[01]\d\/[0-3]\d$/.test(v)])

onMounted(() => {
  // Dont want to drectly mutate parent state
  readyOnlySelection.value = props.readyOnly
  dateSelection.value = props.date ? props.date.replaceAll("-", "/") : undefined
})

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent()

const onOKClick = () => {
  onDialogOK({
    readyOnly: readyOnlySelection.value,
    date: dateSelection.value
      ? dateSelection.value.replaceAll("/", "-")
      : undefined,
  })
}
const onCancelClick = () => {
  onDialogCancel()
}
const clearSelection = () => {
  readyOnlySelection.value = false
  dateSelection.value = undefined
}
</script>

<style lang="scss" module>
.container {
  padding: 10px;
  border: 2px solid $accent;
}

.fieldTitle {
  margin: auto;
  font-size: $text-md;
  text-align: right;
}

.fieldTitleContainer {
  flex: 1;
  padding: 0;
}

.fieldOptionsContainer {
  flex: 2;
}

.dateTitle {
  flex: 1;
  padding-right: 0;
  padding-left: 0;
}
</style>
