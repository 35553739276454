import { createRouter, createWebHistory } from "vue-router"
import { Capacitor } from "@capacitor/core"

import store from "@/store"

import AccountRoutes from "./account"

import Referrals from "@/views/Referrals.vue"
import Help from "@/views/Help.vue"

import LogIn from "@/views/LogIn.vue"
import OrdersList from "@/views/orders/OrdersList.vue"
import LegacyOrdersList from "@/views/orders/LegacyOrdersList.vue"
import Kitchen from "@/views/Kitchen.vue"
import TillCategoryView from "@/views/Till/TillCategoryView.vue"
import TillCashierLogin from "@/views/Till/TillCashierLogin.vue"
import QuickTill from "@/views/Till/QuickTill.vue"
import Home from "@/views/Home.vue"
import Delivery from "@/views/orders/Delivery.vue"
import Collection from "@/views/orders/Collection.vue"
import TerminalLogIn from "@/views/TerminalLogIn.vue"

import BusinessAlerts from "@/views/reports/BusinessAlerts.vue"
import RevenueReport from "@/views/reports/RevenueReport.vue"
import TopSellersReport from "@/views/reports/TopSellersReport.vue"
import TodayReport from "@/views/reports/TodayReport.vue"

import MerchantLayout from "@/layouts/MerchantLayout.vue"

import DeviceLayout from "@/layouts/DeviceLayout.vue"
import TillLayout from "@/layouts/TillLayout.vue"

import PortalLogin from "@/views/PortalLogin.vue"
import HandleLoginLink from "@/views/HandleLoginLink.vue"

import { useCashierStore } from "@/pinia/cashier"
import { useQuickTillStore } from "@/pinia/quickTillStore"
import { useTillConfig } from "@/composables/useTillConfig"

const LoadHomeState = (to, from, next) => {
  if (store.state.tables.tables.length === 0) {
    store.dispatch("tables/fetchTables")
  }
  if (!store.state.products.nextMenuTime) {
    store.dispatch("products/fetchProducts")
  }
  store.dispatch("attributes/fetchAttributes")
  return next()
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component:
        import.meta.env.MODE !== "legacy" ? DeviceLayout : MerchantLayout,
      meta: {
        auth: true,
      },
      children: [
        {
          path: "",
          name: "home",
          component: Home,
          meta: {
            auth: true,
          },
          beforeEnter: (to, from, next) => {
            if (import.meta.env.MODE !== "legacy") {
              const { defaultViewRoute } = useTillConfig()
              next(defaultViewRoute)
            }
            return next()
          },
        },
        {
          path: "delivery",
          name: "delivery",
          component: Delivery,
          meta: {
            auth: true,
          },
        },
        {
          path: "collection",
          name: "collection",
          component: Collection,
          meta: {
            auth: true,
          },
        },
        {
          path: "orders",
          name: "ordersList",
          component: !(import.meta.env.VUE_APP_LEGACY === "true")
            ? OrdersList
            : LegacyOrdersList,
          meta: {
            auth: true,
          },
        },
        {
          path: "referrals",
          name: "referrals",
          component: Referrals,
          meta: {
            auth: true,
          },
        },
        {
          path: "reports",
          name: "alerts",
          component: BusinessAlerts,
          meta: {
            auth: true,
          },
        },
        {
          path: "reports/today",
          name: "todayReport",
          component: TodayReport,
          meta: {
            auth: true,
          },
        },
        {
          path: "reports/revenue",
          name: "revenueReport",
          component: RevenueReport,
          meta: {
            auth: true,
          },
        },
        {
          path: "reports/top-sellers",
          name: "topSellers",
          component: TopSellersReport,
          meta: {
            auth: true,
          },
        },
        {
          path: "help",
          name: "help",
          component: Help,
          meta: {
            auth: true,
          },
        },

        {
          path: "till",
          name: "till",
          component: TillLayout,
          children: [
            {
              path: "cashier-log-in",
              name: "tillCashierLogin",
              component: TillCashierLogin,
              meta: {
                auth: true,
              },
            },
            {
              path: ":categorySlugPath*",
              props: (route) => ({
                navList: route.params.categorySlugPath || [],
              }),
              name: "tillCategoryView",
              beforeEnter: (to, from, next) => {
                const cashierStore = useCashierStore()
                if (!cashierStore.currentCashier)
                  return next({
                    name: "tillCashierLogin",
                    query: {
                      next: to.fullPath,
                    },
                  })

                return next()
              },
              component: TillCategoryView,
              meta: {
                auth: true,
              },
            },
          ],
        },

        {
          path: "quick-till",
          name: "quickTill",
          component: QuickTill,
          beforeEnter: (to, from, next) => {
            const cashierStore = useCashierStore()
            if (!cashierStore.currentCashier)
              return next({
                name: "tillCashierLogin",
                query: {
                  next: to.fullPath,
                },
              })

            return next()
          },
        },

        ...AccountRoutes,
      ],
    },
    {
      path: "/kitchen",
      name: "kitchen",
      component: Kitchen,
      props: (route) => ({ page: route.query.page }),
      meta: {
        auth: true,
      },
    },
    {
      path: "/login",
      name: "log-in",
      component:
        import.meta.env.MODE === "portal"
          ? PortalLogin
          : import.meta.env.VUE_APP_ANDROID === "true"
          ? TerminalLogIn
          : LogIn,
      meta: {
        onlyLoggedOut: true,
      },
      props: true,
    },
    {
      path: "/handle-login",
      name: "handleLogin",
      component: HandleLoginLink,
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  try {
    await store.dispatch("auth/hydrateIfEmpty")
  } catch {
    // Server has 500ed if this happens, so maybe redirect to down for maintenance page?
  }

  // some views require logged out
  if (to.meta.onlyLoggedOut) {
    if (store.state.auth.loggedIn) return next("/")
    return next()
  }

  // if it doesn't require auth, continue
  if (!to.meta.auth) return next()

  // if they're logged in, continue
  if (store.state.auth.loggedIn) return next()

  // attempt to re-auth from session cookie
  await store.dispatch("auth/resetAndRefill")
  if (store.state.auth.loggedIn) return next()

  return to.fullPath == "/"
    ? next({
        name: "log-in",
      })
    : next({
        name: "log-in",
        query: {
          redirectFrom: to.fullPath,
        },
      })
})

export default router
