<template>
  <TillInputField :label="label">
    <QSelect v-bind="$attrs" dense filled bottom-slots />
  </TillInputField>
</template>

<script>
export default {
  inheritAttrs: false,
}
</script>

<script setup>
const props = defineProps({
  label: {
    type: String,
    default: "",
  },
})
</script>

<style lang="scss" module>
.label {
}
</style>
