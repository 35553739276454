<template>
  <QDrawer
    v-bind="$attrs"
    ref="drawerRef"
    side="right"
    bordered
    overlay
    elevated
    :class="$style.drawer"
    :breakpoint="1290"
  >
    <h1 :class="$style.title">Order</h1>
    <div :class="$style.scroll">
      <CartItemsList :class="$style.list" />
    </div>

    <div :class="$style.buttonSectionContainer">
      <div :class="$style.buttonSection">
        <QBtn
          color="primary"
          unelevated
          :class="$style.payButton"
          :disable="!shoppingCart.isValid"
          @click="onPayClick"
        >
          Pay {{ useShoppingCartStore.totalCostAsString }}
        </QBtn>
        <div style="text-align: center">
          <QBtn unelevated :class="$style.cancelButton" @click="cancelOrder">
            Cancel Order
          </QBtn>
        </div>
      </div>
    </div>
  </QDrawer>

  <QDialog
    v-model="showCheckout"
    :no-backdrop-dismiss="modalLoading"
    :no-esc-dismiss="modalLoading"
  >
    <CreateOrderForm @ok="closeOrder" @loading-change="setModalLoading" />
  </QDialog>
</template>

<script setup>
import { App as CapacitorApp } from "@capacitor/app"

import { useShoppingCartStore } from "@/pinia/shoppingCart"
const shoppingCart = useShoppingCartStore()
import { useStore } from "vuex"
const store = useStore()

import { useEventBus } from "@vueuse/core"

const payEventBus = useEventBus("payButton")
const eventBusListener = (eventData) => {
  if (unref(eventData) === "on") {
    showCheckout.value = true
  }
}
onMounted(() => {
  payEventBus.on(eventBusListener)
})

// isDesktop: screen.width >= 866,
const cart = ref(false)
const showCheckout = ref(false)
const modalLoading = ref(false)

const appVersion = ref()

onMounted(async () => {
  try {
    const { version } = await CapacitorApp.getInfo()
    appVersion.value = version
  } catch {}
})
const closeOrder = () => {
  showCheckout.value = false
  cart.value = false
}

const setModalLoading = (state) => {
  modalLoading.value = state
}

const cancelOrder = () => {
  shoppingCart.reset()
  cart.value = !cart.value
}

const drawerRef = ref()
const onPayClick = () => {
  showCheckout.value = true
  drawerRef.value?.hide()
}
</script>

<style lang="scss" module>
.title {
  display: block;
  height: 2rem;
  margin: 0.5rem;
  font-size: 2rem;
  font-weight: 600;
  color: #65626b;
  text-align: center;
}

.buttonSection {
  width: 100%;
  height: 8rem;
  padding: 1rem;
  border: 2px;
  border-radius: 1rem;
  box-shadow: 2px 3px 8px #65626b;
}

.buttonSectionContainer {
  height: 10rem;
  padding: 1rem;
}

.button {
  flex-shrink: 0;
  width: 100%;
  font-weight: 700;
  color: white !important;
  border: 2px;
  border-radius: 0.5rem;
  box-shadow: 1px 2px 3px #65626b;
}

.payButton {
  composes: button;
  margin: 0 auto 0.5rem;
  font-size: $text-md;
}

.cancelButton {
  composes: button;
  width: 80%;
  font-size: $text-base-size;
  background: #9aa6ad;
}

.drawer {
  height: 100%;
}

.scroll {
  height: calc(100vh - 13rem);
  overflow: auto;
}

.list {
  max-width: 300px;
}
</style>
