<template>
  <QCard flat :class="$style.cardStyle">
    <QForm greedy class="fit" @submit="onFormSubmit">
      <DialogLayout @cancel="emit('cancel')">
        <template #title>
          <template v-if="isEdit"> Edit {{ props.initial.username }} </template>
          <template v-else> Create Cashier </template>
        </template>
        <div :class="$style.innerContainer">
          <template v-if="loading">
            <BaseLoadingSpinner />
          </template>
          <template v-else>
            <TillInputText
              v-model="form.username"
              label="Username"
              :rules="rules.name"
            />

            <BaseInputColor
              v-model="form.colour"
              :rules="rules.colour"
              label="Colour"
            />

            <TillInputText v-model="form.pin" label="Pin" />

            <QCheckbox
              v-model="form.hasEditPermission"
              label="Can edit cashiers, products and categories"
            >
            </QCheckbox>
          </template>
        </div>
        <template #bottom>
          <QCardActions align="center" class="q-pa-sm" vertical>
            <QBtn
              color="primary"
              unelevated
              :class="$style.button"
              type="submit"
            >
              Save Cashier
            </QBtn>

            <template v-if="isEdit">
              <QBtn
                color="primary"
                unelevated
                :class="$style.button"
                @click="onDeleteClick"
              >
                Delete Cashier
              </QBtn>
            </template>
          </QCardActions>
        </template>
      </DialogLayout>
    </QForm>
  </QCard>
</template>

<script setup>
import Cashiers from "@/api/cashier"
import DialogLayout from "@/layouts/DialogLayout.vue"

import toast from "@/toast"
import { useQuasar } from "quasar"
const $q = useQuasar()

const style = useCssModule()

const props = defineProps({
  initial: { type: Object, default: () => ({}) },
})

const emit = defineEmits(["loading-change", "ok"])

const loading = ref(false)

const form = ref({
  username: undefined,
  colour: undefined,
  pin: undefined,
  hasEditPermission: false,
})

const rules = computed(() => {
  const colourRegex = /#[a-z]*/
  return {
    username: [],
    colour: [],
    pin: [],
    hasEditPermission: [],
  }
})

watch(
  () => loading.value,
  (value) => {
    emit("loading-change", value)
  },
  { immediate: true }
)

const isEdit = computed(() => !!props.initial.id)

const hydrateFormFromInitialData = () => {
  form.value = {
    ...form.value,
    ...props.initial,
  }
}
onMounted(() => hydrateFormFromInitialData())

const onFormSubmit = async () => {
  loading.value = true
  try {
    const params = unref(form)

    await (isEdit.value
      ? Cashiers.update(props.initial.id, params)
      : Cashiers.create(params))

    emit("ok")
  } catch {
    toast.error({
      message: "Something went wrong creating product",
    })
  } finally {
    loading.value = false
  }
}
const onDeleteClick = () => {
  $q.dialog({
    title: "Are you sure?",
    message: "Deleting a cashier cannot be undone.",
    cancel: {
      outline: true,
      flat: true,
      "text-color": "#e5332a",
    },
    ok: {
      outline: true,
      flat: true,
      "text-color": "#e5332a",
    },
    persistent: true,
    class: style.dialog,
  })
    .onOk(() => {
      deleteCashier()
    })
    .onCancel(() => {})
    .onDismiss(() => {})
}

const deleteCashier = async () => {
  loading.value = true
  try {
    await Cashiers.delete(props.initial.id)
    emit("ok")
  } catch {
    toast.error({
      message: "Something went wrong deleting cashier",
    })
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss" module>
.heading {
  height: 5rem;
  font-family: "chevin-pro";
  font-size: 25px;
  font-weight: bold;
  line-height: 25px;
  color: #65626b;
  text-align: center;
}

.cardStyle {
  width: 100%;
  max-width: 30rem;
  height: 100%;
  height: clamp(20rem, 100%, 40rem);
  max-height: 40rem;
}

.button {
  width: 100%;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.5rem;
  font-size: $text-md;
  font-weight: 400;
}

.innerContainer {
  padding: 0.5rem;
}
</style>
