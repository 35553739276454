<template>
  <div>
    <QAvatar :class="$style.avatar" rounded size="128px">
      <img :src="image" :class="$style.image" />
    </QAvatar>
    <label :class="$style.photoUploadLabel">
      {{ label }}
      <input
        type="file"
        accept="image/*"
        :class="$style.photoUploadInput"
        @change="onFileChange"
      />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, File, undefined],
      default: undefined,
    },
    label: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      image: undefined,
    }
  },
  watch: {
    modelValue: {
      handler(value) {
        this.createImage(value)
      },
      immediate: true,
    },
  },
  methods: {
    onFileChange(event) {
      const files = event.target.files || event.dataTransfer.files
      if (files.length === 0) return

      this.createImage(files[0])
    },
    createImage(file) {
      if (typeof file === "string") {
        this.image = file
      } else if (!file) {
        this.image = "/no_avatar.png"
      } else {
        const reader = new FileReader()
        const vm = this
        const target = 400

        reader.addEventListener("load", (event_) => {
          let img = new Image()
          img.src = event_.target.result
          img.addEventListener("load", () => {
            // limit the image to 400×400
            const ratio = Math.min(target / img.width, target / img.height)
            const width = img.width * ratio + 0.5
            const height = img.height * ratio + 0.5

            const scratch = document.createElement("canvas")
            scratch.width = width
            scratch.height = height

            const context = scratch.getContext("2d")
            context.drawImage(img, 0, 0, width, height)

            vm.image = scratch.toDataURL("image/jpeg")
            vm.$emit("update:modelValue", vm.image)
          })
        })
        reader.readAsDataURL(file)
      }
    },
  },
}
</script>

<style lang="scss" module>
.avatar {
  display: block !important;
  margin: auto;
}

.image {
  object-fit: cover;
}

.photoUploadLabel {
  display: block;
  width: 100%;
  padding: 2px;
  margin: 8px auto;
  font-size: $text-sm;
  text-align: center;
  cursor: pointer;
  background-color: $elevate;

  @include borderCol("grey");
}

.photoUploadInput {
  display: none;
}
</style>
