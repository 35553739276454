<template>
  <QDialog ref="dialogRef" @hide="onDialogHide">
    <QCard class="q-dialog-plugin">
      <QCardSection class="q-dialog__title"> Pause Orders </QCardSection>
      <QCardSection class="q-dialog__message">
        Please select how long you wish to pause ordering for
      </QCardSection>
      <QCardActions :class="$style.buttonRow">
        <QBtn color="primary" no-caps @click="handleOk(30)"> 30 Minutes </QBtn>
        <QBtn color="primary" no-caps @click="handleOk(720)"> 12 Hours </QBtn>
        <QBtn color="primary" no-caps @click="handleOk(undefined)">
          Until Unpaused
        </QBtn>
      </QCardActions>
      <QCardActions class="justify-end">
        <QBtn color="primary" flat @click="onCancelClick"> Cancel </QBtn>
      </QCardActions>
    </QCard>
  </QDialog>
</template>

<script>
import { useDialogPluginComponent } from "quasar"
</script>

<script setup>
const emit = defineEmits(["ok", "hide"])

const handleOk = (value) => {
  onDialogOK(value)
}

const onCancelClick = () => {
  onDialogCancel()
}

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent()
</script>

<style lang="scss" module>
.buttonRow {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
}
</style>
