<template>
  <button
    v-bind="$attrs"
    :class="buttonStyle.button"
    padding="0"
    no-caps
    tabindex="0"
    type="button"
    @click="productClick(product)"
  >
    <div :class="$style.buttonContents">
      <div :class="$style.top">
        <div :class="$style.topTextLineClamp">
          <template v-if="product.name === 'Add Product'">
            <QAvatar icon="add" size="lg" />
          </template>
          <template v-else>
            {{ product.name }}
            <template
              v-for="attribute in product.attributes"
              :key="attribute.id"
            >
              <QChip :class="$style.productAttributeChip" dense>
                {{ attribute.shortLabel }}
              </QChip>
            </template>
          </template>
        </div>
      </div>

      <div :class="$style.bottom">
        <template v-if="product.name !== 'Add Product'">
          {{ "£" + (product.price / 100).toFixed(2) }}
        </template>
      </div>
      <QChip
        v-if="getQuantity(product.id) > 0"
        :class="$style.productQuantityChip"
        text-color="white"
        dense
        size="lg"
      >
        {{ getQuantity(product.id) }}
      </QChip>
    </div>
  </button>
</template>

<script setup>
import { useShoppingCartStore } from "@/pinia/shoppingCart.js"

import ProductOptionsFormModal from "@/components/ProductOptionsFormModal.vue"
import { useVariablePriceSingleModal } from "@/components/modals/VariablePriceSingleModal/useVariablePriceSingleModal"
import { useQuasar } from "quasar"
import buttonStyle from "@/design/button.module.scss"
const shoppingCart = useShoppingCartStore()

const $q = useQuasar()

const style = useCssModule()

const props = defineProps({
  product: {
    type: Object,
    default: () => ({}),
  },
  topLevelCategory: {
    type: Object,
    default: () => ({}),
  },
  color: {
    type: String,
    default: "#65626B",
  },
  edit: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(["edit", "add-with-options"])

const productClick = (product) => {
  if (props.edit) {
    emit("edit", product)
  } else {
    order(product)
  }
}
// const getAttributeStyle = (attribute) => {
//   return {
//     background: attribute.displayColor || "#000000",
//     margin: "1px",
//   }
// }

const order = (product) => {
  if (product.options && product.options.length > 0) {
    addWithOptions(product)
  } else if (product.hasVariablePrice) {
    addWithVariablePrice(product)
  } else {
    addToCartWithNoOptions(product)
  }
}
const addToCartWithNoOptions = (product) => {
  shoppingCart.addItem({
    product: product,
    options: {},
  })
}

const addWithOptions = (product) => {
  $q.dialog({
    component: ProductOptionsFormModal,
    componentProps: {
      item: {
        product: product,
      },
    },
  })
}

const variablePriceModal = useVariablePriceSingleModal()
const addWithVariablePrice = (product) => {
  variablePriceModal.show({
    item: {
      product: product,
    },
  })
}

const getQuantity = (productId) => {
  return shoppingCart.productIdCount(productId)
}
</script>

<style lang="scss" module>
.productAttributeChip {
  display: inline-block;
  padding-right: 4px;
  padding-left: 4px;
  font-size: small;
  font-weight: 600;
}

.productQuantityChip {
  position: absolute;
  top: -5px;
  right: -5px;
  display: inline-block;
  padding-right: 4px;
  padding-left: 4px;
  font-size: small !important;
  font-weight: 600;
  background-color: v-bind("props.color");
}

.dialog {
  border-radius: 5px;
}

.buttonContents {
  width: 100%;
  height: 4rem;
  overflow: hidden;
  font-weight: 600;
  color: rgb(0 0 0 / 70%);
  border-radius: 0.5rem;
}

.top {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 66%;
  padding: 0.25rem;
  text-align: center;
  background-color: white;
}

.topTextLineClamp {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  max-height: 100%;
  overflow: hidden;
  line-height: 1.2em;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.bottom {
  height: 34%;
  color: white;
  background-color: v-bind("props.color");
}
</style>
