<template>
  <QItem :class="$style.item" clickable @click="() => toggleOrderPopover()">
    <QItemSection side :class="$style.firstColumn">
      <QItemLabel> #{{ order.orderNumber }} </QItemLabel>
      <QItemLabel class="full-width">
        <PaymentStatusChip
          :status="order.orderPayment.status"
          @click.stop="toggleOrderPopover('payment')"
        />
      </QItemLabel>
    </QItemSection>

    <!-- <QItemSection>
      <template v-if="order.deliverTo === 'home delivery'">
        <QItemLabel :class="$style.collectionTime">
          {{ formattedFulfilmentTime }}
        </QItemLabel>
      </template>
      <template v-if="order.deliverTo === 'click and collect'">
        <QItemLabel :class="$style.collectionTime">
          {{ formattedFulfilmentTime }}
        </QItemLabel>
      </template>
    </QItemSection> -->

    <QItemSection>
      <QItemLabel :class="$style.orderTime">
        <BaseToolTip>
          {{ formatDateTime(order.createdAt) }}
        </BaseToolTip>
        <UseTimeAgo v-slot="{ timeAgo }" :time="order.createdAt">
          {{ timeAgo }}
        </UseTimeAgo>
      </QItemLabel>
      <QItemLabel :lines="1">
        {{ deliveryLocation }}
        <template v-if="order.cashierId">
          <CashierChip :cashier-id="order.cashierId" class="q-ml-xs" />
        </template>
      </QItemLabel>
    </QItemSection>

    <QItemSection side>
      <QItemLabel>
        {{ formatPrice(order.totalCost) }}
      </QItemLabel>
    </QItemSection>
    <!--
    <QItemSection side>
      <QBtn flat dense @click="toggleOrderPopover('receipt')">
        <QIcon name="receipt"> </QIcon>
      </QBtn>
    </QItemSection> -->

    <QItemSection side class="q-pa-xs">
      <QBtn flat dense @click.stop="() => toggleOrderPopover('receipt')">
        <QIcon name="receipt"></QIcon>
      </QBtn>
    </QItemSection>
  </QItem>
</template>

<script setup>
import DateUtils from "@/utils/dateUtils"
import OrderPopoverDialog from "./OrderPopoverDialog.vue"
import Orders from "@/api/orders/orders"
import { useQuasar } from "quasar"
import { formatPrice } from "@/utils/currency"
const $q = useQuasar()

const props = defineProps({
  order: {
    type: Object,
    required: true,
  },
})
const emit = defineEmits(["update"])

const style = useCssModule()

const showDetailsDialog = ref(false)
const tab = ref("details")

const orderStatusText = computed(() => {
  return (
    {
      requested: "requested",
      ready: "ready",
      fulfilled: "completed",
    }[props.order.status] || props.order.status
  )
})

const deliveryLocation = computed(() => {
  switch (props.order.deliverTo) {
    case "table service": {
      return props.order.table
    }
    case "delivery": {
      return "Delivery"
    }
    case "home delivery": {
      return "Delivery"
    }
    case "collection": {
      return "Collection"
    }
    case "in store": {
      return "In Store"
    }
    default: {
      return "Unknown delivery location"
    }
  }
})

const formatDateTime = (datetime) => {
  if (!datetime) {
    return ""
  } else if (datetime instanceof Date) {
    return DateUtils.format(datetime, "HH:mm EEE do MMM")
  } else {
    return DateUtils.format(DateUtils.parseISO(datetime), "HH:mm EEE do MMM")
  }
}

const formattedFulfilmentTime = computed(() => {
  formatDateTime(fulfilmentTime.value)
})

const fulfilmentTime = computed(() => props.order.orderDatetimeSlot)

const updateOrderItems = (newItem) => {
  const indexOfItem = props.order.orderItems.findIndex(
    ({ id }) => id === newItem.id
  )
  if (indexOfItem === -1) return
  const newOrder = {
    ...props.order,
    orderItems: [
      ...props.order.orderItems.slice(0, indexOfItem),
      { ...newItem },
      ...props.order.orderItems.slice(indexOfItem + 1),
    ],
  }
  emit("update", newOrder)
}

const orderStatusColorClass = computed(() => {
  return (
    {
      requested: style.requested,
      ready: style.ready,
      fulfilled: style.fulfilled,
    }[props.order.status] || ""
  )
})

const toggleOrderPopover = (initialTab) => {
  $q.dialog({
    component: OrderPopoverDialog,
    componentProps: {
      orderId: props.order.id,
      initialTab,
      order: props.order,
    },
  })
}

const onUnreadyClick = async () => {
  try {
    await Orders.unready(props.order.id)
    handleOrderChanged()
  } catch {}
}

const onReadyClick = async () => {
  try {
    await Orders.ready(props.order.id)
    handleOrderChanged()
  } catch {}
}

const onCompleteClick = async () => {
  try {
    await Orders.fulfill(props.order.id)
    handleOrderChanged()
  } catch {}
}

const handleOrderChanged = async () => {
  emit("update")
  return
}
</script>

<style lang="scss" module>
.item {
  margin-bottom: 0.25rem;
  background-color: white;
  border-radius: 0.25rem;
}

.orderNumber {
  font-weight: bold;
  opacity: 0.7;
}

.orderNotes {
  padding: 0.25rem 1rem;
  font-size: $text-sm;
  opacity: 0.7;
}

.customerDetails {
  padding: 0.25rem 1rem;
}

.orderDetailsInDialog {
  padding: 0.25rem 1rem;
  font-size: $text-md;
}

.collectionTime {
  font-size: $text-sm;
  opacity: 0.87;
}

.orderTime {
  font-size: $text-sm;
  color: black;
  opacity: 0.7;
}

.orderDate {
  font-size: $text-sm;
  color: black;
  opacity: 0.7;
}

.printableContent {
  display: none;

  @media print {
    display: initial;
    padding: 0;
    margin: 0;
    font-family: Courier, monospace;
    white-space: pre-wrap;
  }
}

.cardSectionContainer {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: space-between;
  padding: 0;

  @include mq("desktop") {
    flex-direction: row;
  }
}

.cardSection {
  padding: 0.125rem;
}

.cardSectionDetails {
}

.cardSectionStatus {
  margin-left: auto;
}

.cardSectionActions {
  // margin-left: auto;
  .cardSectionItem {
    justify-content: flex-end;
  }
}

.cardSectionItem {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.125rem;
}

.card {
  margin-bottom: 0.25rem;
}

.dialogCard {
  width: 100%;
  padding: 0.5rem;
}

.statusCell {
  width: 5rem;
  cursor: pointer;
}

.ready {
  color: var(--on-color-ready, white);
  background-color: var(--color-ready);
}

.requested {
  color: var(--on-color-requested, white);
  background-color: var(--color-requested);
}

.fulfilled {
  color: var(--on-color-fulfilled, white);
  background-color: var(--color-fulfilled);
}

.statusActionList {
  font-size: $text-sm;
  line-height: 1.5em;
}

.firstColumn {
  width: 4.5rem;
}
</style>
