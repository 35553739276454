import Tables from "@/api/orders/tables"

export default {
  namespaced: true,
  state: () => ({
    tables: [],
  }),
  mutations: {
    SET_FIELDS(state, { tables }) {
      state.tables = tables
    },
  },
  actions: {
    async fetchTables({ commit }) {
      try {
        let {
          data: { results: unprocessedTables },
        } = await Tables.list()
        commit("SET_FIELDS", { tables: unprocessedTables })
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
  getters: {
    listTables: (state) => () => {
      return state.tables
    },
    listActiveTableNames: (state) => {
      return state.tables
        .filter((table) => table.isActive)
        .map((table) => table.name)
    },
  },
}
