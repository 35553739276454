<template>
  <QDialog ref="dialogRef" :maximized="!isDesktop">
    <QCard flat :class="$style.cardStyle">
      <DialogLayout @cancel="onDialogCancel">
        <template #title> </template>
        <div :class="$style.container">
          <div :class="$style.listScroll">
            <QList :class="$style.list">
              <div
                :class="[
                  $style.item,

                  isProductSelected(null) ? $style.selected : '',
                  isProductCurrent(null) ? $style.current : '',
                ]"
                @click="selectProduct(null)"
              >
                -- empty --
              </div>
              <template
                v-for="product in quickTillProducts.data"
                :key="product.id"
              >
                <div
                  :class="[
                    $style.item,

                    isProductSelected(product.id) ? $style.selected : '',
                    isProductCurrent(product.id) ? $style.current : '',
                  ]"
                  :style="`borderRight: 1px solid ${product.color}`"
                  @click="selectProduct(product.id)"
                >
                  <div>
                    {{ product.name }}
                  </div>
                  <QBtn
                    class="block"
                    :class="$style.editButton"
                    @click.stop="editProduct(product)"
                  >
                    Edit
                  </QBtn>
                </div>
              </template>
            </QList>
          </div>

          <!-- <QBtn
            outline
            no-caps
            :class="$style.addNewButton"
            @click="startAddNew"
          >
            Edit Selected Product
          </QBtn> -->

          <QBtn
            outline
            no-caps
            :class="$style.addNewButton"
            @click="startAddNewProduct"
          >
            Add New
          </QBtn>
        </div>

        <template #bottom>
          <QCardActions align="center" class="q-pa-sm">
            <QBtn
              color="primary"
              unelevated
              :class="$style.button"
              no-caps
              :loading="quickTillPositions.isUpdating"
              :disable="quickTillPositions.isUpdating"
              @click="savePosition"
            >
              Save
            </QBtn>
          </QCardActions>
        </template>
      </DialogLayout>
    </QCard>
  </QDialog>
</template>

<script>
import { useDialogPluginComponent, useQuasar } from "quasar"
</script>

<script setup>
import DialogLayout from "@/layouts/DialogLayout.vue"

import { BaseApi } from "@/api"

import { useQuickTillProducts } from "@/composables/useQuickTillProducts"
import { useQuickTillPositions } from "@/composables/useQuickTillPositions"
import { useQuickTillProductFormModal } from "@/components/modals/QuickTillProductFormModal/useQuickTillProductFormModal"
const props = defineProps({
  positionId: {
    type: Number,
    required: true,
  },
})

const quickTillProducts = useQuickTillProducts()
const quickTillPositions = useQuickTillPositions()

const emit = defineEmits(["ok", "hide"])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent()

const selectedProductId = ref()

const currentProductId = computed(
  () => currentQuickTillPosition.value?.productId
)

const currentQuickTillPosition = computed(() =>
  quickTillPositions.getById(props.positionId)
)
watch(
  () => currentQuickTillPosition.value,
  (value) => {
    selectedProductId.value = value?.productId
  },
  { immediate: true, deep: true }
)
const $q = useQuasar()
const isDesktop = $q.screen.gt.md

const isProductSelected = (productId) => productId === selectedProductId.value
const isProductCurrent = (productId) => productId === currentProductId.value

const selectProduct = (productId) => {
  selectedProductId.value = productId
}

const savePosition = async () => {
  // try {
  await quickTillPositions.update(props.positionId, {
    productId: selectedProductId.value,
  })
  onDialogOK()
  // } catch {
  // } finally {
  // }
}

const startAddNewProduct = () => {
  const quickTillProductFormModal = useQuickTillProductFormModal()
  quickTillProductFormModal.onOk((product) => {
    quickTillProducts.revalidate()
    if (product) {
      selectProduct(product.id)
    } else {
      selectProduct(currentProductId.value || null)
    }
  })
  quickTillProductFormModal.show()
}

const editProduct = (oldProduct) => {
  const quickTillProductFormModal = useQuickTillProductFormModal()
  quickTillProductFormModal.onOk((product) => {
    quickTillProducts.revalidate()
  })
  quickTillProductFormModal.show({
    initialData: oldProduct,
  })
}
</script>

<style lang="scss" module>
.cardStyle {
  width: 100%;
  max-width: 30rem;
  height: 100%;
  height: clamp(20rem, 100%, 40rem);
  max-height: 40rem;
}

.button {
  width: 100%;
  padding: 0.25rem 0.5rem;
  font-size: $text-md;
  font-weight: 400;
}

.form {
  height: 100%;
}

.current {
  background-color: rgb(178 11 99 / 20%);
}

.selected {
  color: white;
  background-color: rgb(178 11 99 / 70%);
}

.list {
  width: 100%;
  height: 100%;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  font-size: $text-base-size;
  cursor: pointer;
  border-radius: 0.25rem;
}

.listScroll {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.addNewButton {
  display: block;
  width: 100%;
  color: rgb(178 11 99);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: inherit;
  max-height: inherit;
  padding: 0.25rem;
}

.editButton {
  padding: 0.625rem 0.5rem;
  font-size: $text-sm;
  line-height: 1.2em;
  color: black;
  background-color: white;
}
</style>
