export default {
  namespaced: true,
  state: {
    firstName: undefined,
    lastName: undefined,
    id: undefined,
    email: undefined,
    phone: undefined,
    photo: undefined,
  },
  mutations: {
    SET_FIELDS(state, employee) {
      state.firstName = employee.firstName
      state.lastName = employee.lastName
      state.id = employee.id
      state.email = employee.email
      state.phone = employee.phone
      state.photo = employee.photo
    },
    RESET_FIELDS(state) {
      state.firstName = undefined
      state.lastName = undefined
      state.id = undefined
      state.email = undefined
      state.phone = undefined
      state.photo = undefined
    },
  },
  actions: {
    set({ commit }, employee) {
      commit("SET_FIELDS", employee)
    },
    flush({ commit }) {
      commit("RESET_FIELDS")
    },
  },
  getters: {
    fullName: (state) => {
      return `${state.firstName} ${state.lastName}`
    },
  },
}
