<template>
  <div :class="$style.chip">
    {{ cashier.username }}
  </div>
</template>

<script setup>
import { useCashierStore } from "@/pinia/cashier"

const cashierStore = useCashierStore()

const props = defineProps({
  cashierId: {
    type: [String, Number],
    required: true,
  },
})

const cashier = computed(() => cashierStore.getById(props.cashierId))
</script>

<style lang="scss" module>
.chip {
  display: inline-block;
  padding: 0.125rem 0.25rem;
  font-size: $text-sm;
  color: white;
  background-color: v-bind("cashier.colour");
  border-radius: 0.25rem;
}
</style>
