<template>
  <QExpansionItem expand-icon-class="hidden">
    <template v-slot:header>
      <QItemSection side>
        {{ createdAtTime }}
      </QItemSection>
      <QItemSection>
        <QItemLabel>
          <span :class="$style.orderNumber">#{{ orderClone.orderNumber }}</span>
          | {{ orderClone.customer.address }} ,
          {{ orderClone.customer.postcode }}
        </QItemLabel>
      </QItemSection>
      <QItemSection :class="$style.sideContainer">
        <div>
          <div :class="$style.deliveredText"> Delivered </div>
          <template v-if="loading">
            <QBtn :class="[$style.avatar]" disabled>
              <BaseLoadingSpinner size="sm" />
            </QBtn>
          </template>
          <template v-else>
            <div
              v-if="orderClone.status === 'ready'"
              :class="[
                $style.avatar,
                $style.clickable,
                $style.statusIncomplete,
              ]"
              @click="toggleDelivered"
            >
            </div>

            <div
              v-if="['fulfilled', 'delivered'].includes(orderClone.status)"
              :class="[$style.avatar, $style.statusComplete, $style.clickable]"
            >
              <QIcon name="done" @click="toggleDelivered" />
            </div>
          </template>
        </div>
      </QItemSection>
    </template>
    <QCard>
      <div v-if="order.notes" class="row justify-center">
        <QBtnToggle
          v-model="slide"
          :options="[
            { label: 'customer', value: 'customer' },
            { label: 'order', value: 'order' },
            { label: 'notes', value: 'notes' },
          ]"
        />
      </div>

      <div v-else class="row justify-center">
        <QBtnToggle
          v-model="slide"
          :options="[
            { label: 'customer', value: 'customer' },
            { label: 'order', value: 'order' },
          ]"
        />
      </div>
      <QCarousel
        v-model="slide"
        transition-prev="slide-right"
        transition-next="slide-left"
        swipeable
        animated
        control-color="primary"
        border="3px"
        height="100%"
      >
        <QCarouselSlide name="customer">
          <template v-if="orderClone.customer">
            <CustomerDetails :customer="orderClone.customer" />
          </template>

          <QList v-if="orderClone.orderDatetimeSlot" dense>
            <QItem>
              <QItemSection avatar>
                <QIcon name="today" />
              </QItemSection>
              <QItemSection>
                {{ formatCollectionTime(orderClone.orderDatetimeSlot) }}
              </QItemSection>
            </QItem>
          </QList>
        </QCarouselSlide>
        <QCarouselSlide name="order">
          <OrderItemsList :order="order" />
        </QCarouselSlide>
        <QCarouselSlide v-if="order.notes" name="notes">
          <h1 :class="$style.notesTitle">Notes from customer: </h1>
          <p :class="$style.notes">{{ order.notes }}</p>
        </QCarouselSlide>
      </QCarousel>
    </QCard>
  </QExpansionItem>
</template>

<script setup>
import DateUtils from "@/utils/dateUtils"
import Orders from "@/api/orders/orders"

const props = defineProps({
  order: {
    type: Object,
    required: true,
  },
})
const emit = defineEmits(["update"])
const loading = ref(false)
const slide = ref("customer")
const expanded = ref(false)

const orderClone = computed(() => {
  // Use this within component when mutating to ensure only parent can mutate its own state
  return {
    ...props.order,
    createdAt: DateUtils.parseISO(props.order.createdAt),
    orderDatetimeSlot:
      props.order.orderDatetimeSlot &&
      DateUtils.parseISO(props.order.orderDatetimeSlot),
  }
})
const createdAtTime = computed(() => {
  return DateUtils.format(orderClone.value.createdAt, "HH:mm")
})
const toggleDelivered = async () => {
  loading.value = true
  try {
    const { data: newOrder } =
      props.order.status === "ready"
        ? await Orders.fulfill(props.order.id)
        : await Orders.ready(props.order.id)
    emit("update", newOrder)
  } catch {}
  loading.value = false
}
const toggleExpand = () => {
  expanded.value = !expanded.value
}
const formatCollectionTime = (value) => {
  if (!value) return ""
  return DateUtils.format(value, "HH:mm EEE Do MMM")
}
</script>

<style module lang="scss">
.avatar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 35px;
  height: 35px;
  margin: auto;
  font-size: $text-lg;
  border-radius: 4px;
}

.statusComplete {
  @include bgCol("positive");

  color: white;
}

.statusIncomplete {
  @include textCol("blueGrey");
  @include borderCol("blueGrey");
}

.statusClickable {
  cursor: pointer;
}

.deliveredText {
  margin-bottom: 2px;
  font-size: $text-sm;
  font-weight: 400;
  text-align: center;
  text-transform: capitalize;
}

.sideContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
}

.orderNumber {
  font-weight: bold;
  opacity: 0.7;
}

.notesTitle {
  margin-bottom: 10px;
  font-size: $text-md;
  font-weight: bold;
  opacity: 0.7;
}

.notes {
  min-height: 4em;
}
</style>
