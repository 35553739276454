<template>
  <div :class="$style.container">
    <h1 :class="$style.clientNameTitle">{{ clientName }}</h1>
    <template v-if="loading">
      <BaseLoadingSpinner />
    </template>
    <template v-else-if="error">
      {{ error }}
    </template>
    <div v-else :class="$style.statsContainer">
      <div>
        <h1 :class="$style.statNumber">{{ formatNumber(requestedCount) }}</h1>
        <h2 :class="$style.statTitle">Requested Orders</h2>
      </div>
      <template v-if="$store.state.client.featureTakeaway">
        <QSeparator vertical />
        <div>
          <h1 :class="$style.statNumber">{{ formatNumber(toCollectCount) }}</h1>
          <h2 :class="$style.statTitle">Ready to collect</h2>
        </div>
      </template>
      <template v-if="$store.state.client.featureDelivery">
        <QSeparator vertical />
        <div>
          <h1 :class="$style.statNumber">{{ formatNumber(toDeliverCount) }}</h1>
          <h2 :class="$style.statTitle">Ready to deliver</h2>
        </div>
      </template>
    </div>
    <QSeparator style="width: 80%" />
    <h1 :class="$style.subtitle"> Settings </h1>
    <QForm :class="$style.form" @submit="applySettings">
      <!-- <q-field borderless label="Welcome Message" stack-label>
                <template v-slot:control >
                    <q-toggle v-model="featureWelcomeMessage"  />
                </template>
            </q-field>
            <q-input
                v-if="featureWelcomeMessage"
                v-model="message"
                type="textarea"
                maxlength="255"
                input-style="resize: none;"
                outlined
                dense
                counter
            /> -->
      <QField
        borderless
        :label="`Takeaway wait time: ${waitTime} mins`"
        stack-label
      >
        <template v-slot:control>
          <QSlider
            :model-value="waitTimeSliderValue"
            :label-value="waitTime"
            :min="0"
            :max="9"
            :step="1"
            markers
            @update:model-value="handleWaitTimeSliderInput"
          />
        </template>
      </QField>

      <!-- <q-field borderless label="Capacity" stack-label>
                <template v-slot:control >
                    <q-btn-toggle v-model="capacity" :options="capacityOptions" />
                </template>
            </q-field> -->
      <div :class="$style.buttonContainer">
        <QBtn label="Save changes" color="primary" type="submit" />
      </div>
    </QForm>

    <div :class="$style.actionRow">
      <template v-if="userIsOwnerOrSuperuser">
        <template v-if="menuAvailability.orderingUnavailable">
          <QBtn
            :class="[$style.actionButton, $style.unpause]"
            :loading="availabilityLoading"
            @click="unpauseAvailability"
          >
            Unpause
          </QBtn>
        </template>
        <template v-else>
          <QBtn
            :class="[$style.actionButton, $style.pause]"
            :loading="availabilityLoading"
            @click="pauseAvailability"
          >
            Pause
          </QBtn>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  title: "Home",
}
</script>

<script setup>
import OrderAdmin from "@/api/orders/orderAdmin"
import { BaseApi } from "@/api"
import PauseAvailabilityModal from "@/components/PauseAvailabilityModal.vue"

import { useStore } from "vuex"
const store = useStore()

import { useQuasar } from "quasar"
const $q = useQuasar()

import toast from "@/toast"

const waitTimeOptions = [5, 10, 15, 30, 45, 60, 75, 90, 105, 120]

const loading = ref(false)
const initialised = ref(false)
const error = ref("")
const listInterval = ref()
const waitTimeSlider = ref()
// message: '',
// capacity: '',
// featureWelcomeMessage: false,
const requestedCount = ref()
const toCollectCount = ref()
const toDeliverCount = ref()
const availabilityLoading = ref(false)
const menuAvailability = ref({})

const clientName = computed(() => {
  return store.state.client.name || "beeOrders"
})
const userIsOwnerOrSuperuser = computed(() => {
  return ["owner", "superadmin"].some((v) =>
    store.state.auth.groups.includes(v)
  )
})
const waitTime = computed(() => {
  return waitTimeSlider.value === -1
    ? store.state.client.waitTime
    : waitTimeOptions[waitTimeSlider.value]
})

const waitTimeSliderValue = computed(() => {
  if (waitTimeSlider.value === -1) {
    // get the closest
    return waitTimeOptions.indexOf(
      [...waitTimeOptions]
        .reverse()
        .find((x) => x < store.state.client.waitTime)
    )
  }

  return waitTimeSlider.value
})
onMounted(() => {
  waitTimeSlider.value = waitTimeOptions.indexOf(store.state.client.waitTime)
  fetchData()
  listInterval.value = setInterval(fetchData, 5000)
})
onBeforeUnmount(() => {
  clearInterval(listInterval.value)
  listInterval.value = undefined
})
const fetchData = () => {
  retrieveOrderAdminData()
  retrieveMenuAvailability()
}
const retrieveMenuAvailability = async () => {
  if (!userIsOwnerOrSuperuser.value) return
  try {
    const { data } = await BaseApi.get("/orders/availability/paused_status")
    menuAvailability.value = { ...data }
  } catch {}
  availabilityLoading.value = false
}
const retrieveOrderAdminData = async () => {
  if (!initialised.value) {
    loading.value = true
  }

  try {
    const { data } = await OrderAdmin.list()

    requestedCount.value = data.requestedCount
    toCollectCount.value = data.toCollectCount
    toDeliverCount.value = data.toDeliverCount
  } catch {
    error.value = "Error fetching restaurant data"
  }

  loading.value = false
  initialised.value = true
}
const applySettings = async () => {
  try {
    const { data: client } = await OrderAdmin.create({
      waitTime: waitTime.value,
    })
    store.commit("client/SET_WAIT_TIME", client.waitTime)
    toast.success({
      message: "Changes saved successfully",
    })
  } catch {
    toast.error({
      message: "An error occured",
    })
  }
}
const formatNumber = (n) => {
  // n is undef or null (relaxed equal)
  if (n == undefined) return 0
  // Prepend leading zeroes to single digit natural numbers
  return n < 10 ? "0" + n.toString(10) : n
}
const pauseAvailability = () => {
  $q.dialog({
    component: PauseAvailabilityModal,
  }).onOk(async (duration) => {
    try {
      await BaseApi.post("/orders/availability/pause/", {
        duration: duration,
      })
      toast.success({
        message: "Ordering paused",
      })
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data === "Already paused"
      ) {
        toast.success({
          message: "Ordering paused",
        })
      } else {
        toast.error({
          message: "Failed to pause orders",
        })
      }
    }
    availabilityLoading.value = true
    retrieveMenuAvailability()
  })
}
const unpauseAvailability = () => {
  $q.dialog({
    title: "Unpause",
    message: "This will re-enable ordering for customers.",
    cancel: true,
    persistent: true,
  }).onOk(async () => {
    try {
      await BaseApi.post("/orders/availability/unpause/")
      toast.success({
        message: "Ordering unpaused",
      })
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data === "Already unpaused"
      ) {
        toast.success({
          message: "Ordering unpaused",
        })
      } else {
        toast.error({
          message: "Failed to unpause orders",
        })
      }
    }
    availabilityLoading.value = true
    retrieveMenuAvailability()
  })
}
const handleWaitTimeSliderInput = (value) => {
  waitTimeSlider.value = value
}
</script>

<style module lang="scss">
.container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around safe;
  width: 100%;
  height: 100%;
  // padding: 24px 16px;
}

.form {
  width: 80%;
  margin-bottom: 0.5rem;
}

.statsContainer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  margin: 50px;
}

.statTitle {
  font-size: $text-sm;
  text-align: center;
}

.statNumber {
  font-size: 7rem;
  color: $primary;
}

.subtitle {
  margin: 10px;
  font-size: $text-md;
  font-weight: bold;
  opacity: 0.7;
}

.title {
  font-size: $text-xxl;
  color: #e5332a;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clientNameTitle {
  font-size: $text-md;
  font-weight: bold;
  text-align: center;
  opacity: 0.7;
}

.actionRow {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 0.5rem;
}

.actionButton {
  &.pause {
    @include bgCol("negative");
  }

  &.unpause {
    @include bgCol("positive");
  }
}
</style>
