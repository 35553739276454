import Endpoint from "@/api/endpoint"
import { BaseApi } from "@/api"
const relativeUrl = "/alerts"
const Alerts = new Endpoint({
  relativeUrl,
})

Alerts.unseenCount = (params) => {
    return BaseApi.get(`${relativeUrl}/unseen_count/`, params)
}


export default Alerts
