import { defineStore } from "pinia"
import Categories from "@/api/categories"

export const useCategoryStore = defineStore("category", () => {
  const categoriesMap = ref(new Map())

  const isLoading = ref(false)
  const hasLoaded = ref(false)
  const hasError = ref(false)

  const reset = () => {
    categoriesMap.value.clear()
    fetchCategories()
  }

  const fetchCategories = async () => {
    isLoading.value = true
    hasError.value = false
    try {
      const { data } = await Categories.listAllCategories()
      for (const category of data)
        categoriesMap.value.set(category.id, category)
      hasLoaded.value = true
    } catch {
      hasError.value = true
    } finally {
      isLoading.value = false
    }
  }

  const categoriesList = computed(() => [...categoriesMap.value.values()])

  const getById = computed(() => (id) => categoriesMap.value.get(id))
  const getBySlug = computed(
    () => (slug) =>
      categoriesList.value.find((category) => category.slug === slug)
  )

  const asOptions = computed(() => (options = {}) => [
    {
      label: "Top Level",
      value: null,
    },
    ...categoriesList.value.map((category) => ({
      label: category.name,
      value: category.id,
    })),
  ])

  if (!hasLoaded.value) {
    fetchCategories()
  }

  const getCategoryPath = computed(() => {
    if (!hasLoaded.value) {
      return () => []
    }
    return (id) => {
      if (!id) return []
      let current = getById.value(id)
      if (!current) return []
      let path = [current]
      while (current.parentCategoryId) {
        console.log(current)
        console.log(path)
        current = getById.value(current.parentCategoryId)
        path = [current, ...path]
      }
      return path
    }
  })

  return {
    categoriesList: readonly(categoriesList),
    isLoading: readonly(isLoading),
    hasLoaded: readonly(hasLoaded),
    categoriesMap,
    asOptions,
    getById,
    getBySlug,
    reset,
    getCategoryPath,
  }
})
