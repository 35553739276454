import { defineStore } from "pinia"
import { Dialog } from "quasar"
import router from "@/router"
import Cashiers from "@/api/cashier"

export const useCashierStore = defineStore("cashier", () => {
  const isLoading = ref(false)
  const hasLoaded = ref(false)
  const error = ref({})
  const cashiers = ref([])

  const currentCashierId = ref()

  const currentCashier = computed(() =>
    cashiers.value.find(({ id }) => id === currentCashierId.value)
  )

  const listCashiers = async () => {
    isLoading.value = true
    try {
      const { data } = await Cashiers.list()
      cashiers.value = data
      hasLoaded.value = true
    } catch (error_) {
      error.value = error_.response ? error_.response.data : error_
    } finally {
      isLoading.value = false
    }
  }

  const ensureLoaded = () => {
    if (!hasLoaded.value) listCashiers()
  }

  if (!hasLoaded.value) listCashiers()

  const cashierLogIn = async (
    cashier,
    { next = { name: "tillCategoryView" } }
  ) => {
    if (cashier.pin) {
      // not async right now, but will be maybe
      Dialog.create({
        title: "Cashier Login",
        message: "Enter pin for " + cashier.username,
        prompt: {
          model: "",
          type: "number",
          isValid: (pin) => pin == cashier.pin,
        },
        cancel: {
          outline: true,
          flat: true,
          "text-color": "#e5332a",
        },
        ok: {
          outline: true,
          flat: true,
          "text-color": "#e5332a",
        },
        // class: style.dialog,
      })
        .onOk(() => {
          currentCashierId.value = cashier.id
          // temporary
          router.push(next)
        })
        .onCancel(() => {})
        .onDismiss(() => {})
    } else {
      currentCashierId.value = cashier.id
      // temporary
      router.push(next)
    }
  }

  const cashierLogOut = () => {
    currentCashier.value = undefined
  }

  const getById = computed(
    () => (cashierId) =>
      cashiers.value.find((cashier) => cashier.id === cashierId) || {}
  )

  return {
    isLoading: readonly(isLoading),
    error: readonly(error),
    cashiers: readonly(cashiers),
    currentCashier: readonly(currentCashier),
    cashierLogIn,
    cashierLogOut,
    listCashiers,
    getById,
    ensureLoaded,
  }
})
