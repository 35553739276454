<template>
  <QLayout
    id="q-app"
    view="lhr LpR lFr"
    :class="[$style.pageBackground, edit ? $style.editTheme : '']"
  >
    <QHeader :class="$style.header">
      <QPullToRefresh @refresh="refreshInnerView">
        <QToolbar :class="$style.smallToolbar">
          <QBtn
            flat
            :class="$style.burgerMenuButton"
            :aria-expanded="leftDrawerOpen"
            rounded
            dense
            @click="leftDrawerOpen = !leftDrawerOpen"
          >
            <QIcon name="menu" />
          </QBtn>

          <template v-if="currentCashier">
            <CashierHeaderBadge
              :cashier="currentCashier"
              :class="$style.cashierBadge"
            />
          </template>

          <h1 :class="$style.pageTitleSmall">
            {{ pageTitle || "takepayments+" }}
          </h1>

          <div :class="$style.rightButtonsWrapper">
            <template v-if="!shoppingCart.hasItems">
              <template v-if="showEditButton && !edit">
                <QBtn
                  flat
                  dense
                  round
                  icon="edit"
                  :class="[
                    $style.menuButton,
                    edit ? $style.editButtonOn : $style.editButtonOff,
                  ]"
                  @click="edit = !edit"
                />
              </template>
              <QBtn
                flat
                dense
                round
                aria-label="Menu"
                icon="settings"
                :class="$style.menuButton"
              >
                <QMenu :class="$style.accountMenu">
                  <QList>
                    <template v-if="email">
                      <QItem :class="$style.menuEmail">
                        <QItemSection>
                          {{ email }}
                        </QItemSection>
                      </QItem>

                      <QSeparator />
                    </template>

                    <QItem v-close-popup clickable :to="{ name: 'help' }">
                      <QItemSection> Help </QItemSection>
                    </QItem>
                    <QSeparator />

                    <!-- <QItem tag="label">
                      <QItemSection>
                        <QItemLabel>Till Only</QItemLabel>
                      </QItemSection>
                      <QItemSection avatar>
                        <QToggle v-model="quickTill" />
                      </QItemSection>
                    </QItem>
                    <QSeparator /> -->

                    <!-- <QItem>
                      <QSelect
                        :options="defaultViewOptions"
                        emit-value
                        map-options
                        class="full-width"
                        :model-value="defaultView"
                        label="Default View"
                        @update:model-value="setDefaultView"
                      />
                    </QItem> -->

                    <template
                      v-for="item in routesSectionUser"
                      :key="item.text"
                    >
                      <QItem :to="item.to" clickable @click="item.onClick">
                        <template v-if="item.icon">
                          <QItemSection side>
                            <QIcon :name="item.icon" size="sm"></QIcon>
                          </QItemSection>
                        </template>
                        <QItemSection>
                          {{ item.text }}
                        </QItemSection>
                      </QItem>
                    </template>

                    <template v-if="isAndroid">
                      <QSeparator />
                      <QItem v-close-popup clickable @click="exitApp">
                        <QItemSection> Exit </QItemSection>
                      </QItem>
                    </template>
                  </QList>

                  <template v-if="isAndroid">
                    <QSeparator />
                    <div :class="$style.appVersion"> v. {{ appVersion }} </div>
                  </template>
                </QMenu>
              </QBtn>
            </template>
            <template v-else>
              <QBtn
                flat
                dense
                round
                aria-label="Payment"
                color="white"
                size="lg"
                :class="$style.logoRight"
                @click="cart = !cart"
              >
                <div :class="$style.cartButtonContents">
                  <div :class="$style.iconRow">
                    <QIcon
                      name="img:/img/cart_icon.svg"
                      :class="$style.cartIcon"
                    />
                    <div :class="$style.itemsInCartText">
                      {{ shoppingCart.numberOfItems }}
                    </div>
                  </div>
                  <div :class="$style.cartTotalPriceText">
                    {{ shoppingCart.totalCostAsString }}
                  </div>
                </div>
              </QBtn>
            </template>
          </div>
        </QToolbar>
      </QPullToRefresh>
    </QHeader>

    <HomeDrawerMenu v-model="leftDrawerOpen" />

    <TillCartDrawer v-model="cart" />

    <QPageContainer>
      <QPage :style-fn="styleFunction" :class="$style.pageContainer">
        <div :class="[$style.page]">
          <RouterView
            :key="`${innerViewKey}`"
            v-bind="{
              edit: edit,
            }"
            @checkout="showCheckout = true"
            @pay="showCheckout = true"
          />
        </div>
      </QPage>
    </QPageContainer>
  </QLayout>
</template>

<script setup>
import ChangePinModal from "@/components/ChangePinModal.vue"
import { App as CapacitorApp } from "@capacitor/app"
import { Capacitor } from "@capacitor/core"
import { useStore } from "vuex"
import { usePageTitle } from "@/composables/pageTitle"
import { useCashierStore } from "@/pinia/cashier"
import { useQuasar } from "quasar"
import { storeToRefs } from "pinia"
import { useShoppingCartStore } from "@/pinia/shoppingCart"
import { clearCache as clearCategoryCache } from "@/composables/tillCategories"
import { clearCache as clearAllCaches } from "@/composables/useSWRVCache"
import { useQuickTillStore } from "@/pinia/quickTillStore.ts"
import { useTillConfig } from "@/composables/useTillConfig"
const store = useStore()

const route = useRoute()
const router = useRouter()

const shoppingCart = useShoppingCartStore()

const $q = useQuasar()

const { currentCashier } = storeToRefs(useCashierStore())

const cashierStore = useCashierStore()

const { title } = usePageTitle()

const leftDrawerOpen = ref(false)
// isDesktop: screen.width >= 866,
const cart = ref(false)
const edit = ref(false)
const toggleEdit = () => {
  edit.value = !edit.value
}
provide("toggleEdit", toggleEdit)

const innerViewKey = ref(0)
const isAndroid = ref(Capacitor.getPlatform() === "android")
const hospitalityToggleLoading = ref(false)
const appVersion = ref()

const routesSectionUser = computed(() => {
  return filterAllowedLinks([
    ...(isLoggedInAsTerminal.value
      ? [
          {
            title: "Change Pin",
            text: "Change Pin",
            onClick: changePin,
            icon: "fas fa-unlock-alt",
          },
        ]
      : []),
    {
      title: "Log Out",
      text: isLoggedInAsTerminal.value ? "Pin Lock" : "Log Out",
      icon: "fas fa-sign-out-alt",
      onClick: logOut,
    },
  ])
})
const clientFeatures = computed(() => store.state.client.features)
const userAuthGroups = computed(() => store.state.auth.groups)
const email = computed(() => store.state.employee.email)

const isLoggedInAsTerminal = computed(
  () => !!store.state.auth.deviceSerialNumber
)
const isTillRoute = computed(() =>
  ["tillCashierLogin", "tillCategoryView"].includes(route.name)
)
const hospitalityMode = computed({
  get() {
    return store.state.terminal.hospitalityMode
  },
  async set(value) {
    if (!hospitalityToggleLoading.value) {
      hospitalityToggleLoading.value = true
      await store.dispatch("terminal/toggleHospitalityMode")
      hospitalityToggleLoading.value = false
    }
  },
})
const pageTitle = computed(() => title.value)
watch(
  () => isTillRoute.value,
  (value) => {
    if (!value) {
      edit.value = false
    }
  }
)
onMounted(async () => {
  try {
    const { version } = await CapacitorApp.getInfo()
    appVersion.value = version
  } catch {}
})

const refreshInnerView = (done) => {
  clearCategoryCache()
  clearAllCaches()
  cashierStore.listCashiers()
  innerViewKey.value = innerViewKey.value + 1
  done()
}

const styleFunction = (offset, height) => {
  const pageHeight = height - offset
  return `height: ${pageHeight}px`
}
const filterAllowedLinks = (linkList) => {
  return linkList.filter((item) => {
    let allowed = true

    if (item.group)
      allowed = allowed && userAuthGroups.value.includes(item.group)

    if (item.feature)
      allowed = allowed && clientFeatures.value.includes(item.feature)

    return allowed
  })
}
const logOut = () => {
  store.dispatch("auth/logOut")
}

const exitApp = () => {
  CapacitorApp.exitApp()
}

const changePin = () => {
  $q.dialog({ component: ChangePinModal })
}

const routeBack = () => {
  router.back()
}

const quickTillStore = useQuickTillStore()
const quickTill = computed({
  get: () => quickTillStore.enabled,
  set: (v) => quickTillStore.setEnabled(v),
})

const showEditButton = computed(() => {
  return isTillRoute.value && currentCashier.value?.hasEditPermission
})

const defaultViewOptions = [
  {
    value: "full_till",
    label: "Till",
  },
  {
    value: "quick_till",
    label: "Quick Till",
  },
]

// Being a bit lazy here for now.
const { defaultView, setDefaultView } = toRefs(useTillConfig())
</script>

<style lang="scss" module>
.pageBackground {
  padding: 0;
  background-color: #d0cece;
  background-repeat: repeat;

  --color-header: #65626b;
  --color-background: #d0cece;
  --color-container: #e0dddf;
  --background-gradient-start: #f8f8f8;
  --background-gradient-end: #e0dddf;
}

.page {
  max-width: $max-page-width;
  height: 100%;
  // padding: 0.25rem;
  margin: 0 auto;
  color: black;
  background: radial-gradient(
    var(--background-gradient-start),
    30%,
    var(--background-gradient-end)
  );
  border-radius: 0;

  @media only screen and (min-width: $max-page-width) {
    border-radius: 4px;
  }
}

.pageContainer {
  @media only screen and (min-width: $max-page-width) {
    padding: 0.5rem;
  }
}

.pageTitleSmall {
  margin: 0 auto;
  font-family: chevin-pro, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2em;
  color: white;
}

.header {
  z-index: 500;
  background-color: #65626b;
  background-color: var(--color-header);
}

.smallToolbar {
  max-width: $max-page-width;
  height: 50px;
  padding: 0;
  margin: 0 auto;
  color: #333;
  box-shadow: none;

  @include dark-mode {
    color: white;
    background-color: $dark-elevation-7;
  }
}

.textTitleBlack {
  font-size: $text-xxl;
  font-weight: 300;
}

.rightButtonsWrapper {
  position: absolute;
  right: 0;
  text-align: center;
}

.menuButton {
  color: white;
}

.burgerMenuButton {
  position: absolute;
  left: 0;
  width: 3rem;
  height: 3rem;
  font-size: 1.2em;
  color: white;
}

.accountMenu {
  color: black;
  background-color: #eee;
  border-right: 1px solid rgb(0 0 0 / 12%);
  border-radius: 30px;

  @include dark-mode {
    color: white;
    background-color: $dark-elevation-7;
  }
}

.menuEmail {
  font-size: $text-sm;
}

.logoRight {
  padding: 0 10px;
  font-size: 1.5em;
}

.cartButtonContents {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  font-size: $text-base-size;
}

.iconRow {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.cartIcon {
  margin-right: 4px;
}

.itemsInCartText {
  line-height: 1em;
}

.cartTotalPriceText {
  font-size: $text-sm;
  line-height: 1em;
  opacity: 0.87;
}

.button {
  flex-shrink: 0;
  width: 100%;
  margin: 0.25rem auto;
  font-weight: 700;
  color: white !important;
  border: 2px;
  border-radius: 15px;
  box-shadow: 1px 2px 3px #65626b;

  :global(.q-btn__wrapper) {
    min-height: 0;
  }
}

.cancelButton {
  width: 80%;
  background: #9aa6ad;

  :global(.q-btn__wrapper) {
    padding: 0 16px;
  }
}

.editTheme {
  // --color-header: #a57100;
  --background-gradient-start: #f3d299;
  --background-gradient-end: #eac78a;
}

.editButtonOff {
  opacity: 0.6;
}

.editButtonOn {
  background: #faa61a;
}

.appVersion {
  width: 100%;
  font-size: $text-sm;
  line-height: 1.5em;
  text-align: center;
  opacity: 0.6;
}

.cashierBadge {
  position: absolute;
  left: 3.5rem;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  background-color: rgb(0 0 0 / 70%);
}

.navigationButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 30rem;
}

.navigationButton {
  width: 33%;
}
</style>
