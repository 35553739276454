<template>
  <div>
    <div v-if="orders.length === 0" :class="$style.noOrders">
      No current orders.
    </div>
    <div :class="$style.container" :style="orientationStyle">
      <template v-for="order in orders" :key="order.id">
        <div :class="$style.item">
          <div :class="$style.itemSectionTop">
            <div>
              <p :class="$style.date"> {{ orderDate(order) }} </p>
              <p :class="$style.orderNumber"> #{{ order.orderNumber }} </p>
            </div>
          </div>
          <div :class="$style.itemSectionMiddle">
            <template v-for="item in order.kitchenItems" :key="item.id">
              <div
                :class="[
                  $style.kitchenItem,
                  item.status === 'ready' ? $style.kitchenItemReady : '',
                ]"
              >
                <div :class="$style.kitchenItemName">
                  {{ item.product.name }}
                </div>
                <div>
                  <template v-for="extra in item.optionalItems" :key="extra.id">
                    <span :class="$style.extraName"> - {{ extra.label }} </span>
                  </template>
                </div>
              </div>
            </template>
          </div>
          <div :class="$style.itemSectionBottom">
            <div> Ordered at {{ order.createdAt.slice(11, 16) }} </div>
            <div>
              {{ deliveryLocation(order) }}
            </div>
            <div :class="$style.orderNotes">
              {{ order.notes }}
            </div>
          </div>
        </div>
      </template>
    </div>
    <div :class="$style.time">
      Last updated: {{ format(new Date(), "HH:mm EEE Do MMM") }}
    </div>
  </div>
</template>

<script setup>
import Orders from "@/api/orders/orders"
import { format, parse } from "date-fns"

const props = defineProps({
  page: {
    type: Number,
    default: 1,
  },
})

const orders = ref([])
const requestInterval = ref()

const orientationStyle = ref("")

onBeforeUnmount(() => {
  pause()
})

onMounted(() => {
  listOrdersForKitchen()
  play()
  getOrientationStyle()
})

onMounted(() => {
  window.addEventListener("orientationchange", getOrientationStyle)
})

const listOrdersForKitchen = async () => {
  const {
    data: { results },
  } = await Orders.listKitchen({ page: props.page, limit: 12 })
  orders.value = results
}
const play = () => {
  requestInterval.value = setInterval(listOrdersForKitchen, 5000)
}
const pause = () => {
  clearInterval(requestInterval.value)
  requestInterval.value = undefined
}
const deliveryLocation = (order) => {
  switch (order.deliverTo) {
    case "table service": {
      return `Deliver to: ${order.table}`
    }
    case "delivery": {
      return `Deliver to: ${order.customer.address}`
    }
    case "collection": {
      return "Collect from store"
    }
    default: {
      return "Unknown delivery location"
    }
  }
}
const orderDate = (order) => {
  return format(
    parse(order.createdAt.slice(0, 10), "yyyy-MM-dd", new Date()),
    "EEE do MMM"
  )
}
const getOrientationStyle = () => {
  if ("orientation" in window.screen) {
    orientationStyle.value =
      window.screen.orientation.type === "landscape-primary"
        ? "grid-template-columns: repeat(4, 1fr);"
        : "grid-template-columns: repeat(2, 1fr);"
  } else {
    orientationStyle.value = "grid-template-columns: repeat(4, 1fr);"
  }
}
</script>

<style lang="scss" module>
.container {
  display: grid;
  grid-auto-rows: 45vh;
  grid-gap: 8px;
  height: 100vh;
  padding: 16px;
  padding-bottom: 3rem;
  overflow-y: scroll;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 50%), 0 1px 2px 0 rgb(0 0 0 / 30%);
}

.kitchenItem {
  margin-bottom: 16px;
}

.kitchenItemReady {
  opacity: 0.3;

  @include textCol("positive");
}

.extraName {
  margin-right: 4px;
  font-size: $text-sm;
  opacity: 0.87;
}

.orderNumber {
  float: right;
}

.date {
  float: left;
}

.itemSectionTop {
  height: 1.5em;
}

.itemSectionMiddle {
  height: calc(100% - 3.5em);
  overflow: auto;
}

.itemSectionBottom {
  height: 2em;
}

.orderNotes {
}

.noOrders {
  margin-top: 10px;
  font-size: $text-xl;
  text-align: center;
}

.time {
  position: absolute;
  bottom: 4px;
  left: 4px;
}
</style>
