<template>
  <QForm class="q-gutter-y-sm column" @submit="onSubmit">
    <QInput
      v-model="merchantIdentifier"
      label="Merchant Identifier"
      :error="!!errors.merchantIdentifier"
      :error-message="errors.merchantIdentifier"
      :rules="rules.merchantIdentifier"
    />
    <QInput
      v-model="form.email"
      color="primary"
      label="Email address"
      name="email"
      bottom-slots
      :rules="rules.email"
    >
    </QInput>

    <QBtn
      label="Request Link"
      color="primary"
      no-caps
      class="q-ml-auto"
      type="submit"
      align="right"
      :loading="isSubmitting"
      :disabled="isSubmitting"
    ></QBtn>
  </QForm>
</template>

<script setup>
import { requestLoginLink } from "@/api/auth"
import toast from "@/toast"

const form = ref({
  email: undefined,
})

const rules = ref({
  merchantIdentifier: [(v) => !!v || "Merchant identifier is required"],
  email: [(v) => !!v || "Email is required"],
})
const errors = ref({})
const logInError = ref(false)
const isSubmitting = ref(false)
const merchantIdentifier = ref()
onMounted(() => {
  merchantIdentifier.value = sessionStorage.getItem("merchantIdentifier")
})

const onSubmit = async () => {
  logInError.value = false
  isSubmitting.value = true
  sessionStorage.setItem("merchantIdentifier", merchantIdentifier.value)
  try {
    const res = await requestLoginLink({ email: form.value.email })
    toast.success({
      message:
        "If an active account was found, a link to log in to your account has been emailed to the given email address.",
    })
  } catch (error) {
    if (error?.response?.status === 404) {
      console.log("here")
      errors.value.merchantIdentifier = "Merchant not found"
    }
    logInError.value = true
  }
  isSubmitting.value = false
}
</script>
