<template>
  <div :class="$style.card">
    <h2 :class="$style.email">
      {{ email }}
    </h2>
    <EmployeeForm
      :errors="errors"
      password-required
      :submitting="submitting"
      :initial="initial"
      @submit="handleSubmit"
      @cancel="handleCancel"
    >
      <template v-slot:submit-label> Create Your Account </template>
    </EmployeeForm>
  </div>
</template>

<script>
export default {
  title: "Accept Invite",
}
</script>

<script setup>
import Invite from "@/api/invite"
const router = useRouter()

import toast from "@/toast"

import { useQuasar } from "quasar"
const $q = useQuasar()

const props = defineProps({
  token: {
    type: String,
    required: true,
  },
})

const errors = ref({})
const submitting = ref(false)
const initial = ref({})
const email = ref("")

onMounted(() => {
  if (!props.token) {
    router.push({ name: "log-in" })
  } else {
    retrieveInvite()
  }
})
const retrieveInvite = async () => {
  $q.loading.show()
  try {
    const { data } = await Invite.retrieve(props.token)

    email.value = data.email || ""
    initial.value = data
  } catch {}
  $q.loading.hide()
}
const handleSubmit = async (validatedForm) => {
  submitting.value = true
  const params = validatedForm
  try {
    await Invite.acceptInvite(props.token, params)
    toast.success({
      message: "Account successfully created!",
    })
    router.push("/")
  } catch (error) {
    errors.value = error.response.data
    toast.error({
      message: "Something went wrong. Invite may longer be valid.",
    })
  } finally {
    submitting.value = false
  }
}
const handleCancel = () => {
  router.push({ name: "log-in" })
}
</script>

<style lang="scss" module>
.card {
  max-width: 30rem;
  padding: 0.25rem;
}

.email {
  font-size: $text-xl;
}
</style>
