<template>
  <QDialog ref="dialogRef" :maximized="!isDesktop">
    <Component
      :is="renderedComponent"
      v-bind="componentProps"
      @cancel="onDialogCancel"
    />
  </QDialog>
</template>

<script>
import { useDialogPluginComponent, useQuasar } from "quasar"
</script>

<script setup>
import OrderPopoverContents from "@/components/OrderPopoverContents.vue"
import Orders from "@/api/orders/orders"
import DialogLayout from "@/layouts/DialogLayout.vue"

const { onDialogOK, onDialogHide, onDialogCancel, dialogRef } =
  useDialogPluginComponent()
/**
 * Plan is to also handle payment requests in here. Not sure if that's smart, but we'll see
 */

const props = defineProps({
  detailsType: {
    type: [String],
    required: true,
  },
  detailsId: {
    type: [Number, String],
    required: true,
  },
})

const componentMap = {
  order: OrderPopoverContents,
}

const renderedComponent = computed(() => componentMap[props.detailsType])

const emit = defineEmits("ok", "hide")

const componentProps = ref({
  onHide: onDialogHide,
  onCancel: onDialogCancel,
  onOK: onDialogOK,
})

const $q = useQuasar()
const isDesktop = $q.screen.gt.md

const isLoading = ref(false)
const hasLoaded = ref(false)
const retrieveOrder = async () => {
  isLoading.value = true
  try {
    const { data } = await Orders.retrieve(props.detailsId, { all: true })
    componentProps.value.order = data
    hasLoaded.value = true
  } catch (error) {
    onDialogCancel()
    throw error
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  if (props.detailsType === "order") {
    componentProps.value.orderId = props.detailsId
  }
})
</script>

<style lang="scss" module>
.card.card {
  width: 100%;
  max-width: 30rem;
  height: 100%;
  height: clamp(20rem, 40rem);
  max-height: 40rem;
}
</style>
