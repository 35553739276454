<template>
  <button
    v-bind="$attrs"
    ref="buttonRef"
    :class="[buttonStyle.button, $style.button]"
    padding="0"
    no-caps
    type="button"
    tabindex="0"
  >
    <div :class="$style.buttonContents">
      <div :class="$style.top">
        <div :class="$style.topTextLineClamp">
          <QIcon name="add" :class="$style.plusIcon" />
        </div>
      </div>
      <div :class="$style.bottom">
        <slot> </slot>
      </div>
    </div>
  </button>
</template>

<script setup>
import buttonStyle from "@/design/button.module.scss"

import { onLongPress } from "@vueuse/core"
const style = useCssModule()

const props = defineProps({
  color: {
    type: String,
    default: undefined,
  },
  plusIconSize: {
    type: String,
    default: "1.8em",
  },
})

const emit = defineEmits(["longpress"])

const onLongPressCallbackHook = () => emit("longpress")

const buttonRef = ref()

onLongPress(buttonRef, onLongPressCallbackHook)
</script>

<style lang="scss" module>
.button {
  height: 100%;
  border-radius: 0.25rem;
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
}

.plusIcon {
  display: block;
  margin: auto;
  font-size: 2em;
  font-size: v-bind("props.plusIconSize");
  color: v-bind("props.color");
}

.buttonContents {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-weight: 600;
  color: rgb(0 0 0 / 70%);
  border-radius: 0.25rem;
}

.top {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding: 0.125rem;
  text-align: center;
  background-color: white;
}

.topTextLineClamp {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  max-height: 100%;
  overflow: hidden;
  line-height: 1.2em;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.bottom {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  max-height: 100%;
  padding: 0.125rem 0.25rem;
  overflow: hidden;
  font-size: $text-xs;
  line-height: 1.5em;
  color: white;
  text-overflow: ellipsis;
  word-break: break-all;
  background-color: v-bind("props.color");
  -moz-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
