<template>
  <OrderList :filters="filters" :is-live="live">
    <template v-slot:filters>
      <QInput
        v-model="filters.search"
        placeholder="Search"
        outlined
        dense
        color="grey"
        class="full-width"
        @keydown.enter="$event.target.blur()"
      >
        <template v-slot:append>
          <QIcon
            name="close"
            class="cursor-pointer"
            @click="filters.search = ''"
          />
        </template>
      </QInput>
    </template>

    <template v-slot:filter-chips>
      <template v-for="status in filters.status" :key="status">
        <QChip
          removable
          :label="status.toUpperCase()"
          color="orange"
          @remove="removeStatus(status)"
        />
      </template>

      <template v-for="method in filters.deliverTo" :key="method">
        <QChip
          removable
          :label="method.toUpperCase()"
          color="blue"
          @remove="removeDeliverTo(method)"
        />
      </template>
      <template v-if="filters.date">
        <QChip
          removable
          :label="filters.date"
          color="negative"
          @remove="filters.date = undefined"
        />
      </template>
    </template>

    <template v-slot:order="{ order, refreshData }">
      <OrderListItemOrders :order="order" @update="refreshData" />
    </template>
  </OrderList>
</template>

<script setup>
import OrderListFilterDialogOrders from "@/components/OrderListFilterDialogOrders.vue"
import { usePageTitle } from "@/composables/pageTitle"
const { setPageTitle } = usePageTitle()
setPageTitle("Orders")

import { useQuasar } from "quasar"
const $q = useQuasar()

const filters = ref({})

const live = ref(true)
const toggleLive = () => {
  live.value = !live.value
}

const showFilterOptions = () => {
  $q.dialog({
    component: OrderListFilterDialogOrders,
    parent: this,
    componentProps: {
      ...filters.value,
    },
  }).onOk((newFilters) => {
    filters.value = newFilters
  })
}

const removeStatus = (value) => {
  let index = filters.value.status.indexOf(value)
  if (index === -1) return
  filters.value.status.splice(index, 1)
}

const removeDeliverTo = (method) => {
  let index = filters.value.deliverTo.indexOf(method)
  console.log(index)
  if (index === -1) return
  filters.value.deliverTo.splice(index, 1)
}
</script>

<style lang="scss" module>
.chip {
  text-transform: capitalize;
}

.buttonLive {
  margin: 0.25rem;
}

.buttonLiveOn {
  @include bgCol("red");
}

.buttonLiveOff {
  @include bgCol("grey");
}
</style>
