<template>
  <div :class="$style.container">
    <div
      ref="scrollContainer"
      :class="[
        $style.lineItemsContainer,
        itemsAboveScroll ? $style.moreAboveIndicator : '',
        itemsBelowScroll ? $style.moreBelowIndicator : '',
      ]"
    >
      <div :class="$style.flexContainer">
        <template v-for="(lineItem, index) in items" :key="lineItem.internalId">
          <div :class="$style.lineItem">
            <div :class="$style.productName" class="full-width">
              {{ lineItem.product.name }}
            </div>
            <div :class="$style.lineItemPrice">
              {{ costOfItemAsString(lineItem) }}
            </div>
            <div>
              <QBtn
                flat
                dense
                :class="$style.removeIcon"
                :style="{ color: lineItem.product.color }"
                @click="() => shoppingCart.removeItem(lineItem.internalId)"
              >
                <QIcon name="backspace"> </QIcon>
              </QBtn>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div :class="[$style.lineItem, $style.summaryItem]">
      <div :class="$style.summaryQuantity">
        {{ shoppingCart.numberOfItems }} Items
      </div>
      <QSeparator vertical :class="$style.summarySeparator" />
      <div :class="$style.summarySection">
        <div :class="$style.summaryPrice">
          {{ shoppingCart.totalCostAsString }}
        </div>
        <QBtn
          flat
          dense
          :class="[$style.removeAllIcon]"
          @click="() => shoppingCart.reset()"
        >
          <QIcon name="backspace"> </QIcon>
        </QBtn>
      </div>
      <div> </div>
    </div>
  </div>
</template>

<script setup>
import { formatPrice } from "@/utils/currency.js"
import { costOfItemAsString, useShoppingCartStore } from "@/pinia/shoppingCart"
import { useScroll } from "@vueuse/core"

const shoppingCart = useShoppingCartStore()
const props = defineProps({
  itemName: {
    type: String,
    default: "",
  },
  withBackground: {
    type: Boolean,
    default: false,
  },
})

const items = computed(() => shoppingCart.items)

watch(
  () => items.value,
  (value) => scrollToBottom()
)

const scrollContainer = ref()
const scrollToBottom = async () => {
  await nextTick()
  scrollContainer.value &&
    (scrollContainer.value.scrollTop = scrollContainer.value?.scrollHeight)
}

onMounted(() => scrollToBottom())

const { arrivedState } = useScroll(scrollContainer, { offset: { bottom: 50 } })

onMounted(() => {
  arrivedState.bottom = true
})

const itemsAboveScroll = computed(() => !arrivedState.top)
const itemsBelowScroll = computed(() => !arrivedState.bottom)
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024;
}

.lineItemsContainer {
  flex-grow: 1;
  align-self: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  text-align: left;
  box-shadow: var(--top-shadow, 0 0 0 rgb(0 0 0)),
    var(--bottom-shadow, 0 0 0 rgb(0 0 0));
}

.flexContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100%;
  padding: 0 0.5rem;

  > * ~ * {
    border-top: 1px solid rgb(0 0 0 / 30%);
  }
}

.lineItem {
  display: flex;
  align-items: center;
  min-width: 200px;
  padding: 0.125rem 0.5rem;
  font-size: $text-sm;
  line-height: 1.2em;
  color: rgb(0 0 0 / 70%);
}

.lineItemName {
  margin-right: 10px;
}

.lineItemPrice {
  width: 6rem;
  margin-right: 0.25rem;
  margin-left: auto;
  font-size: $text-base-size;
  font-weight: 600;
  text-align: right;
  opacity: 0.87;
}

.moreAboveIndicator {
  --top-shadow: inset 0 8px 5px -5px rgb(0 0 0 / 40%);
}

.moreBelowIndicator {
  --bottom-shadow: inset -0 -8px 5px -5px rgb(0 0 0 / 40%);
}

.chipContainer {
  flex: 0 1 33%;
}

.removeIcon {
  opacity: 0.7;
}

.removeAllIcon {
  color: #c04a89;
}

.summaryItem {
  width: 100%;
  padding: 0.25rem 1rem;
  font-size: $text-xl;
  font-weight: 700;
  line-height: 1.2em;
  background-color: white;
  border-top: 1px solid rgb(0 0 0 / 87%);
}

.summaryQuantity {
  flex: 1 0 0%;
  color: $primary;
  text-align: right;
}

.summarySection {
  display: flex;
  flex: 1 0 0%;
  align-items: center;
  justify-content: space-between;
}

.summaryPrice {
  color: $primary;
  text-align: left;
}

.summarySeparator {
  margin: 0 1rem;
}

.productName {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  flex-grow: 1;
  overflow: hidden;
  font-size: $text-sm;
  line-height: 1.2em;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  color: rgb(0 0 0 / 70%);
}
</style>
