import Attributes from "@/api/orders/attributes"

export default {
  namespaced: true,
  state: {
    attributes: [],
  },
  mutations: {
    SET_FIELDS(state, { attributes }) {
      state.attributes = attributes
    },
  },
  actions: {
    async fetchAttributes({ commit }) {
      try {
        let {
          data: { results: attributes },
        } = await Attributes.list()
        commit("SET_FIELDS", { attributes: attributes })
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
  getters: {
    listAttributes: (state) => () => {
      return state.attributes
    },
  },
}
