<template>
  <div :class="$style.container">
    <QIcon name="img:/img/refer_a_friend.svg" :class="$style.refferalIcon" />
    <div :class="$style.title">
      <div :class="$style.header">Get a month of free service</div>
      <div :class="$style.subheader">for you and a friend.</div>
    </div>
    <QList separator>
      <template v-for="referral in referrals" :key="referral.id">
        <QItem>
          <QItemSection>
            {{ referral.recipientPhone || referral.recipientEmail }}
          </QItemSection>
          <QItemSection side>
            <template v-if="referral.accepted">
              <QIcon name="done" color="positive" />
            </template>
          </QItemSection>
        </QItem>
      </template>
      <QForm id="newReferralForm" @submit="createReferral">
        <QItem>
          <QItemSection>
            <QInput
              v-model="newReferralInput"
              label="New Referral"
              bottom-slots
              :rules="rules.email"
              lazy-rules="ondemand"
              no-error-icon
              :error="
                !!errors.recipientPhone && errors.recipientPhone.length > 0
              "
              :error-message="
                !!errors.recipientPhone && errors.recipientPhone[0]
              "
              hint="Please enter a valid phone number."
              @focus="errors.recipientPhone = []"
            />
          </QItemSection>
          <QItemSection side>
            <QBtn type="submit" color="secondary" form="newReferralForm">
              Send
            </QBtn>
          </QItemSection>
        </QItem>
      </QForm>
    </QList>
  </div>
</template>

<script>
export default {
  title: "Refer a friend",
}
</script>

<script setup>
import Referrals from "@/api/referrals"
const newReferralInput = ref("")
const referrals = ref([])
const rules = ref({
  phone: [(value) => !!value || "Please enter a valid phone number."],
})
const errors = ref({})

onMounted(() => {
  listReferrals()
})
const listReferrals = async () => {
  try {
    const {
      data: { results },
    } = await Referrals.list()
    referrals.value = results
  } catch {}
}
const createReferral = async () => {
  const params = {
    recipientPhone: newReferralInput.value,
  }
  try {
    await Referrals.create(params)
    listReferrals()
    newReferralInput.value = ""
  } catch (error) {
    errors.value = error.response.data
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
}

.refferalIcon {
  width: 83px;
  height: 88px;
  margin: 0 auto 25px;
}

.newReferralForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.newReferralInput {
  flex: 1 1 auto;
}

.title {
  margin-bottom: 16px;
  font-size: $text-lg;
  font-weight: 600;
  line-height: 1.69rem;
  text-align: center;
  letter-spacing: 0;
}

.subheader {
  font-size: $text-md;
  font-weight: 500;
}
</style>
