<template>
  <button
    v-bind="$attrs"
    ref="buttonRef"
    :class="[buttonStyle.button, $style.button]"
    padding="0"
    no-caps
    type="button"
    tabindex="0"
  >
    <div :class="$style.buttonContents">
      <!-- <QIcon name="add" :class="$style.plusIcon" /> -->
    </div>
  </button>
</template>

<script setup>
import buttonStyle from "@/design/button.module.scss"

import { onLongPress } from "@vueuse/core"

const emit = defineEmits(["longpress"])

const onLongPressCallbackHook = () => emit("longpress")

const buttonRef = ref()

onLongPress(buttonRef, onLongPressCallbackHook)
</script>

<style lang="scss" module>
.button {
  height: 100%;
  border-radius: 0.25rem;
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
}

.plusIcon {
  display: block;
  margin: auto;
  font-size: 2em;
  font-size: 3.5em;
  color: #65626b;
}

.buttonContents {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-weight: 600;
  color: rgb(0 0 0 / 70%);
  border-radius: 0.25rem;
}
</style>
