const title = ref("")

const titlePrefix =
  import.meta.env.MODE !== "legacy" ? "takepayments+" : "beeOrdered Admin"

const titleTemplateFunction = (title) => `${titlePrefix} | ${title}`

export function usePageTitle() {
  watchEffect(() => {
    document.title = titleTemplateFunction(title.value)
  })

  const setPageTitle = (value) => {
    title.value = value
  }

  return { title: readonly(title), setPageTitle }
}
