<template>
  <QCard :class="$style.orderSummaryCard">
    <QList dense>
      <QItem>
        <QItemSection> Order </QItemSection>
        <QItemSection side> #{{ order.orderNumber }} </QItemSection>
      </QItem>
      <QItem>
        <QItemSection> Cost </QItemSection>
        <QItemSection side>
          {{ formatPrice(order.totalCost) }}
        </QItemSection>
      </QItem>
      <template v-if="order.customer && customerDetails(order.customer)">
        <QItem>
          <QItemSection> Customer </QItemSection>
          <QItemSection side>
            {{ customerDetails(order.customer) }}
          </QItemSection>
        </QItem>
      </template>
      <QItem>
        <QItemSection> Date </QItemSection>
        <QItemSection side>
          {{ formatDateTime(order.createdAt) }}
        </QItemSection>
      </QItem>

      <!-- <QItem>
        <QItemSection>Status</QItemSection>
        <QItemSection side>
          <StatusDumbCell
            :class="[$style.statusCell, orderStatusColorClass]"
            @click.stop=""
          >
            {{ orderStatusText }}
            <QPopupProxy>
              <QCard :class="$style.statusActionList">
                <QList dense>
                  <QItem v-close-popup clickable @click="onUnreadyClick">
                    Unready
                  </QItem>
                  <QItem v-close-popup clickable @click="onReadyClick">
                    Ready
                  </QItem>
                  <QItem v-close-popup clickable @click="onCompleteClick">
                    Complete
                  </QItem>
                </QList>
              </QCard>
            </QPopupProxy>
          </StatusDumbCell>
        </QItemSection>
      </QItem> -->
    </QList>
  </QCard>

  <QCard>
    <QCardSection class="q-pa-sm"> Items </QCardSection>
    <!-- <QSeparator /> -->
    <QCardSection class="q-pa-sm">
      <QList dense>
        <template v-for="item in props.order.orderItems" :key="item.id">
          <QItem>
            <QItemSection>
              <QItemLabel :class="$style.productName" :lines="1">
                {{ item.product.name }}
              </QItemLabel>
              <QItemLabel caption lines="1">
                <span
                  v-if="item.product.measure && item.product.measure !== '.'"
                  :class="$style.productMeasure"
                >
                  {{ item.product.measure }}
                </span>
                {{ item.product.description }}
              </QItemLabel>

              <QItemLabel caption>
                <template v-for="option in item.optionalItems" :key="option.id">
                  <span> {{ option.label }} | &nbsp;</span>
                </template>
              </QItemLabel>
            </QItemSection>
          </QItem>
        </template>
      </QList>
    </QCardSection>
  </QCard>
</template>

<script setup>
import { formatPrice } from "@/utils/currency"
import * as DateFns from "date-fns"

import groupBy from "lodash/groupBy"

const style = useCssModule()
const props = defineProps({
  order: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(["refresh"])

const customerDetails = (customer) => {
  if (!customer) return
  return [customer.name, customer.phone, customer.address, customer.postcode]
    .filter(Boolean)
    .join(" : ")
}

const itemsKeyedByCategory = computed(() => {
  return groupBy(props.order.orderItems, (item) => item.product.category)
})

const formatDateTime = (datetime) => {
  const format_ = (date) => DateFns.format(date, "HH:mm EEE do MMM")
  if (!datetime) {
    return ""
  } else if (datetime instanceof Date) {
    return format_(datetime)
  } else {
    return format_(DateFns.parseISO(datetime))
  }
}

const orderStatusColorClass = computed(() => {
  return (
    {
      requested: style.requested,
      ready: style.ready,
      fulfilled: style.fulfilled,
    }[props.order.status] || ""
  )
})

const onUnreadyClick = async () => {
  try {
    await Orders.unready(props.order.id)
    handleOrderChanged()
  } catch {}
}

const onReadyClick = async () => {
  try {
    await Orders.ready(props.order.id)
    handleOrderChanged()
  } catch {}
}

const onCompleteClick = async () => {
  try {
    await Orders.fulfill(props.order.id)
    handleOrderChanged()
  } catch {}
}

const handleOrderChanged = async () => {
  emit("update")
  return
}

const orderStatusText = computed(() => {
  return (
    {
      requested: "requested",
      ready: "ready",
      fulfilled: "completed",
    }[props.order.status] || props.order.status
  )
})
</script>

<style lang="scss" module>
.productMeasure {
  padding-right: 4px;
  text-transform: lowercase;
  opacity: 0.7;
}

.productAttributeLabel {
  display: inline-block;
  padding-right: 4px;
  // opacity: 0.7;
  font-size: small;
  font-weight: 600;
}

.noItems {
  padding: 1rem;
}

.expansionItem {
  background: white;
  border-radius: 0.25rem;
}

.expansionChevron {
  color: #b20b63;
}

.productChip {
  padding: 0.5em;
  margin: 0;
  font-weight: 600;
  color: white;
  background: #b20b63;
}

.productName {
  font-size: $text-sm;
}

.orderSummaryCard {
  margin-bottom: 0.5rem;
}

.statusCell {
  width: 5rem;
  cursor: pointer;
}

.ready {
  color: var(--on-color-ready, white);
  background-color: var(--color-ready);
}

.requested {
  color: var(--on-color-requested, white);
  background-color: var(--color-requested);
}

.fulfilled {
  color: var(--on-color-fulfilled, white);
  background-color: var(--color-fulfilled);
}
</style>
