<template>
  <QDialog ref="dialogRef" full-width @hide="onDialogHide">
    <QCard>
      <QCardSection>
        <pre :class="$style.terms">
            beepaid App – Terms and Conditions

        1. Definitions and Interpretation
            1.1 In these terms and conditions, the following terms (whether used in singular or plural form) shall have the following meanings:
    Acquirer means the provider or proposed provider of merchant acquiring services for the Merchant to which the Merchant is referred by takepayments, as specified on the Acquirer Application Form;
    Acquiring Agreement means the agreement made between the Acquirer and the Merchant for the provision of merchant acquiring services by the Acquirer;
    Acquirer Application Form means the application form completed by or on behalf of the Merchant for submission to the Acquirer by takepayments in relation to a proposed Acquiring Agreement (or, where there is no separate application form or where takepayments previously referred the Merchant to the Acquirer, the Acquiring Agreement itself);
    Administration Charge means the charge payable in accordance with clause 13.13 where takepayments agrees to make a Requested Change, as set out in the Other Charges List;
    Applicable Laws means any applicable law and any applicable binding rules or requirements of a Regulatory Body, including the Card Scheme Rules (in each case, as the same may be amended, redacted and/or re-enacted from time to time);
    Application Form means the document entitled ‘takepayments Limited Online Services Application Form’ signed by or on behalf of the Merchant;
    beepaid Software means software which enables the Merchant to effect a 3D secure payment request to another recipient;
    Card Scheme Rules means the by-laws, rules, regulations, releases, interpretations, operating guidelines and other requirements promulgated by any Card Scheme, including the rules and operating instructions applicable to the PCI DSS (in each case, as amended from time to time by such Card Scheme);
    Card Schemes means Visa Europe, Visa Inc, MasterCard Worldwide and/or such other scheme which from time to time processes any payment transaction which is initiated by the Merchant via the Service;
    Change of Legal Entity Charge means the charge payable in accordance with clause 8.3 where takepayments agrees to early termination of the Contract in connection with a change to the Merchant’s legal entity or basis of trading, as set out in the Other Charges List;
    Conditions means these terms and conditions (as may be amended from time to time in accordance with clause 13.11);
    Contract means the contract between the Merchant and takepayments for the provision of the Service (comprising the Application Form and these Conditions);
    Default Visit Charge means the charge payable in accordance with clause 5.6 in the event that takepayments deploys a representative to visit the Merchant Site following any breach of the Contract by the Merchant, as set out in the Other Charges List;
    Direct Debit Resubmission Charge means the prevailing charge for resubmitting a direct debit in accordance with clause 6.13.1, as set out in the Other Charges List;
    Early Termination Administration Charge means the charge payable in accordance with clause 9.4.3 where the Contract is terminated before the end of the Minimum Period or the relevant Extended Period (as the case may be);
    Early Termination Amounts means the amounts due to be paid by the Merchant pursuant to clause 9.4;
    Effective Date means the date on which the Merchant has signed the Application Form;
    Extended Period means a period of twelve (12) months commencing on the expiry of the Minimum Period or (as the case may be) commencing on the date on which the previous such twelve-month period expires;
    GDPR means the General Data Protection Regulation (EU) 2016/679;
    Intellectual Property Rights means all vested, contingent and future intellectual property rights including goodwill, reputation, rights in confidential information, copyright, trade marks, trade names, logos, service marks, devices, plans, models, diagrams, specifications, source and object code materials, data and processes, design rights, patents, know-how, trade secrets, inventions, get-up, database rights, rights in software, in each case whether registered or unregistered, and any applications or registrations for the protection of these rights and all renewals and extensions thereof existing in any part of the world, whether now known or in the future created;
    Non-Payment Charge means the prevailing charge for non-payment of any of the Service Charges or Relevant Charges on the Payment Date, as set out in the Other Charges List;
    Non-Use Charge means the prevailing charge applicable where the Acquiring Agreement is terminated for any reason during the Service Term, as set out in the Other Charges List;
    Merchant means the relevant person identified as the Merchant on the Application Form;
    Merchant Bank Account means the bank account nominated by the Merchant for the processing of the direct debit in respect of the payment of the Service Charges (and the Relevant Charges where applicable), or such other bank account as may be notified to and agreed by takepayments from time to time in accordance with clause 6.8;
    Merchant Site means the Merchant’s address as specified in the Application Form;
    Minimum Period means the period of 12 (twelve) months from and including the Effective Date;
    Other Charges List means takepayments’ list of sundry charges as set out in clause 14.1 (in each case, as may be amended from time to time in accordance with the provisions of the Contract);
    Payment Date means the due date for payment of the Service Charges and/or (as the case may be) the due date for payment of the Relevant Charges (where applicable) in accordance with clause 6.5;
    PCI DSS means the Payment Card Industry Data Security Standards, as released from time to time by the Payment Card Industry Security Standards Council;
    Performance Standards means the service availability standards specified in clause 3.8;
    Personal Data has the meaning set out in the GDPR;
    Privacy Policy means takepayments’ Privacy Policy from time to time in force as set out at takepayments.com/privacy-policy;
    Regulatory Body means any government departments and regulatory, statutory and other entities, committees and bodies which (whether under statute, rules, regulations, codes of practice or otherwise) are entitled to regulate, investigate, or influence the matters dealt with in the Contract or any other affairs of a party (including the Card Schemes);
    Relevant Charges means the Set-Up Charges, the charges in the Other Charges List and any other charges or amounts (including interest) which are due and payable under the Contract from time to time (other than the Service Charges, the Statement Fee and the Early Termination Amounts);
    Relevant Provider means the Acquirer and/or (as applicable) any other third party which (whether pursuant to any contract with the Merchant and/or with takepayments) makes the Service available and/or provides the Support;
    Requested Change means any change to the Contract (including, for the avoidance of doubt, any change to the Service, the Service Charges, the Minimum Period, the Extended Period, the Merchant Bank Account, the Merchant Site and any change to any of these Conditions) which is agreed by takepayments in writing following a request from the Merchant to make such change;
    RPI means the means the Retail Prices Index as published by the All Items Retail Prices Index as published by the Office for National Statistics in the UK (or where such index or authority no longer exists, the replacement or successor for such authority or index);
    Service means the provision of the Software by takepayments;
    Service Charges means the charges payable by the Merchant for the provision of the Service (excluding any Set-up Charges), as set out in the Application Form;
    Service Instructions means any instructions provided by or on behalf of takepayments relating to the Merchant’s use of the Service (including as may be updated, changed or replaced from time to time pursuant to clause 3.3.2);
    Set-up Charges means any applicable set-up or development charges payable by the Merchant in connection with the Service, as set out in the Application Form;
    Software means the beepaid Software, together with (where applicable) any other software provided or made available to the Merchant by or on behalf of takepayments or a Relevant Provider in connection with the provision of the Support;
    Software Updates has the meaning set out in clause 11.7;
    Statement Fee means the fee shown in the Other Charges List for the applicable copy of the invoice or statement which is required by the Merchant;
    Support means any support (including telephone and online support), management information, reporting tools and/or services (including any software) which are provided or otherwise made available (directly or indirectly) to the Merchant by or on behalf of a Relevant Provider in connection with the Contract or the Merchant’s use of the Service;
    takepayments means takepayments Limited, a company registered in England and Wales under company registration number 3102137; and
    Service Term means, for each Service, the period from the Effective Date until the date of termination of the Contract (howsoever arising) for such Service.
            1.2 In these Conditions (unless the context otherwise requires):
                1.2.1 references to the Contract or to any other document referred to in the Contract (including the Application Form and these Conditions) shall mean the Contract or such other document as amended, varied, supplemented, modified or novated from time to time;
                1.2.2 the words “including” and “include” and words of similar effect shall be deemed to have the words “without limitation” following them;
                1.2.3 the words “other” and “otherwise” are illustrative and shall not limit the sense of the words preceding or following them;
                1.2.4 references to a “person” shall include natural persons, firms, companies, associations, corporate or unincorporated bodies (whether or not having separate legal personality) and that person’s legal and personal representatives, successors and permitted assigns;
                1.2.5 words importing the singular shall include the plural and vice versa;
                1.2.6 references to a numbered clause are to a clause of these Conditions so numbered;
                1.2.7 references to a “party” mean takepayments or the Merchant (and references to the “parties” shall be construed accordingly); and
                1.2.8 any reference to any legislative provision is a reference to it as it is in force from time to time (taking account of any amendment, extension or re-enactment) and includes any subordinate legislation for the time being in force made under it.
            1.3 In the event of any inconsistency between the provisions of the Application Form and the provisions of these Conditions, then the latter shall prevail.
            1.4 The headings in these Conditions and the Application Form are for ease of reference only and shall not affect the construction or interpretation of the Contract.
        2. Duration of the Contract
            2.1 Subject to clause 0, unless the Contract is terminated earlier in accordance with its provisions:
                2.1.1 the Service shall be provided for the Minimum Period; and
                2.1.2 the provision of the Service shall continue after the expiry of the Minimum Period for each Extended Period unless and until the Merchant terminates the Contract in accordance with clause 2.2.
            2.2 The Merchant may terminate the Contract for the Service:
                2.2.1 on or after the expiry of the Minimum Period applicable to such Service; or
                2.2.2 on the expiry of any Extended Period applicable to such Service,
    by giving not less than two (2) months’ prior written notice to takepayments.
            2.3 For the avoidance of doubt:
                2.3.1 where any notice served by the Merchant pursuant to clause 2.2 is not served within sufficient time to give the full period of notice (as required by clause 2.2) prior to the expiry of the Minimum Period or (as the case may be) the relevant Extended Period, then such notice shall not take effect until the expiry of the subsequent Extended Period;
                2.3.2 each Contract for a Service is a separate contract and independent of any other Contract for the supply of another Service and accordingly no termination of any Contract relating to a Service shall, of itself, affect the continuation in force of any other Contract for another Service or entitle the Merchant to terminate any Contract for another Service.
        3. Provision of the Service
            3.1 takepayments shall provide the Service to the Merchant with effect from the Effective Date, subject to the provisions of these Conditions.
            3.2 Subject to clauses 3.4, 3.5, 3.7 and 11.9, takepayments warrants that the Service shall be provided:
                3.2.1 with reasonable care and skill; and
                3.2.2 in accordance with Applicable Laws.
            3.3 takepayments may (but shall not be obliged to), either itself or via the Relevant Provider:
                3.3.1 issue Service Instructions to the Merchant at any time regarding the use of the Service; and
                3.3.2 update, change or replace any such Service Instructions from time to time.
            3.4 The Merchant acknowledges and agrees that takepayments shall be entitled, at any time:
                3.4.1 to make changes to the Service, the Software and/or the Support from time to time, without prior notice, to comply with changes in Applicable Laws (or in the circumstances referred to in clauses 11.5 and 11.6); and
                3.4.2 on giving such notice to the Merchant as is reasonable in the circumstances, implement improvements or variations to the Service and/or the Support provided that they do not materially adversely affect the technical and/or performance quality of the Service or the Support (as applicable).
            3.5 takepayments does not give any warranties beyond those contained in clause 3.2.  In particular, but without prejudice to the foregoing, takepayments does not warrant that the Service, the Software or the Support:
                3.5.1 will be provided on a continuous or uninterrupted basis, or will be free of defects, bugs or errors;
                3.5.2 will achieve any particular performance criteria; or
                3.5.3 will be suitable for the Merchant’s use or purposes.
            3.6 It is the Merchant’s sole responsibility to ensure that the Service (including any applicable Performance Standards) and the Software are suitable for the Merchant’s intended use, meet the Merchant’s requirements and are suitable for the purposes of (including for the PCI DSS and any other standards required under) the Acquiring Agreement.
            3.7 Without prejudice to clause 3.5.1 (and subject always to clause 3.2.1):
                3.7.1 the Merchant acknowledges and agrees that interruptions may occur in the provision of the Service, whether by reason of breakdown or for any other reason, in the provision of the Service;
                3.7.2 if any such interruption does occur, takepayments shall use its reasonable endeavours to restore the provision of the Service as soon as reasonably practicable after becoming aware of the same; and
                3.7.3 provided that it complies with its obligations under clause 3.7.2, takepayments shall have no liability in respect of any such interruptions.
            3.8 Without prejudice to clauses 3.5 and 3.7 and subject to clause 3.9, takepayments will use reasonable endeavours to ensure that the Service has 99.9% availability, measured over each successive 4 week period 00:00:00 hours Sunday to 23:59:59 hours Saturday.
            3.9 The level of availability of the Service set out in clause 3.8 shall not apply to the extent that the Service is not available as a result of:
                3.9.1 any causes or circumstances beyond takepayments’ reasonable control;
                3.9.2 service failure by any a third party service provider (including any network/telecommunications provider); or
                3.9.3 any planned or emergency maintenance or development work affecting the Software, the Service or the Support (including for the purpose of Software Updates).
        4. Merchant Information
            4.1 The information in the Contract and obtained in accordance with clause 4.2 relating to the Merchant, together with other information obtained about the Merchant and its staff in connection with the Contract, may be used and disclosed by takepayments in accordance with clauses 4.3 and 10 and (where the information comprises Personal Data) the Privacy Policy.
            4.2 The Merchant agrees that:
                4.2.1 takepayments shall be entitled to obtain and use all information provided by or on behalf of the Merchant in the Acquirer Application Form as though such information was also provided directly to takepayments as part of the Contract;
                4.2.2 each Relevant Provider shall be entitled to notify takepayments of any breach of Cardholder Data (as defined by the PCI DSS) which relates to the Merchant’s use or receipt of the Service or the Support and provide to takepayments all information associated with such breach; and
                4.2.3 any of the foregoing information may be used and disclosed by takepayments for any of the purposes specified in clause 10.2 and shared with any third parties on the basis referred to in clauses 4.3 and 10 and (where the information comprises Personal Data) the Privacy Policy, including after termination of the Contract for any reason.
            4.3 Notwithstanding the entry into force of the Contract for the Service, takepayments may subsequently make all reasonable checks about the Merchant and any director, shareholder and/or other business owner of the Merchant including making financial checks (including checking the Merchant’s credit history and searching the files of licensed credit reference agencies, who may record the search) and seeking bank and/or trade references. The Merchant shall, promptly upon request by takepayments, provide any written authority which is required to enable such checks to be made.
            4.4 The Merchant represents and warrants to takepayments that all the information which the Merchant has provided to takepayments (in any form) in connection with the Acquiring Agreement and the Contract (including Personal Data relating to the Merchant and/or its staff) is complete and accurate.
            4.5 Without prejudice to any other provision of the Contract, the Merchant shall inform takepayments promptly (giving as much advance notice as is reasonably practicable, where appropriate) of any actual or proposed change in its ownership or trading name, address, telephone number or trading hours, or any closure of its business.
        5. Merchant’s General Obligations
            5.1 The Merchant shall (and shall ensure that all its staff who make use of the Service shall):
                5.1.1 (without prejudice to clause 5.2.7) ensure that it complies with the Service Instructions regarding its use of the Service;
                5.1.2 where applicable, promptly carry out installation of the Software in the manner specified by takepayments;
                5.1.3 permit takepayments or its duly authorised representatives to enter the Merchant Site (or, without prejudice to clause 5.2.2, any other premises from which the Merchant is or may be using or benefitting from the Service) at all reasonable times in order to verify the Merchant’s compliance with the Contract; and
                5.1.4 be solely responsible for the cost of any third-party charges incurred in using the Service (including the installation, rental and call charges associated with any internet, email, text and/or telephone services and any charges payable under the Acquiring Agreement).
            5.2 The Merchant shall not (and shall ensure that all its staff shall not) without the prior written consent of takepayments:
                5.2.1 permit any third party (other than relevant staff of the Merchant) to have use of the Service;
                5.2.2 (unless the nature of the Service permits otherwise) not make use of the Service in respect of any premises other than the Merchant Site and/or the Merchant’s business as operated at the Merchant Site;
                5.2.3 remove, deface, obstruct, alter or add to takepayments’ trade marks and trade names provided or displayed in connection with the Service);
                5.2.4 do (or permit to be done) any act or thing which will or may jeopardise the rights and/or interest of takepayments (whether in connection with the Service or otherwise);
                5.2.5 use the Service or the Support for any purpose other than its proper purpose as intended by takepayments, or in any manner other than that approved by takepayments;
                5.2.6 use the Service for processing any payment transactions other than those permitted under the terms of the Acquiring Agreement (and, for the avoidance of doubt, shall not use the Service for the processing of payment transactions by any payment processor other than the Acquirer or for any unlawful purpose); or
                5.2.7 (where takepayments has specified that a Certificate of Integration, or similar, from a Relevant Provider is necessary for use of the Service or the Support in connection with the Merchant’s technology and infrastructure) use the Service or the Support until it has received such a certificate.
            5.3 The Merchant shall not (and shall ensure that all its staff shall not) use the Service (including any facility provided in connection with the Service) or the Software to send, knowingly receive, upload, download, display or use any data or material:
                5.3.1 which is abusive, indecent, obscene, defamatory, racist, offensive or menacing;
                5.3.2 which introduces viruses, trojans, worms, logic bombs or other material which is malicious or harmful to the Service or the Support (including the Software) or the hosting or computer systems of takepayments or any Relevant Provider;
                5.3.3 which contravenes any Applicable Laws (including any laws or regulations relating to Personal Data or the transmission of any marketing communications); or
                5.3.4 in connection with the carrying out of any fraud or other criminal activity.
            5.4 The Merchant shall be solely responsible, at its own cost, for:
                5.4.1 the provision of all utilities and facilities (including email and/or text messaging services and all associated devices) necessary to enable use of the Service at all relevant times;
                5.4.2 for the provision of suitable firewalls and other appropriate security measures relating to the Merchant’s use of the Service;
                5.4.3 for the security, safe keeping and backing-up of all data and content used or processed by the Merchant in connection with the Service; and
                5.4.4 for ensuring that all systems, devices and software used by the Merchant in connection with the Service are regularly updated and that all such software is the latest available version and/or contains the latest available updates.
            5.5 If the Acquiring Agreement is terminated for any reason during the Service Term, the Merchant shall pay the Non-Use Charge each month from the date of such termination.  The Non-Use Charge shall remain payable each month until the termination of the Contract.
            5.6 If the Merchant breaches any of its obligations under the Contract (including failure to pay any of the Service Charges or the Relevant Charges on the Payment Date) then takepayments shall be entitled (at its option) to deploy a representative to visit the Merchant Site with a view to investigating and discussing the breach with the Merchant.  Where takepayments so deploys a representative then, regardless of whether or not such representative meets the Merchant on such visit (and even if the relevant breach is subsequently remedied by the Merchant), the Merchant shall pay the Default Visit Charge.  For the avoidance of doubt, takepayments shall be entitled to deploy a representative to visit the Merchant Site under this clause 5.6 on each occasion that the Merchant is in breach of the Contract (including each occasion that any payment of the Service Charges or the Relevant Charges was not paid on the Payment Date) and the Default Visit Charge shall be payable for each such visit.
            5.7 takepayments’ rights under clause 5.6 shall be without prejudice to any other rights and remedies of takepayments (including its rights to terminate the Contract under clause 8.2.1), subject always to clause 6.12.
        6. Invoicing, Payment of Service Charges and Payment of Relevant Charges
            6.1 takepayments may, at its discretion, either:
                6.1.1 issue an invoice for the Service Charges each month during the Service Term; or
                6.1.2 at any time, issue a single annual invoice for all of the Service Charges due to the expiry of the Minimum Period or due to the expiry of the relevant Extended Period (as the case may be).
            6.2 In addition to invoices for the Service Charges pursuant to clause 6.1, takepayments may also issue an invoice for the Relevant Charges (where applicable) at any time following such Relevant Charges being incurred.
            6.3 With effect from the Effective Date (and save as otherwise provided in clauses 6.4 and 9.4), the Service Charges shall be due and payable in advance on a monthly basis and in accordance with clause 6.5.
            6.4 Where applicable, the first payment of the Service Charges shall include (in addition to the Service Charges payable in advance for the following month) an amount equal to the pro-rata amount of monthly Service Charges in respect of the period between the Effective Date and the end of the month in which the Effective Date occurred.
            6.5 Payment of the Service Charges and the Relevant Charges (where applicable) shall be due on the date for payment specified in the relevant invoice issued by takepayments, which shall be no earlier than 3 (three) days after the date of the invoice.
            6.6 Payment of the Service Charges (including pursuant to clause 6.4 but excluding pursuant to clause 9.4) and the Relevant Charges (where applicable) shall be collected by takepayments on the Payment Date by direct debit in accordance with clause 6.7.
            6.7 The Merchant shall maintain a Merchant Bank Account with facilities to pay to takepayments the Service Charges (and the Relevant Charges where applicable) by direct debit via the Bankers Automated Clearing Services (BACS). The Merchant acknowledges and agrees that, pursuant to the BACS scheme, payment may be taken from the Merchant Bank Account at any time up to two working days after the Payment Date (or, where the Payment Date is a bank or public holiday, up to four working days after the Payment Date).
            6.8 The Merchant shall not without the prior written consent of takepayments (which shall not be unreasonably withheld):
                6.8.1 close or change the Merchant Bank Account; or
                6.8.2 cancel the direct debit for payment of the Service Charges (or the Relevant Charges where applicable) from the Merchant Bank Account.
            6.9 Without prejudice to clause 6.8, the Merchant shall give takepayments not less than 10 days’ advance notice of any proposed closure, change or cancellation of the Merchant Bank Account.
            6.10 The Merchant shall ensure that there are sufficient funds in the Merchant Bank Account to meet each correct direct debit request for the Service Charges (and the Relevant Charges where applicable) in accordance with clause 6.7.
            6.11 All payments to be made by the Merchant under the Contract shall be made without withholding or set-off on account of disputes, counterclaims or for any other reason whatsoever.
            6.12 Where takepayments deploys a representative pursuant to clause 5.6 as a result of the Merchant’s failure to pay any of the Service Charges or Relevant Charges on the Payment Date then the Non-Payment Charge shall not be payable (but the Default Visit Charge shall remain payable in accordance with clause 5.6).  For the avoidance of doubt, the Non-Payment Charge shall be payable under clause 6.13.1 on each occasion that the Merchant fails to pay any of the Service Charges or Relevant Charges on the Payment Date but where takepayments does not deploy a representative pursuant to clause 5.6.
            6.13 If the Merchant fails to pay any of the Service Charges or the Relevant Charges on the Payment Date then (without prejudice to takepayments’ other rights and remedies, including its rights to deploy a representative under clause 5.6 and to terminate the Contract under clause 8.2.1) the Merchant shall:
                6.13.1 subject to clause 6.12, pay the Non-Payment Charge (for each occurrence of non-payment of any of the Service Charges and Relevant Charges);
                6.13.2 pay the Direct Debit Resubmission Charge (for each occurrence that a direct debit is unsuccessful and is resubmitted for payment);
                6.13.3 pay interest on all unpaid sums at a rate of 4% per annum above the base rate prevailing at the relevant time at the Bank of England (payable daily) until the sums are paid, whether before or after any judgment; and
                6.13.4 pay all fees, costs, charges and/or legal costs properly incurred (or to be incurred) by takepayments as a result of or in connection with such non-payment.
            6.14 The Service Charges and the Relevant Charges may be increased as follows:
                6.14.1 on the expiry of the Minimum Period, the Service Charges and the Relevant Charges may be increased by a percentage amount equal to the percentage increase of the RPI as published on such expiry measured in respect of the duration of the Minimum Period; and
                6.14.2 on each subsequent anniversary of the expiry of the Minimum Period, the Service Charges and the Relevant Charges (as previously increased pursuant to clause 6.14.1) may be increased by a percentage amount equal to the percentage increase of the RPI as published on such anniversary measured in respect of the previous 12 month period.
            6.15 Should the Merchant require any paper copies of invoices or statements which have previously been provided by takepayments in connection with the Contract, the Merchant must contact takepayments and such copies will be issued following payment (by debit or credit card) of the relevant Statement Fee.
        7. Liabilities
            7.1 Subject to clauses 7.2 and 7.3, takepayments’ maximum aggregate liability arising under or in connection with the Contract (including any liability for breach of the Contract and any liability for the acts or omissions of its employees, agents or subcontractors), whether arising in contract, tort (including negligence), misrepresentation or otherwise, shall be limited to:
                7.1.1 £250,000 (two hundred and fifty thousand pounds sterling) for loss of or damage to physical property; and
                7.1.2 in all other cases, the value of all Service Charges paid by the Merchant to takepayments (or, where an event giving rise to any such liability occurs prior to the expiry of the Minimum Period, the value of all Service Charges paid and payable by the Merchant to takepayments in respect of the Minimum Period).
            7.2 Subject to clause 7.3, takepayments shall not be liable to the Merchant under or in connection with the Contract for any:
                7.2.1 special, indirect or consequential loss;
                7.2.2 loss of profit;
                7.2.3 loss of use (including loss of use or receipt of the Service and interruptions in the Service);
                7.2.4 loss of business or revenue;
                7.2.5 loss of (or depletion of) goodwill; or
                7.2.6 any increased costs or expenses,
        in each case, however caused (even if foreseeable).
            7.3 Nothing in the Contract excludes or limits takepayments’ liability for:
                7.3.1 death or personal injury caused by negligence
                7.3.2 fraud (including deception, dishonesty and fraudulent misrepresentation); or
                7.3.3 any liability if and to the extent that it cannot be excluded or limited by Applicable Laws.
        8. Termination and Suspension
            8.1 takepayments shall be entitled to terminate the Contract at any time without cause by giving the Merchant not less than 30 days’ notice.
            8.2 takepayments shall be entitled to terminate the Contract immediately on giving notice to the Merchant if:
                8.2.1 the Merchant is in material breach of the Contract and, if capable of remedy, does not cure such breach within 7 days of receiving notice of such breach;
                8.2.2 the Merchant is or becomes bankrupt or insolvent or is in serious financial difficulty;
                8.2.3 the Merchant ceases to trade or has an administrator, receiver or similar appointed over the whole or any part of its assets;
                8.2.4 the Merchant fails to pay any money due under the Contract on the due date for payment;
                8.2.5 the Merchant, or any person engaged in the Merchant’s business, has been convicted of a criminal offence and/or a judgment in a civil action involving dishonesty;
                8.2.6 the Merchant engages in any activity which, in the absolute discretion of takepayments, adversely affects (or is likely to adversely affect) the reputation of takepayments;
                8.2.7 any judgment debt or enforcement proceedings are issued against the Merchant by any person; or
                8.2.8 there is a change in ownership/control of the Merchant and/or the Merchant Site.
            8.3 Without prejudice to any other rights or remedies available to takepayments (including its rights to terminate the Contract in accordance with its provisions), takepayments shall be entitled to suspend the provision of the Service (or part thereof) at any time without prior notice (and without liability to the Merchant):
                8.3.1 if the Merchant does not pay any Charges on the Payment Date (until such payment is made);
                8.3.2 if (and for the duration that) the Merchant is otherwise in breach of (or takepayments reasonably considers that the Merchant may be in breach of) any other provision of the Contract;
                8.3.3 if and to the extent required to do so by Applicable Laws or by any Regulatory Body (or to avoid any breach in Applicable Laws or the requirements of any Regulatory Body);
                8.3.4 if and to the extent necessary pending implementation of any Software Updates pursuant to clause 11.6; and/or
                8.3.5 pending receipt by takepayments of any necessary information, assistance or materials from the Merchant relevant to the Service.
            8.4 For the avoidance of doubt, if takepayments suspends the provision of the Service pursuant to clause 8.3 then any applicable Performance Standards shall not apply to any period in which the Service is suspended (and where applicable the Performance Standards shall be deemed to be adjusted on a pro-rata basis to the extent necessary to accommodate the period of any such suspension).
            8.5 Without prejudice to clause 13.12 or any other provision of the Contract, if the Merchant wishes to effect any change to its legal entity or basis of trading (such as a change from a sole trader to a partnership or a limited company) then it may contact takepayments to request termination of the Contract prior to end of the Minimum Period or the relevant Extended Period (as the case may be) in order for the relevant new entity to enter into a new contract with takepayments which shall replace the Contract.  If takepayments agrees to such termination then the Merchant shall be liable to pay the Change of Legal Entity Charge on demand.
        9. Consequences of Termination
            9.1 Termination of the Contract (for any reason) shall be without prejudice to any other rights or remedies which takepayments and the Merchant may be entitled to under the Contract or at law and shall not affect any rights or liabilities of takepayments or the Merchant which have accrued as at the date of termination.
            9.2 Without prejudice to clause 9.1, all applicable Service Charges and all applicable Relevant Charges as at the date of termination of the Contract (for any reason) shall remain due and payable notwithstanding such termination.
            9.3 On termination of the Contract (for any reason):
                9.3.1 the Merchant shall no longer be entitled to any use or benefit of the Service; and
                9.3.2 the Merchant shall immediately stop making (or purporting to make) any use of the Service.
            9.4 If the Contract is terminated for any reason (other than termination by takepayments pursuant to clause 8.1) before the end of the Minimum Period or the relevant Extended Period (as the case may be) then:
                9.4.1 the Merchant shall (without prejudice to clauses 9.1 and 9.2) remain liable to pay an amount equal to all Service Charges payable for the remainder of the Minimum Period or such Extended Period (as applicable); and
                9.4.2 the Acquiring Agreement shall be deemed to have been terminated with effect from the date of termination of the Contract and the Merchant shall (without prejudice to clauses 9.1 and 9.2) be liable to pay an amount equal to the aggregate amount of the Non-Use Charges per month for the remainder of the Minimum Period or such Extended Period (as applicable); and
                9.4.3 the Merchant shall pay the Early Termination Administration Charge relating to takepayments’ processing of such termination.
            9.5 Where applicable, the Early Termination Amounts may be invoiced by takepayments at any time after termination of the Contract and shall be payable within 7 days of the date of takepayments’ invoice.
            9.6 The provisions of the Contract which expressly or by implication have effect after termination of the Contract shall continue to be enforceable notwithstanding such termination (for any reason).
        10. Data Protection
            10.1 This clause 10 applies to any Personal Data which takepayments obtains about the Merchant and/or any of its staff in their capacity as a data subject (as such term is defined by the GDPR).  For the avoidance of doubt, this clause 10 applies to any Personal Data which is obtained by takepayments pursuant to clause 4.1 or from any third parties from time to time.
            10.2 The Merchant acknowledges and agrees that (where applicable) Personal Data of the Merchant (and Personal Data of the Merchant’s staff which is provided by the Merchant to takepayments) may be processed by takepayments and the Relevant Provider (and their respective agents) for various purposes relating to the administration and performance of the Contract and as otherwise set out in the Privacy Policy.
            10.3 Where the Merchant provides Personal Data about the Merchant’s staff in connection with the administration or performance of the Contract, the Merchant warrants and represents that it has the consent of all such staff:
                10.3.1 to pass their Personal Data to takepayments;
                10.3.2 for takepayments to use and disclose their Personal Data for any of the purposes set out in the Privacy Policy (which includes disclosure to and use by the Relevant Provider); and
                10.3.3 (where applicable) for takepayments to make any financial and other checks as referred to in clause 4.3.
            10.4 The Merchant and any applicable members of its staff have various rights relating to the Personal Data which is processed by takepayments.  Details of such rights and how to exercise them are set out in the Privacy Policy.
        11. Software and Intellectual Property Rights
            11.1 All Intellectual Property Rights in the Service and the Software, or otherwise provided or made available to the Merchant in connection with the Contract, shall remain with takepayments (or, where applicable, the Relevant Provider or any other third-party owner of such Intellectual Property Rights) and shall not be transferred to the Merchant in any way.  The only right which the Merchant shall have in respect of all such Intellectual Property Rights shall be the right to use the Service and the Software, during the Service Term, strictly in accordance with the terms of the Contract.
            11.2 The Merchant shall (and shall ensure that all its staff who make use of the Service shall) only use takepayments’ trade marks and/or trade names with the prior written consent of takepayments and provided that they are only used in accordance with takepayments’ guidelines prevailing at the relevant time and any applicable terms of the Contract.
            11.3 Without prejudice to clause 11.1, the Merchant acknowledges and agrees that:
                11.3.1 the Contract does not convey or transfer to the Merchant any ownership or proprietary interest in any Intellectual Property Rights in the Service or the Support (including in the Software and any documents provided or made available in connection with the provision of the Service or the Support);
                11.3.2 the Merchant’s right to use the Software is non-exclusive and revocable and is strictly limited to a licence to use such Software during the Service Term for the purpose of the Service and in the format in which it is provided;
                11.3.3 the Software cannot be used by the Merchant on behalf of any third parties (save for duly authorized staff of the Merchant) and the Merchant shall not (and shall ensure that all its staff shall not) grant, or purport to grant, to any third parties any right or licence to use the Software; and
                11.3.4 it shall notify takepayments immediately on becoming aware of any claim by any third party that the Service or the Support (or any part thereof, including the Software and any documents provided or made available in connection with the provision of the Service or the Support) infringes any Intellectual Property Rights of any third party.
            11.4 The Merchant shall not (and shall ensure that all its staff shall not):
                11.4.1 copy, translate, adapt, vary, modify, add to, decompile, disassemble, reverse-engineer, correct (or attempt to correct) errors in, all or any part of the Service or the Support (including the Software and any documents provided or made available in connection with the provision of the Service or the Support) or create derivative works of the same for any purpose;
                11.4.2 tamper with, obscure, remove or deface any trade marks, trade names, logos or service marks which appear in connection with anything made available to the Merchant pursuant to the provision of the Service or the Support (including in the Software and any documents provided or made available in connection with the provision of the Service or the Support);
                11.4.3 cause or permit anything to happen which may harm, damage or endanger the Intellectual Property Rights of takepayments or a Relevant Provider (whether in connection with the Service or otherwise); or
                11.4.4 use the Software for any purpose other than the proper use of the Service or in any format other than that which is provided by or on behalf of takepayments or the Relevant Provider (as applicable).
            11.5 If the Service or the Support (or any part thereof) infringes a third party’s Intellectual Property Rights, or if takepayments considers that it may do so, takepayments shall be entitled to make such modifications to the Service and/or the Support as may be necessary to avoid further infringement (or potential infringement), provided that any such modifications shall not materially diminish or otherwise materially adversely affect the technical and/or performance quality of the Service or the Support (as applicable). The provisions of this clause 11.5 sets out takepayments’ entire liability and responsibility in respect of any actual or alleged infringement by the Service or the Support (or any part thereof) of the Intellectual Property Rights of any third party.
            11.6 takepayments shall not be liable for any loss or damage in any way whatsoever caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect the Merchant’s computer equipment, computer programs, devices, data or other material relating to the Merchant’s use of the Service (including in connection with the Merchant downloading any material or accessing any link), except if and to the extent directly caused by takepayments’ negligence or any breach of the Contract by takepayments.
            11.7 takepayments may at any time release new versions or releases of the Software and/or create and implement patches to the Software (together and individually, “Software Updates”). takepayments shall endeavour to notify the Merchant about any Software Updates, but takepayments shall be entitled to apply any Software Updates (whether remotely or otherwise) as it requires from time to time, in its absolute discretion.
            11.8 The Merchant shall (and shall ensure that, where applicable, its staff shall) provide all such assistance and access (including access to the Merchant’s computer systems and the Merchant Site where applicable) which may be reasonably required by takepayments in relation to any release, application, installation or implementation of any Software Update.
            11.9 Without prejudice to clauses 8.2.1, 8.3.2 and 11.8 or any other provision of the Contract, if any Software Update cannot be released, applied, installed, implemented or otherwise made available as a result of any failure of the Merchant to provide adequate access and/or assistance relating to such Software Update, the Merchant acknowledges and agrees that takepayments shall have no responsibility or liability to the Merchant if and to the extent that such failure results in the performance, functionality and/or security of the Software being adversely affected.
        12. Notices
            12.1 For the avoidance of doubt (but without prejudice to the generality of clause 2.3), notices given by either party in respect of any Contract for a Service shall not relate to, or otherwise affect, affect any other Contract for another Service.
            12.2 All notices and consents to be given by takepayments under the Contract shall be given in writing and may be delivered personally or sent by post, SMS, facsimile or email using the contact details set out on the Application Form or otherwise notified to or obtained by takepayments during the Service Term, or (where the functionality of the Software permits) sent to the Merchant by way of message notification via the Software.
            12.3 All notices and consents to be given by the Merchant under the Contract shall be given in writing and sent by post to takepayments at 4th Floor, Highbank House, Exchange Street, Stockport SK3 0ET, marked for the attention of Customer Services (or to such other address and/or marked for such other person as may be notified by takepayments from time to time).
            12.4 Correctly addressed notices and consents in accordance with the foregoing provisions of this clause 12 shall be deemed to have been delivered as follows:
                12.4.1 if sent by post, two days after posting; and
                12.4.2 if delivered personally or sent by facsimile, SMS, email or terminal message notification, at the time of delivery or sending.
            12.5 For the avoidance of doubt but without prejudice to the generality of clause 12.1, takepayments shall be entitled to send to the Merchant by SMS or email, using the contact details set out on the Application Form or otherwise notified to or obtained by takepayments during the Service Term, or (where the functionality of the Software permits) by way of message notification via the Software:
                12.5.1 copies of any invoices and statements relating to the Contract;
                12.5.2 reminders or notices relating to any overdue amounts payable under the Contract;
                12.5.3 requests or reminders relating to any information to be provided by the Merchant under the Contract;
                12.5.4 notice of any changes to the nature and scope of the Service or the Support pursuant to clause 3.4;
                12.5.5 notice of any changes to the Contract pursuant to clause 13.11;
                12.5.6 Service Instructions or any updates to Service Instructions; and
                12.5.7 (without prejudice to clauses 3.5.1 and 3.7) notices regarding availability of the Service or the Support or any operating or technical issues with the Service or the Support.
        13. General matters
    Value Added Tax
            13.1 The Service Charges and the Relevant Charges are exclusive of any applicable value added tax and any other applicable taxes and duties or similar charges, which shall be payable by the Merchant in addition at the prevailing rate from time to time in force.
    Force Majeure
            13.2 Save in respect of payment of the Service Charges and Relevant Charges, each party shall be relieved of its obligations to perform any obligation in the Contract to the extent and for the duration that it is prevented from doing so due to causes beyond the affected party’s reasonable control including fire, flood, failure of utility or telecommunications supplies, failure of banking services, extreme weather conditions, disaster, emergency or where Applicable Laws delay or prohibit the relevant obligation.
    Relationship of the parties
            13.3 Nothing in the Contract is intended to, or shall be deemed to, establish any partnership or joint venture between the parties, constitute any party the agent of the other, nor authorise either party to make or enter into any commitments for or on behalf of the other.
    Subcontracting and assignment
            13.4 takepayments shall be entitled to transfer and/or subcontract its rights and/or obligations under the Contract. The Contract is personal to the Merchant and the Merchant shall not be entitled to transfer or subcontract the whole or any part of the Contract to any other person.
            13.5 takepayments may from time to time appoint a payment collections agent which shall be entitled to collect from the Merchant (including by way of debiting the Merchant Bank Account) any sums due to takepayments pursuant to the Contract.
    Acquiring Agreements
            13.6 takepayments is officially licensed by MasterCard and Visa International as an independent Sales Organisation and Member Service Provider and works in association with the Acquirer which undertakes the processing of payment transactions. The Merchant acknowledges and agrees that takepayments is remunerated by the Acquirer in connection with introducing the Merchant to the Acquirer.  Notwithstanding the foregoing, the Acquiring Agreement is a separate contract entered into between the Merchant and the Acquirer and takepayments has no rights or responsibilities under the Acquiring Agreement.
    Confidentiality
            13.7 The parties agree to keep confidential and not to disclose to any person (other than other companies in its group and/or professional advisers in each case on a need to know basis) any confidential information of the other party (unless such was in the public domain other than by breach of this provision or Applicable Laws requires the disclosing party to disclose such information). This clause 13.7 shall survive the expiry and/or termination of the Contract. The Merchant shall ensure that all of its staff comply with these confidentiality obligations.
    Third party rights
            13.8 Save as provided in clause 13.9, nothing in the Contract is intended to confer a benefit on any third party and a person who is not a party to the Contract is not entitled to enforce any of its terms, whether under the Contracts (Rights of Third Parties) Act 1999 or otherwise.
            13.9 Each Relevant Provider shall be entitled to directly enforce against the Merchant any breaches (or alleged breaches) of clauses 5.2.7, 11.3.3 and 11.4.
            13.10 Notwithstanding clause 13.9, the Contract may be terminated or varied without reference to any Relevant Provider or any other person and sections 2(1) (a) to (c) of the Contracts (Rights of Third Parties) Act 1999 shall not apply.
    Changes to the Contract
            13.11 takepayments shall be entitled to change any terms of the Contract (including any charges in the Other Charges List) without reference to any other person by giving the Merchant at least 7 days’ notice.
            13.12 The Merchant may not change any of the terms of the Contract unless such change is agreed in writing with takepayments or takepayments otherwise notifies the Merchant of its agreement to such change.
            13.13 Without prejudice to clause 13.12, in the event that takepayments agrees to make any Requested Change then the Merchant shall be liable to pay the Administration Charge on demand (for each occasion that any Requested Change is so agreed).
    Waiver
            13.14 The failure of a party to exercise or enforce any right under the Contract, or the grant by that party of any forbearance, delay or indulgence, shall not be construed as a waiver of that right, nor operate to bar the exercise or enforcement of it at any time or times thereafter.
    Severability
            13.15 If any provision of the Contract (or part of any provision) is found by any court or other authority of competent jurisdiction to be invalid, illegal or unenforceable, that provision (or part) shall, to the extent required, be deemed not to form part of the Contract and the validity and enforceability of the other provisions of the Contract shall not be affected.  Where the removal of any such provision (or part) would result in the intentions of the parties not being met with regard to the remaining provisions of the Contract, an alternative provision which is lawful and enforceable shall (where appropriate and to the extent necessary to give effect to such intentions) be deemed to be included in the Contract in its place.
    Entire Agreement
            13.16 The Contract supersedes all prior agreements, arrangements and understandings between the parties in respect of its subject matter and constitutes the entire agreement between the parties in relation to the provision of the Service.  Each party confirms that it is not relying on any understanding or on any statement, representation, warranty or covenant of the other party (whether negligently or innocently made) except as specifically set out in the Contract. Nothing in this clause 13.16 shall operate to limit or exclude any liability of either party for fraud.
    Complaints
            13.17 takepayments operates a complaints procedure relating to the Contract and a copy of the current complaints procedure is available free of charge on request, by contacting takepayments’ Customer Services department.
    Law and jurisdiction
            13.18 The Contract and any matter arising from or in connection with it is governed by and shall be interpreted in accordance with English law and (without prejudice to the operation of the complaints procedure as referred to in clause 13.17) each party irrevocably submits to the jurisdiction of the English courts in relation to all claims and matters arising out of or in connection with the Contract.
        14. Other Charges List
            14.1 takepayments’ sundry charges under the Contract are as follows:
    Non-Payment Charge					£50
    Direct Debit Resubmission Charge			£10
    Default Visit Charge					£80
    Non-Use Charge						£30
    Administration Charge					£25
    Early Termination Administration Charge		£40
    Change of Legal Entity Charge				£75
    Statement Fees:
        • copies of statements/invoices which were issued 0-3 months prior to the date of the Merchant’s request - £5 per statement/invoice
        • copies of statements/invoices which were issued 4-6 months prior to the date of the Merchant’s request - £7.50 per statement/invoice
        • copies of statements/invoices which were issued more than 6 months prior to the date of the Merchant’s request - £25.00 per statement/invoice.
    </pre
        >
      </QCardSection>
      <QCardActions align="right">
        <QBtn color="primary" flat label="Cancel" @click="onCancelClick" />
        <QBtn color="primary" label="Agree" @click="onAgreeClick" />
      </QCardActions>
    </QCard>
  </QDialog>
</template>

<script setup>
const emit = defineEmits(["hide", "ok"])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent()

const onAgreeClick = () => {
  onDialogOK()
}

const onCancelClick = () => {
  onDialogCancel()
}
</script>

<style lang="scss" module>
.terms {
  font-family: unset;
  white-space: pre-wrap;
}
</style>
