<template>
  <template v-if="loading">
    <BaseLoadingSpinner />
  </template>
  <div v-else :class="$style.container" class="fit">
    <div :class="$style.title">
      <h4> Today Report for </h4>
      <p>{{ DateUtils.format(generatedAt, "EEEE do LLLL") }}</p>
    </div>

    <div :class="$style.time">
      <p>
        Status at {{ formatTime(generatedAt) }}:
        {{ summary.open ? "Open" : "Closed" }} since
        {{ formatTime(startDatetime) }}
      </p>
      <p>
        {{ summary.open ? "Closing" : "Open" }}
        in {{ timeUntilEnd }} at {{ formatTime(endDatetime) }}
      </p>
      <p v-if="summary.increaseOverLastWeek">
        {{ summary.increaseOverLastWeek.toFixed(2) }}% increase over this period last week.
      </p>
    </div>
    <div :class="$style.summary">
      <table :class="$style.statTable">
        <thead>
          <tr :class="$style.statTableHeader">
            <th></th>
            <th>Transactions</th>
            <th>Revenue</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="stat in stats" :key="stat.label">
            <td>{{ stat.label }}</td>
            <td :class="$style.count">{{ stat.stats.count }}</td>
            <td :class="$style.total">{{ formatPrice(stat.stats.total) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="summary.cashierBreakdown.length > 0" :class="cashierBreakdownContainer">
      <table :class="$style.statTable">
        <thead>
          <th>Cashier</th>
          <th>Transactions</th>
          <th>Revenue</th>
        </thead>
        <tbody>
          <tr v-for="breakdown in summary.cashierBreakdown" :key="breakdown.id">
            <td>{{ breakdown.cashierName }}</td>
            <td :class="$style.count">{{ breakdown.count }}</td>
            <td :class="$style.total">{{ formatPrice(breakdown.total) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  title: "Daily Summary",
}
</script>

<script setup>
import ReportsAPI from "@/api/reports"
import DateUtils from "@/utils/dateUtils"
import { formatPrice } from "@/utils/currency"

const summary = ref()
const loading = ref(true)

const getReport = async () => {
  loading.value = true
  const { data } = await ReportsAPI.todayReport()
  summary.value = data
  loading.value = false
}
onMounted(async () => {
  getReport()
})

const startDatetime = computed(
  () => summary.value?.start && DateUtils.parseISO(summary.value.start)
)
const endDatetime = computed(
  () => summary.value?.end && DateUtils.parseISO(summary.value.end)
)
const generatedAt = computed(
  () =>
    summary.value?.generatedAt && DateUtils.parseISO(summary.value.generatedAt)
)

const formatTime = (datetime) => datetime && DateUtils.format(datetime, "HH:mm")

const timeUntilEnd = computed(
  () =>
    generatedAt.value &&
    DateUtils.formatDuration(
      DateUtils.intervalToDuration({
        start: generatedAt.value,
        end: endDatetime.value,
      }),
      { format: ["days", "hours", "minutes"] }
    )
)

const stats = computed(
  () =>
    summary.value !== undefined && [
      { label: "Card", stats: summary.value.totalCardPayments },
      { label: "Cash", stats: summary.value.totalCashPayments },
      { label: "Total", stats: summary.value.totalPayments },
    ]
)
</script>
<style lang="scss" module>
.container {
  padding: 10px;
}
.title {
  margin-bottom: 14px;
  text-align: center;
  p {
    font-size: 18px;
  }
}

.statTable {
  margin-top: 20px;
  width: 100%;
  border-radius: 4px 4px 0 0;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid rgba(239, 239, 245, 1);

  th {
    white-space: nowrap;
    text-align: inherit;
    padding: 12px;
    vertical-align: inherit;
    text-transform: none;
    border: none;
    font-weight: 500;
    background-color: rgba(250, 250, 252, 1);
    border-color: rgba(239, 239, 245, 1);
    border-bottom: 1px solid rgba(239, 239, 245, 1);
    border-right: 1px solid rgba(239, 239, 245, 1);
  }

  td {
    padding: 12px;
    border-right: 1px solid rgba(239, 239, 245, 1);
    border-bottom: 1px solid rgba(239, 239, 245, 1);
  }

  .count {
    text-align: center;
  }
  .total {
    text-align: right;
  }
}
</style>
