<template>
  <QList separator>
    <template v-if="!shoppingCart.hasItems">
      <div :class="$style.noItems"> No items added </div>
    </template>
    <template v-else>
      <template
        v-for="category in shoppingCart.itemsByCategory"
        :key="category.id"
      >
        <div style="padding: 0.5rem 1rem">
          <QExpansionItem
            default-opened
            :class="$style.expansionItem"
            :header-class="$style.expansionItem"
            :expand-icon-class="$style.expansionChevron"
          >
            <template v-slot:header>
              <QItemSection>
                <QItemLabel style="font-weight: 600">
                  {{ categoryLabel(category) }}
                </QItemLabel>
                <QItemLabel
                  style="font-size: 1.2rem; font-weight: 700; color: #65626b"
                >
                  {{ categoryCaption(category) }}
                </QItemLabel>
              </QItemSection>
            </template>
            <QSeparator />
            <template v-for="item in category.items" :key="item.internalId">
              <QItem clickable>
                <QItemSection>
                  <QItemLabel style="font-size: 0.8rem; font-weight: 600">
                    {{ item.product.name }}
                  </QItemLabel>
                  <QItemLabel style="margin-top: 0" caption lines="1">
                    <template
                      v-for="attribute in item.product.attributes"
                      :key="attribute.id"
                    >
                      <QChip
                        :class="$style.productAttributeLabel"
                        :style="getAttributeStyle(attribute)"
                        dense
                      >
                        <BaseToolTip> {{ attribute.value }}</BaseToolTip>
                        {{ attribute.shortLabel }}
                      </QChip>
                    </template>
                    <span
                      v-if="
                        item.product.measure && item.product.measure !== '.'
                      "
                      :class="$style.productMeasure"
                    >
                      {{ item.product.measure }}
                    </span>
                    {{ item.product.description }}
                  </QItemLabel>
                  <QItemLabel
                    v-if="
                      item.product.options && item.product.options.length > 0
                    "
                    style="margin-top: 0"
                    caption
                  >
                    Options selected:
                    <QBtn
                      v-if="
                        item.product.options && item.product.options.length > 0
                      "
                      flat
                      dense
                      label="Edit"
                      size="sm"
                      style="bottom: 1px; color: #b20b63"
                      @click="editItem(item)"
                    />
                  </QItemLabel>
                  <template v-for="option in item.options" :key="option.id">
                    <QItemLabel style="margin-top: 0" caption>
                      <template
                        v-for="optionChoice in option"
                        :key="optionChoice.id"
                      >
                        <span>- {{ optionChoice.label }} </span>
                        <br />
                      </template>
                    </QItemLabel>
                  </template>
                </QItemSection>

                <QItemSection side style="display: inline">
                  <QChip
                    style="margin-right: 2px; border-radius: 8px 0 0 8px"
                    :class="$style.productChip"
                  >
                    {{ costOfItemAsString(item) }}
                  </QChip>
                  <QChip
                    style="border-radius: 0 8px 8px 0"
                    :class="$style.productChip"
                    clickable
                    @click="removeItem(item)"
                  >
                    <QIcon name="clear" size="1.5rem" />
                  </QChip>
                </QItemSection>
              </QItem>
            </template>
          </QExpansionItem>
        </div>
      </template>
    </template>
  </QList>
</template>

<script setup>
import ProductOptionsFormModal from "@/components/ProductOptionsFormModal.vue"

import { useQuasar } from "quasar"
const $q = useQuasar()

import { useShoppingCartStore, costOfItemAsString } from "@/pinia/shoppingCart"
const shoppingCart = useShoppingCartStore()

const categoryLabel = (category) => {
  let label = category.label
  let length_ = category.items.length
  return label ? `${label} x ${length_}` : `Top Level Products x ${length_}`
}
const categoryCaption = (category) => {
  return formatPrice(category.price)
}

const editItem = (item) => {
  $q.dialog({
    component: ProductOptionsFormModal,
    componentProps: {
      item: item,
    },
  }).onOk(() => {})
}

const formatPrice = (price) => {
  return `£${(price / 100).toFixed(2)}`
}
const removeItem = (item) => {
  shoppingCart.removeItem(item.internalId)

  // This just ensures we also reset the cart method if all items are deleted
  if (!shoppingCart.hasItems) {
    shoppingCart.reset()
  }
}
const getAttributeStyle = (attribute) => {
  return {
    background: attribute.displayColor || "#000000",
    margin: "1px",
  }
}
</script>

<style lang="scss" module>
.productMeasure {
  padding-right: 4px;
  text-transform: lowercase;
  opacity: 0.7;
}

.productAttributeLabel {
  display: inline-block;
  padding-right: 4px;
  // opacity: 0.7;
  font-size: small;
  font-weight: 600;
}

.noItems {
  padding: 1rem;
}

.expansionItem {
  background: white;
  border: 2px;
  border-radius: 20px;
  box-shadow: 2px 3px 8px #65626b;
}

.expansionChevron {
  color: #b20b63;
}

.productChip {
  padding: 0.5em;
  margin: 0;
  font-weight: 600;
  color: white;
  background: #b20b63;
}
</style>
