<template>
  <div :class="$style.card">
    <EditPasswordForm
      :errors="errors"
      require-current-password
      :submitting="submitting"
      @submit="submit"
      @cancel="cancel"
    >
      <template v-slot:submit-label> Change Password </template>
    </EditPasswordForm>
  </div>
</template>

<script>
export default {
  title: "Change Password",
}
</script>

<script setup>
import Password from "@/api/password"

const router = useRouter()

import toast from "@/toast"

const errors = ref({})
const submitting = ref(false)

const submit = async (validatedForm) => {
  submitting.value = true
  try {
    await Password.update(validatedForm)
    router.push("/")
    toast.success({
      message: "Password has been updated",
    })
  } catch (error) {
    errors.value = error.response.data
    toast.error({
      message: "Something went wrong. Please check fields for errors.",
    })
  } finally {
    submitting.value = false
  }
}
const cancel = () => {
  router.back()
}
</script>

<style lang="scss" module>
.card {
  width: 100%;
  max-width: 35rem;
  padding: 0.25rem;
}
</style>
