<template>
  <div :class="$style.container">
    <div v-if="isSmallDevice" :class="$style.reportSelectContianer">
      <QBtn
        :label="showChart ? 'Revenue Graph' : 'Transaction History'"
        icon="expand_more"
        flat
        no-caps
      >
        <QMenu fit>
          <QList>
            <QItem v-close-popup clickable @click="() => (showChart = false)">
              <QItemSection>Transaction History</QItemSection>
            </QItem>
            <QItem v-close-popup clickable @click="() => (showChart = true)">
              <QItemSection>Revenue Graph</QItemSection>
            </QItem>
          </QList>
        </QMenu>
      </QBtn>
    </div>
    <VueApexchart
      width="100%"
      height="300"
      :options="chartOptions"
      :series="chartData"
      :style="chartStyle"
    />
    <QTable
      v-if="!isSmallDevice || !showChart"
      v-model:pagination="tablePagination"
      :rows="tableData"
      :columns="tableColumns"
      row-key="id"
      :visible-columns="visibleColumns"
      :loading="tableLoading"
      :table-header-class="$style.tableHeaderClass"
      :class="$style.table"
      @request="onTableUpdate"
      @row-click="handleRowClick"
    >
      <template v-slot:top>
        <div :class="$style.exportFieldsContainer">
          <div :class="$style.dateInputsContainer">
            <DateInput
              v-model="exportFromDate"
              :label="isAndroidBuild ? 'Email Report From' : 'Export From'"
              :date-is-selectable="fromDateIsSelectable"
            />
            <DateInput
              v-model="exportToDate"
              :label="isAndroidBuild ? 'Email Report To' : 'Export To'"
              :date-is-selectable="toDateIsSelectable"
            />
          </div>
          <QBtn
            v-if="isAndroidBuild"
            color="primary"
            icon-right="email"
            label="Email Report"
            no-caps
            @click="onEmailReportClick"
          />
          <QBtn
            v-else
            color="primary"
            icon-right="archive"
            label="Export to csv"
            no-caps
            :loading="exporting"
            @click="onExportClick"
          />
        </div>
      </template>
    </QTable>
  </div>
</template>

<script>
import ReportsAPI from "@/api/reports"
import VueApexchart from "vue3-apexcharts"
import InvoiceDetailsDialog from "@/components/InvoiceDetailsDialog.vue"
import { exportFile } from "quasar"
import { Capacitor } from "@capacitor/core"

export default {
  title: "Transactions",
  components: {
    VueApexchart,
  },
  props: {},
  data() {
    return {
      chartOptions: {
        title: {
          text: "Revenue per hour over the previous 24 hours",
          align: "center",
        },
        chart: {
          zoom: {
            enabled: false,
          },
          id: "revenue",
        },
        xaxis: {
          tickAmount: 3,
          type: "numeric",
          labels: {
            formatter: this.numberToDate,
            show: true,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return "£" + (value / 100).toFixed(2)
            },
          },
          title: {
            text: "Revenue per hour",
          },
        },
        colors: ["#65626B", "#B20B63"],
      },
      chartData: [],
      tablePagination: {
        sortBy: "createdAt",
        descending: false,
        page: 1,
        rowsPerPage: 50,
        rowsNumber: 0,
      },
      tableColumns: [
        {
          name: "createdAt",
          label: "Created",
          field: "createdAt",
          sortable: false,
          align: "left",
          format: (isoString, payment) =>
            this.$dateFns.format(
              this.$dateFns.parseISO(isoString),
              "dd/MM HH:mm"
            ),
        },
        {
          name: "amountGross",
          label: "Amount Paid",
          field: "amountGross",
          sortable: false,
          align: "left",
          format: this.formatPrice,
        },
        {
          name: "refunded",
          label: "Refunded",
          field: "refunded",
          sortable: false,
          align: "left",
          format: (refunded, payment) => (refunded ? "Yes" : "No"),
        },
        {
          name: "amountRefunded",
          label: "Amount Refunded",
          field: "amountRefunded",
          sortable: false,
          align: "left",
        },
        {
          name: "paymentType",
          label: "Payment Type",
          field: "type",
          sortable: false,
          align: "left",
        },
        {
          name: "itemCount",
          label: "Item Count",
          field: "itemCount",
          sortable: false,
          align: "left",
        },
        {
          name: "customerName",
          label: "Customer Name",
          field: "customerName",
          sortable: false,
          align: "left",
        },
      ],
      tableLoading: false,
      tableData: [],
      exportFromDate: this.$dateFns.format(
        this.$dateFns.subDays(new Date(), 7),
        "yyyy/MM/dd"
      ),
      exportToDate: this.$dateFns.format(new Date(), "yyyy/MM/dd"),
      refreshInterval: null,
      refreshing: false,
      exporting: false,
      showChart: false,
    }
  },
  computed: {
    isSmallDevice() {
      return this.$q.screen.lt.sm
    },
    isAndroidBuild() {
      return Capacitor.getPlatform() === "android"
    },
    visibleColumns() {
      return this.isSmallDevice
        ? ["createdAt", "amountGross", "paymentType"]
        : ["createdAt", "amountGross", "paymentType", "itemCount"]
    },
    chartStyle() {
      return this.isSmallDevice && !this.showChart ? { display: "none" } : {}
    },
  },
  created() {
    this.getChartData()
    this.getTableData(this.tablePagination)
    this.refreshInterval = setInterval(this.refreshPage, 10000)
  },
  beforeUnmount() {
    clearInterval(this.refreshInterval)
    this.refreshInterval = null
  },
  methods: {
    numberToDate(t) {
      return new Date(t).toString().slice(16, 21)
    },
    formatPrice(penceValue) {
      return `£${(penceValue / 100).toFixed(2)}`
    },
    toDateIsSelectable(dateString) {
      return dateString <= this.$dateFns.format(new Date(), "yyyy/MM/dd")
    },
    fromDateIsSelectable(dateString) {
      return dateString <= this.exportToDate
    },
    async refreshPage() {
      if (this.refreshing || this.exporting) {
        return
      }

      this.refreshing = true
      await this.getChartData()

      if (this.tablePagination.page === 1) {
        await this.getTableData(this.tablePagination)
      }
      this.refreshing = false
    },
    async getChartData() {
      await ReportsAPI.list().then((res) => {
        const times = res.data.revenue
          .map((r) => Date.parse(r.datetime))
          .reverse()
        const revenue = res.data.revenue.map((r) => r.revenue).reverse()
        const expectedRevenue = res.data.expectedRevenue
          .map((r) => r.revenue)
          .reverse()

        this.chartOptions = {
          title: {
            text: `Hourly Revenue over last 24 hours`,
          },
          xaxis: {
            categories: times,
            overwriteCategories: [times[0], times[12], times[23]],
          },
          annotations: {
            xaxis: [
              {
                x: new Date(times[23]).setHours(0),
              },
            ],
          },
        }

        this.chartData = [
          {
            name: "Revenue",
            type: "area",
            data: revenue,
          },
          {
            name: "Expected Revenue",
            type: "line",
            data: expectedRevenue,
          },
        ]
      })
    },
    async getTableData(pagination) {
      this.tableLoading = this.tableData.length === 0 //Only load if not already fetched data

      const requestParams = {
        limit: pagination.rowsPerPage,
        page: pagination.page,
      }

      const {
        data: { count, results },
      } = await ReportsAPI.transactionReport(requestParams)

      this.tablePagination = {
        ...this.tablePagination,
        rowsNumber: count,
        page: pagination.page,
        rowsPerPage: pagination.rowsPerPage,
      }

      this.tableData = results
      this.tableLoading = false
    },
    onTableUpdate({ pagination: newPagination }) {
      this.getTableData(newPagination)
    },
    async onExportClick() {
      try {
        this.exporting = true

        const { data } = await ReportsAPI.transactionReportAsCsv({
          fromDate: this.exportFromDate.replaceAll("/", "-"),
          toDate: this.exportToDate.replaceAll("/", "-"),
        })
        const exportStatus = exportFile("transaction-report.csv", data)

        if (exportStatus !== true) {
          this.$q.notify({
            message: "Browser denied file download...",
            color: "negative",
            icon: "warning",
          })
        }
      } catch {
        this.$q.notify({
          message: "An error occurred fetching csv data",
          color: "negative",
          icon: "warning",
        })
      } finally {
        this.exporting = false
      }
    },
    async onEmailReportClick() {
      try {
        const {
          data: { reportRecipient },
        } = await ReportsAPI.emailTransactionReport({
          fromDate: this.exportFromDate.replaceAll("/", "-"),
          toDate: this.exportToDate.replaceAll("/", "-"),
        })

        this.$toast.success({
          message: `report sent to ${reportRecipient}`,
        })
      } catch {
        this.$toast.error({
          message: `Failed to send`,
        })
      }
    },
    handleRowClick(_event, row, _index) {
      this.$q.dialog({
        component: InvoiceDetailsDialog,
        componentProps: {
          detailsType: "order",
          detailsId: row.orderId,
        },
      })
    },
  },
}
</script>

<style lang="scss" module>
.container {
  width: 100%;
  height: 100%;
  padding: 0.25rem;
}

.exportFieldsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;

  @include mq(phone, max) {
    flex-direction: column;
  }
}

.dateInputsContainer {
  display: flex;
  flex: 1;
}

.table {
  height: 400px;
  margin-top: 10px;

  @include mq(phone, max) {
    height: 80vh;
  }

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: white;
  }

  thead tr th {
    position: sticky;
    z-index: 1;
    font-size: 0.9rem;
    border-bottom-width: 3px !important;
  }

  thead tr:first-child th {
    top: 0;
  }

  .q-table--loading thead tr:last-child th {
    top: 48px;
  }
}

.reportSelectContianer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
