<template>
  <QCard flat :class="$style.cardStyle">
    <QForm :class="$style.form" @submit="onFormSubmit">
      <DialogLayout @cancel="emit('cancel')">
        <template #title>
          {{ item.product.name }}
        </template>
        <div class="q-pa-sm">
          <template v-if="item.product.hasVariablePrice">
            <QInput
              v-model="priceOverride"
              :class="$style.priceInput"
              label="Price"
              mask="#.##"
              fill-mask="0"
              reverse-fill-mask
              input-class="text-right"
              prefix="£"
              unmasked-value
              inputmode="numeric"
              autofocus
            />
          </template>
          <template v-for="option in item.product.options" :key="option.id">
            <div :class="$style.selectContainer">
              <h3 :class="$style.optionLabel">
                {{ option.label }}
              </h3>
              <OptionChoicesInput
                v-model="selectedOptions[option.id]"
                :multiple="option.multiple"
                :options="formatOptionChoices(option)"
              >
              </OptionChoicesInput>
            </div>
          </template>
        </div>
        <template #bottom>
          <div :class="$style.price">
            {{ formattedTotalCost }}
          </div>
          <QCardActions align="center" class="q-pa-sm">
            <QBtn
              color="primary"
              unelevated
              :class="$style.button"
              type="submit"
              no-caps
            >
              <template v-if="editting"> Save Changes </template>
              <template v-else> Add to Cart </template>
            </QBtn>
          </QCardActions>
        </template>
      </DialogLayout>
    </QForm>
  </QCard>
</template>

<script setup>
import cloneDeep from "lodash/cloneDeep"
import DialogLayout from "@/layouts/DialogLayout.vue"

import { useShoppingCartStore, costOfItem } from "@/pinia/shoppingCart"
const shoppingCart = useShoppingCartStore()

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
})
const emit = defineEmits(["ok", "cancel"])

const priceOverride = ref()
onMounted(() => {
  if (props.item.product.hasVariablePrice) {
    priceOverride.value = props.item.priceOverride
      ? props.item.priceOverride
      : props.item.product.price
  }
})

const selectedOptions = ref({})
const editting = computed(() => !!props.item.internalId)

const totalCost = computed(() => costOfItem(newItem.value))

const formattedTotalCost = computed(
  () => `£${(totalCost.value / 100).toFixed(2)}`
)

const newItem = computed(() => {
  return {
    ...props.item,
    options: selectedOptions.value,
    ...(props.item.product.hasVariablePrice && {
      priceOverride: +priceOverride.value,
    }),
  }
})

onMounted(() => {
  if (!props.item.internalId) {
    for (const option of props.item.product.options) {
      selectedOptions.value[option.id] = option.multiple
        ? []
        : option.optionChoices.length > 0
        ? [option.optionChoices[0]]
        : []
    }
  } else {
    selectedOptions.value = cloneDeep(props.item.options)
  }
})
const formatOptionChoices = (option) => {
  return [...option.optionChoices]
}
const updateItem = () => {
  shoppingCart.editItem(props.item.internalId, newItem.value)
  emit("ok")
}
const addItem = () => {
  shoppingCart.addItem(newItem.value)
  emit("ok")
}

const onFormSubmit = () => {
  if (editting.value) {
    updateItem()
  } else {
    addItem()
  }
}
</script>

<style lang="scss" module>
.cardStyle {
  width: 100%;
  max-width: 30rem;
  height: 100%;
  height: clamp(20rem, 100%, 40rem);
  max-height: 40rem;
}

.button {
  width: 100%;
  padding: 0.25rem 0.5rem;
  font-size: $text-md;
  font-weight: 400;
}

.selectContainer {
  padding-right: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.price {
  padding-top: 1rem;
  font-size: 20px;
  font-weight: bold;
  color: #b20b63;
  text-align: center;
}

.optionLabel {
  margin-bottom: 8px;
  font-size: $text-md;
  font-weight: bold;
  color: #b20b63;
}

.priceInput {
  font-size: $text-lg;
  font-weight: 600;
}

.bottom {
  height: 6rem;
  padding: 0;
}

.form {
  height: 100%;
}
</style>
