<template>
  <QDialog ref="dialogRef">
    <QCard>
      <QCardSection class="bg-primary text-white">
        <div class="text-h5 text-center">Quantity</div>
      </QCardSection>

      <QCardSection class="q-pa-sm">
        <div class="text-subtitle1 text-center"><span :class="$style.productName">{{productName}}</span>{{formatPrice(price)}}</div>
        <QDivider/>
        <QuickTillMultiplierInput v-model="multiplierValue" />
      </QCardSection>

      <QCardActions :class="$style.actions">
        <QBtn no-caps @click="onDialogCancel"> Cancel </QBtn>
        <QBtn no-caps @click="() => onDialogOK(multiplierValue)">OK</QBtn>
      </QCardActions>
    </QCard>
  </QDialog>
</template>

<script>
import { useDialogPluginComponent, useQuasar } from "quasar"
</script>

<script setup>
import {formatPrice} from '@/utils/currency'

const emit = defineEmits(["ok", "hide"])
const props = defineProps({
  productName: {
    type: String,
    required: true,
  },
  price: {
    type: Number,
    required: true,
  }
})

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent()

const multiplierValue = ref(1)
console.log(props.product)
</script>

<style lang="scss" module>
.actions {
  justify-content: space-around;
}

.productName{ 
  margin-right: 15px;
}
</style>
