<template>
  <TillInputField :label="props.label">
    <QField :model-value="props.modelValue" bottom-slots dense>
      <QBtn :class="$style.button">
        <QPopupProxy transition-show="scale" transition-hide="scale">
          <QColor
            :model-value="props.modelvalue"
            default-view="palette"
            format-model="hex"
            @update:model-value="emit('update:modelValue', $event)"
          />
        </QPopupProxy>
        {{ props.modelValue }}
      </QBtn>
    </QField>
  </TillInputField>
</template>

<script>
export default {
  inheritAttrs: false,
}
</script>

<script setup>
const props = defineProps({
  modelValue: {
    type: String,
    default: undefined,
  },
  label: {
    type: String,
    default: "",
  },
})

const emit = defineEmits(["update:modelValue"])
</script>

<style lang="scss" module>
.button {
  width: 100%;
  color: white;
  background-color: v-bind("props.modelValue");
}
</style>
