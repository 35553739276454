import Auth from "@/api/auth"
import router from "@/router"
import WhoAmI from "@/api/whoAmI"
import { Storage } from "@capacitor/storage"
import { Device as CapacitorDevice } from "@capacitor/device"

export default {
  namespaced: true,
  state: {
    loggedIn: false,
    groups: [],
    admin: false,
    anonymous: false,
    superAdmin: false,
    deviceSerialNumber: undefined,
  },
  mutations: {
    LOG_IN(state, isAdmin = false, isSuperAdmin = false) {
      state.loggedIn = true
      state.admin = isAdmin
      state.superAdmin = isSuperAdmin
    },
    LOG_OUT(state) {
      state.loggedIn = false
      state.admin = false
      state.superAdmin = false
      state.deviceSerialNumber = undefined
    },
    LOG_IN_ANONYMOUS(state) {
      state.anonymous = true
    },
    Log_IN_AS_TERMINAL_DEVICE(state, device) {
      state.loggedIn = true
      state.deviceSerialNumber = device.terminalId
    },
  },
  actions: {
    async logOut({ commit, dispatch }, toastMessage) {
      try {
        await Auth.logOut()
      } catch {}
      dispatch("employee/flush", undefined, { root: true })
      dispatch("client/flush", undefined, { root: true })
      commit("LOG_OUT")
      try {
        await router.push({
          name: "log-in",
          params: {
            toastMessage,
          },
        })
      } catch {}
    },
    async logIn({ state, dispatch }, payload) {
      state.anonymous = false
      try {
        await Auth.logIn(payload)
        // dispatch("hydrateIfEmpty")
        return Promise.resolve()
      } catch (error) {
        dispatch("logOut")
        return Promise.reject(error)
      }
    },

    async logInTerminalDevice({ state, dispatch }, pin) {
      state.anonymous = false
      const { uuid: deviceUUID } = await CapacitorDevice.getId()
      try {
        const { data } = await Auth.logInTerminalDevice({
          pin,
          deviceUuid: deviceUUID,
        })
        data.hospitalityMode &&
          dispatch("terminal/setHospitalityMode", data.hospitalityMode, {
            root: true,
          })
        return Promise.resolve()
      } catch (error) {
        dispatch("logOut")
        return Promise.reject(error)
      }
    },
    async hydrateIfEmpty({ commit, state, dispatch }) {
      if (state.loggedIn || state.anonymous) return Promise.resolve()
      try {
        const {
          data: { user, client, device },
        } = await WhoAmI()
        state.groups = user.groups || []
        user.id && dispatch("employee/set", user, { root: true })
        client.id && dispatch("client/set", client, { root: true })
        !(
          typeof device.hospitalityMode === "undefined" ||
          device.hospitalityMode === undefined
        ) &&
          dispatch("terminal/setHospitalityMode", device.hospitalityMode, {
            root: true,
          })
        const isAdmin = user.groups && user.groups.includes("admin")
        const isSuperAdmin = user.groups && user.groups.includes("superadmin")
        user.id
          ? commit("LOG_IN", isAdmin, isSuperAdmin)
          : device.terminalId
          ? commit("Log_IN_AS_TERMINAL_DEVICE", device)
          : commit("LOG_IN_ANONYMOUS")
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    },

    resetAndRefill({ state, dispatch }) {
      state.loggedIn = false
      state.anonymous = false
      return dispatch("hydrateIfEmpty")
    },
  },
}
