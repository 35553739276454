<template>
  <QDialog ref="dialogRef" :maximized="!isDesktop">
    <ProductOptionsForm
      :item="item"
      @ok="onDialogOK"
      @cancel="onDialogCancel"
    />
  </QDialog>
</template>

<script>
import { useDialogPluginComponent, useQuasar } from "quasar"
</script>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
})
const emit = defineEmits(["ok", "hide"])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent()

const $q = useQuasar()
const isDesktop = $q.screen.gt.md
</script>

<style lang="scss" module></style>
