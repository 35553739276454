<template>
  <QDialog ref="dialogRef" :maximized="!isDesktop">
    <CashierForm v-bind="$attrs" @cancel="onDialogCancel" @ok="onDialogOK">
    </CashierForm>
  </QDialog>
</template>

<script>
import { useDialogPluginComponent, useQuasar } from "quasar"
</script>

<script setup>
const emit = defineEmits(["ok", "hide"])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent()

const $q = useQuasar()
const isDesktop = $q.screen.gt.md
</script>
