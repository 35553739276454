<template>
  <QDialog ref="dialogRef" :maximized="!isDesktop" @hide="onDialogHide">
    <QCard flat :class="$style.cardStyle">
      <QForm greedy :class="$style.form" @submit="createProduct">
        <DialogLayout @cancel="onDialogCancel">
          <template #title> </template>
          <div :class="$style.innerContainer">
            <template v-if="isLoading">
              <BaseisLoadingSpinner />
            </template>
            <template v-else>
              <TillInputText
                v-model="form.name"
                label="Name*"
                :rules="rules.name"
              />

              <BaseInputColor
                v-model="form.selfColor"
                :rules="rules.color"
                label="Background Colour"
              />

              <template v-if="showVatInput">
                <TillInputVat v-model="form.vatRate" />
              </template>
            </template>
          </div>

          <template #bottom>
            <QCardActions align="center" class="q-pa-sm" vertical>
              <QBtn
                color="primary"
                unelevated
                :class="$style.button"
                type="submit"
              >
                Save
              </QBtn>

              <template v-if="isEdit">
                <QBtn
                  color="primary"
                  unelevated
                  label="Delete"
                  :class="$style.button"
                  @click="checkDeleteProduct"
                />
              </template>
            </QCardActions>
          </template>
        </DialogLayout>
      </QForm>
    </QCard>
  </QDialog>
</template>

<script>
import { useDialogPluginComponent, useQuasar } from "quasar"
</script>

<script setup>
import Product from "@/api/orders/products"
import DialogLayout from "@/layouts/DialogLayout.vue"
import CategoryAPI from "@/api/categories"
import { useCategoryStore } from "@/pinia/categories"
import { useStore } from "vuex"

import toast from "@/toast"

const store = useStore()
const style = useCssModule()
const $q = useQuasar()

const props = defineProps({
  initialData: { type: Object, default: () => ({}) },
})

const emit = defineEmits(["ok", "hide"])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent()

const isDesktop = $q.screen.gt.md

const isLoading = ref(false)

const showVatInput = computed(() => store.state.client.vatRegistered)

const form = ref({
  name: undefined,
  hasVariablePrice: true,
  selfColor: undefined,
  vatRate: null,
})
const id = computed(() => props.initialData.id)
const isEdit = computed(() => !!id.value)

const createOrEdit = computed(() => {
  return isEdit.value ? "Edit Product" : "Create Product"
})

const rules = computed(() => {
  const colourRegex = /#[a-z]*/
  return {
    name: [
      (v) => !!v || "Name is required",
      (v) => v.length < 64 || "Name is too long (maximum 64 characters)",
    ],
    colour: [(v) => colourRegex.test(v) || "Incorrect Hex Colour Format"],
  }
})

onMounted(async () => {
  isLoading.value = true
  loadInitialData()
  isLoading.value = false
})
const loadInitialData = async () => {
  form.value = Object.fromEntries(
    Object.entries(form.value).map(([key, value]) => [
      key,
      props.initialData[key] || value,
    ])
  )
}

const createProduct = async () => {
  isLoading.value = true
  try {
    const params = form.value

    const { data: newProduct } = await (isEdit.value
      ? Product.partialUpdate(id.value, params)
      : Product.create(params))

    onDialogOK(newProduct)
  } catch {
    toast.error({
      message: "Something went wrong creating product",
    })
  } finally {
    isLoading.value = false
  }
}
const checkDeleteProduct = () => {
  $q.dialog({
    title: "Are you sure?",
    message: "Deleting a product cannot be undone.",
    cancel: {
      outline: true,
      flat: true,
      "text-color": "#e5332a",
    },
    ok: {
      outline: true,
      flat: true,
      "text-color": "#e5332a",
    },
    persistent: true,
    class: style.dialog,
  })
    .onOk(() => {
      deleteProduct()
    })
    .onCancel(() => {})
    .onDismiss(() => {})
}
const deleteProduct = async () => {
  isLoading.value = true
  try {
    await Product.delete(id.value)
    onDialogOK()
  } catch {
    toast.error({
      message: "Something went wrong deleting product",
    })
  } finally {
    isLoading.value = false
  }
}
</script>

<style lang="scss" module>
.cardStyle {
  width: 100%;
  max-width: 30rem;
  height: 100%;
  height: clamp(20rem, 100%, 40rem);
}

.innerContainer {
  padding: 0.5rem;
}

.button {
  width: 100%;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.5rem;
  font-size: $text-md;
  font-weight: 400;
}

.form {
  width: 100%;
  height: 100%;
}

.priceInputRow {
  display: flex;
  flex-wrap: nowrap;
  align-items: bottom;
  justify-content: space-between;
}

.variablePriceInputLabel {
  width: 3rem;
  font-size: $text-xs;
  text-align: center;
}
</style>
