<template>
  <div @contextmenu="handleContextMenu">
    <OfflineListener>
      <template v-slot="{ presenceKey }">
        <RouterView :key="`${presenceKey}:${merchantIdentifier}`" />
      </template>
    </OfflineListener>
  </div>
</template>

<script setup>
import TIDInputDialog from "@/components/TIDInputDialog.vue"

import { useStore } from "vuex"

import { useQuasar } from "quasar"
import { Capacitor } from "@capacitor/core"
const route = useRoute()

const merchantIdentifier = ref()

const $q = useQuasar()

const store = useStore()

onMounted(() => {
  if (import.meta.env.VUE_APP_ANDROID === "true") {
    $q.dialog({
      component: TIDInputDialog,
    }).onOk(async (newMerchantIdentifier) => {
      merchantIdentifier.value = newMerchantIdentifier
      try {
        await store.dispatch("auth/resetAndRefill")
      } catch {
        // Server has 500ed if this happens, so maybe redirect to down for maintenance page?
      }
    })
  }
})

const handleContextMenu = (event_) => {
  if (Capacitor.getPlatform() === "android") {
    event_.preventDefault()
    event_.stopPropagation()
  }
}
</script>

<style lang="scss">
// @import "./styles/quasar.scss";
@import "@/design/chevinPro.scss";
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern, no-descending-specificity
@import url("https://fonts.googleapis.com/css?family=Oxanium&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

html body {
  --vee-color-primary: #b20b63;
  --vee-color-dark-primary: #709fbf;
  --vee-color-secondary: #ee7c0a;
  --vee-color-dark-secondary: #90d7ef;
  --vee-color-accent: #e5332a;
  --vee-color-dark-accent: #e5332a;
  --vee-color-positive: #21ba45;
  --vee-color-dark-positive: #54ec82;
  --vee-color-negative: #b00020;
  --vee-color-dark-negative: #cf6679;
  --vee-color-info: #31ccec;
  --vee-color-dark-info: #31ccec;
  --vee-color-warning: #f2c037;
  --vee-color-dark-warning: #f2c037;
  --q-primary: #{$primary};
  --q-secondary: #{$secondary};
  --q-accent: #{$accent};
  --q-positive: #{$positive};
  --q-negative: #{$negative};
  --q-warning: #{$warning};
  --q-info: #{$info};
  --color-ready: rgb(33 186 69 / var(--local-bg-opacity, 1));
  --color-requested: rgb(255 193 7 / var(--local-bg-opacity, 1));
  --color-fulfilled: rgb(33 150 243 / var(--local-bg-opacity, 1));

  font-family: "chevin-pro", sans-serif;
}

#app {
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-xs {
  font-size: $text-xs;
}

.text-sm {
  font-size: $text-sm;
}

.text-md {
  font-size: $text-md;
}

.text-lg {
  font-size: $text-lg;
}

.text-xl {
  font-size: $text-xl;
}

.text-xxl {
  font-size: $text-xxl;
}

.text-xxxl {
  font-size: $text-xxxl;
}

html {
  box-sizing: border-box;
}

body {
  @include mq(tablet) {
    // tablet up
    background-color: white;
  }

  @include dark-mode {
    // background-color: rgba(0, 0, 0, 0.65);
    background-color: $dark-elevation-0;
  }
}

body.body--dark {
  --q-primary: #{$dark-mode-primary};
  --q-secondary: #{$dark-mode-secondary};
  --q-accent: #{$dark-mode-accent};
  --q-positive: #{$dark-mode-positive};
  --q-negative: #{$dark-mode-negative};
  --q-warning: #{$dark-mode-warning};
  --q-info: #{$dark-mode-info};

  .bg-secondary,
  .bg-warning,
  .bg-primary,
  .bg-positive,
  .bg-negative,
  .bg-info {
    color: black !important;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  padding: 0;
  margin: 0;
  font-weight: normal;
  line-height: 1.5em;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

.vuecal__cell::before {
  border: none !important;
}

.vuecal__time-cell {
  padding-right: 10px !important;
  font-size: $text-md;
}

.vuecal__weekdays-headings {
  margin-bottom: 10px !important;
  border-bottom: none !important;
}

.vuecal__time-column .vuecal__time-cell .line::before {
  border-top: none !important;
}

.q-table__top {
  padding-top: 0;
}

.plate {
  padding: 3px 10px;
  font-size: 1.3rem;
  font-weight: 900;
  text-align: center;
  background-color: #fec20a;
  border-radius: 2px;
}

.q-field--standard .q-field__control::after {
  background: linear-gradient(
    229.99deg,
    #ee7d00 0%,
    #e85124 43.48%,
    #c74b87 100%
  );
}
</style>
