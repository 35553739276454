<template>
  <div :class="$style.container">
    <div :class="$style.topIcons">
      <QIcon
        :class="$style.topIcon"
        size="4rem"
        name="img:/img/help_icon.svg"
      />
      <QIcon
        :class="$style.topIcon"
        size="4rem"
        name="img:/img/help_phone.svg"
      />
    </div>

    <h2 :class="$style.header"> Chat to us, we're here to help you. </h2>

    <Transition name="fade" mode="out-in">
      <template v-if="ticket">
        <SupportForm
          :errors="errors"
          :submitting="submitting"
          @submit="onSubmit"
          @cancel="onCancel"
        >
          <template v-slot:submit-label> Send Ticket </template>
        </SupportForm>
      </template>
      <template v-else>
        <div>
          <QList :class="$style.listContainer">
            <QItem :class="$style.menuItem" clickable @click="showSupport">
              <QItemSection avatar>
                <QIcon name="chat" />
              </QItemSection>
              <QItemSection> Open a Support Ticket </QItemSection>
            </QItem>
            <QSeparator />
            <QItem :class="$style.menuItem" clickable @click="callSupport">
              <QItemSection avatar>
                <QIcon name="phone" />
              </QItemSection>
              <QItemSection>
                <QItemLabel> Phone Support </QItemLabel>
                <QItemLabel caption :class="$style.phoneNumber">
                  {{ supportPhone }}
                </QItemLabel>
              </QItemSection>
            </QItem>
          </QList>

          <div :class="$style.deviceInfo">
            <template v-if="merchantId || serialNumber">
              <p :class="$style.deviceInfoLine">
                Merchant: {{ merchantId }}
              </p>
              <p :class="$style.deviceInfoLine">
                Serial Number: {{ serialNumber }}
              </p>
            </template>
            <p v-else :class="$style.deviceInfoLine"> Merchant: {{ tenantName }} </p>
            
          </div>
          <div :class="$style.requestDesktopAccessContainer">
            <QBtn
              color="primary"
              no-caps
              class="q-mx-auto block"
              :loading="usersLoading"
              @click="showRequestPortalAccessDialog"
            >
              Desktop Access
            </QBtn>
          </div>
        </div>
      </template>
    </Transition>
  </div>
</template>

<script>
export default {
  title: "Help",
}
</script>

<script setup>
import Support from "@/api/support"
import toast from "@/toast"
import { Storage } from "@capacitor/storage"
import Users from "@/api/users"
import Auth from "@/api/auth"
import WhoAmI from "@/api/whoAmI"

import { useQuasar } from "quasar"

import { useUserSelectModal } from "@/components/modals/UserSelectModal/useUserSelectModal"
import { useStore } from "vuex"

const $store = useStore()
const $q = useQuasar()

const emit = defineEmits(["ok"])
const supportPhone = ref("01606566600")
const ticket = ref(false)
const submitting = ref(false)
const errors = ref()
const serialNumber = ref()
const merchantId = ref()
const tenantName = computed(() => $store.state.client.name)
onMounted(async () => {
  if (import.meta.env.VUE_APP_ANDROID !== "true") {
    return
  }

  const { value: serialNumberFromStorage } = await Storage.get({
    key: "serialNumber",
  })
  serialNumber.value = serialNumberFromStorage

  merchantId.value = sessionStorage.getItem("merchantIdentifier")
})

const showSupport = () => {
  ticket.value = true
}
const callSupport = () => {
  window.open(`tel:${supportPhone.value}`, "_self")
}
const onSubmit = async (validatedForm) => {
  submitting.value = true
  try {
    const response = await Support.create(validatedForm)
    emit("ok", response.data)
    toast.success({
      message: "Support ticket has been created",
    })
    ticket.value = false
    // hide()
  } catch (error) {
    errors.value = error.response.data
    toast.error({
      message: "Something went wrong. Support ticket has not been created.",
    })
  }
  submitting.value = false
}
const onCancel = () => {
  ticket.value = false
}

const requestLoginLink = async (emailAddress) => {
  try {
    await Auth.requestLoginLink({ email: emailAddress })
    toast.success({
      message: `Log in link sent to ${emailAddress}`,
    })
  } catch {}
}

const users = ref([])
const usersLoading = ref(false)
const listUsers = async () => {
  usersLoading.value = true
  try {
    const {
      data: { results },
    } = await Users.list()
    users.value = results
  } finally {
    usersLoading.value = false
  }
}
onMounted(() => listUsers())

const userSelectModal = useUserSelectModal()
userSelectModal.onOk((user) => requestLoginLink(user.email))
const showRequestPortalAccessDialog = () => {
  userSelectModal.show({
    userOptions: users.value,
  })
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0.25rem;
}

.topIcon {
  margin: 1rem;
}

.header {
  margin-top: 1.6rem;
  font-size: 1.6rem;
  line-height: 1.8rem;
  text-align: center;

  @media only screen and (max-width: $max-page-width) {
    width: 14rem;
  }
}

.listContainer {
  margin-top: 1rem;
}

.menuItem {
  font-size: $text-md;
  font-weight: 400;
  opacity: 0.87;
}

.phoneNumber {
  font-size: $text-md;
}

.deviceInfo {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: center;
}

.deviceInfoLine {
  margin-bottom: 0;
  font-size: $text-sm;
  color: rgb(0 0 0 / 60%);
}
</style>
