<template>
  <div :class="$style.container" class="fit">
    <div :class="$style.filterContainer">
      <slot name="filters" />
    </div>

    <div :class="$style.filterChipContainer">
      <slot name="filter-chips" />
    </div>
    <template v-if="ordersLoading">
      <BaseLoadingSpinner />
    </template>

    <template v-else>
      <div :class="$style.scroll">
        <QList separator>
          <template v-for="order in orders" :key="order.id">
            <slot name="order" v-bind="{ order, refreshData }"> </slot>
          </template>
        </QList>
      </div>
    </template>
    <QItem>
      <QPagination
        v-model="paginationPage"
        :max="Math.ceil(pagination.totalRows / pagination.rowsPerPage)"
        :input="true"
        class="q-mx-auto"
      />
    </QItem>
    <audio id="alertAudio" src="/sounds/alert.wav" preload="auto" />
  </div>
</template>

<script setup>
import Orders from "@/api/orders/orders"
import debounce from "lodash/debounce"
import * as DateFns from "date-fns"
import axios from "axios"
// import OrderListFilterDialog from "@/components/OrderListFilterDialog.vue"

const props = defineProps({
  filters: {
    type: Object,
    default: () => ({}),
  },
  isLive: {
    type: Boolean,
    default: false,
  },
})

const pagination = ref({
  page: 1,
  rowsPerPage: 10,
})

const orders = ref([])

const ordersLoading = ref(false)
const ordersHaveLoaded = ref(false)

const CancelToken = axios.CancelToken
let source

const listOrders = async (params) => {
  if (!ordersHaveLoaded.value) {
    ordersLoading.value = true
  }

  if (source) {
    source.cancel()
    source = undefined
  }
  source = CancelToken.source()

  try {
    const {
      data: { results, count },
    } = await Orders.list(
      {
        all: true,
        ordering: "-created_at",
        // live: props.isLive || undefined,
        limit: pagination.value.rowsPerPage,
        ...params,
      },
      {
        cancelToken: source.token,
      }
    )
    orders.value = results
    pagination.value.page = params.page
    pagination.value.totalRows = count
    ordersHaveLoaded.value = true
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("cancelled")
      console.log(params)
    }
    if (axios.isCancel(error)) return
  } finally {
    ordersLoading.value = false
  }
}

const paginationPage = computed({
  get: () => pagination.value.page,
  set: (value) => {
    listOrders({
      ...props.filters,
      page: value,
    })
  },
})

const debouncedListOrders = debounce((filters) => listOrders(filters), 300)

watch(
  () => props.filters,
  (value) =>
    debouncedListOrders({
      ...value,
      page: 1,
    })
)

const refreshData = () =>
  listOrders({
    ...props.filters,
    page: pagination.value.page,
  })

// Polling

const requestInterval = ref()

const play = () => {
  refreshData()
  requestInterval.value = setInterval(refreshData, 5000)
}

const pause = () => {
  clearInterval(requestInterval.value)
  requestInterval.value = undefined
  refreshData()
}

// this also handles initial load
watch(
  () => props.isLive,
  (value) => {
    if (value) {
      play()
    } else {
      pause()
    }
  },
  {
    immediate: true,
  }
)

onBeforeUnmount(() => pause())

// Alert sound

const latestTimestamp = computed(() =>
  Array.isArray(orders.value) && orders.value.length > 0
    ? DateFns.parseISO(orders.value.at(0).createdAt)
    : undefined
)
const playAlertSound = () => {
  const element = document.querySelector("#alertAudio")
  element.currentTime = 0
  element.play()
}

watch(
  () => latestTimestamp.value,
  (newValue, oldValue) => {
    if (!newValue || !oldValue) return
    if (props.isLive && newValue.getTime() > oldValue.getTime()) {
      playAlertSound()
    }
  }
)
</script>

<style lang="scss" module>
.container {
  width: 100%;
  height: 100%;
  padding: 0.25rem;
}

.filterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0.5rem;
}

.filterChipContainer {
  margin-left: 10px;
}

.scroll {
  height: calc(100% - 120px);
  overflow: auto;
}
</style>
