import Endpoint from "@/api/endpoint"
import { BaseApi } from "@/api"
import humps from "humps"
const relativeUrl = "/orders/orders"
const Orders = new Endpoint({
  relativeUrl,
})
Orders.listKitchen = (params) =>
  BaseApi.get(`${relativeUrl}/kitchen/`, {
    headers: { "content-type": "application/x-www-form-urlencoded" },
    params: humps.decamelizeKeys(params),
  })
Orders.unready = (id) => BaseApi.post(`${relativeUrl}/${id}/unready/`)
Orders.ready = (id) => BaseApi.post(`${relativeUrl}/${id}/ready/`)
Orders.fulfill = (id) => BaseApi.post(`${relativeUrl}/${id}/fulfill/`)
Orders.pay = (id, params = {}) =>
  BaseApi.post(`${relativeUrl}/${id}/pay/`, params)
Orders.cancel = (id) => BaseApi.post(`${relativeUrl}/${id}/cancel/`)
Orders.prepareRefund = (id, params) =>
  BaseApi.get(`${relativeUrl}/${id}/prepare_refund/`, {
    headers: { "content-type": "application/x-www-form-urlencoded" },
    params: humps.decamelizeKeys(params),
  })
Orders.handleCompletedPartialRefund = (id, params) =>
  BaseApi.post(`${relativeUrl}/${id}/handle_completed_partial_refund/`, params)
Orders.sendReceipt = (id, params = {}) =>
  BaseApi.post(`${relativeUrl}/${id}/send_receipt/`, params)

export default Orders
