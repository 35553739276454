import { defineStore } from "pinia"
import { BaseApi } from "@/api"
export const useTillConfig = defineStore("tillConfig", () => {
  const featureQuickTill = ref(true)
  const featureFullTill = ref(true)
  const defaultView = ref()
  const hasLoaded = ref(false)
  const retrieveTillConfig = async () => {
    try {
      const { data } = await BaseApi.get("till-config/")

      featureQuickTill.value = data.featureQuickTill
      featureFullTill.value = data.featureFullTill
      defaultView.value = data.defaultTillView
      hasLoaded.value = true
    } catch {}
  }
  const setDefaultView = async (value) => {
    try {
      const { data } = await BaseApi.patch("till-config/update/", {
        defaultTillView: value,
      })
      featureQuickTill.value = data.featureQuickTill
      featureFullTill.value = data.featureFullTill
      defaultView.value = data.defaultTillView
    } catch {}
  }

  if (!hasLoaded.value) {
    retrieveTillConfig()
  }

  const defaultViewRoute = computed(() => {
    return (
      {
        full_till: "/till",
        quick_till: "/quick-till",
      }[unref(defaultView)] || "/till"
    )
  })

  return {
    featureFullTill: readonly(featureFullTill),
    featureQuickTill: readonly(featureQuickTill),
    defaultView: readonly(defaultView),
    setDefaultView,
    defaultViewRoute,
  }
})
