export default {
  namespaced: true,
  state: {
    id: undefined,
    name: undefined,
    domains: [],
    features: [],
    isDemo: false,
    product: undefined,
    logo: undefined,
    featureEatIn: false,
    featureTakeaway: false,
    featureDelivery: false,
    featureInStore: false,
    featureHomeDelivery: false,
    featureClickAndCollect: false,
    deliveryCost: undefined,
    deliveryCostCutoff: undefined,
    waitTime: undefined,
    vatRegistered: undefined,
  },
  mutations: {
    SET_FIELDS(state, client) {
      state.id = client.id
      state.name = client.name
      state.domains = [...client.domains]
      state.featureEatIn = client.featureEatIn
      state.featureTakeaway = client.featureTakeaway
      state.featureDelivery = client.featureDelivery
      state.featureRestaurantMode =
        client.featureEatIn || client.featureTakeaway
      state.featureInStore = client.featureInStore
      state.featureHomeDelivery = client.featureHomeDelivery
      state.featureClickAndCollect = client.featureClickAndCollect
      state.featureStoreMode =
        client.featureInStore ||
        client.featureHomeDelivery ||
        client.featureClickAndCollect
      state.deliveryCost = client.deliveryCost
      state.deliveryCostCutoff = client.deliveryCostCutoff
      state.waitTime = client.waitTime
      state.vatRegistered = client.vatRegistered
    },
    RESET_FIELDS(state) {
      state.id = undefined
      state.name = undefined
      state.domains = []
      state.featureEatIn = false
      state.featureTakeaway = false
      state.featureDelivery = false
      state.featureInStore = false
      state.featureHomeDelivery = false
      state.featureClickAndCollect = false
      state.deliveryCost = undefined
      state.deliveryCostCutoff = undefined
      state.waitTime = undefined
      state.vatRegistered = undefined
    },
    SET_WAIT_TIME(state, waitTime) {
      state.waitTime = waitTime
    },
  },
  actions: {
    set({ commit }, client) {
      commit("SET_FIELDS", client)
    },
    flush({ commit }) {
      commit("RESET_FIELDS")
    },
  },
}
