import { Notify } from "quasar"

export const error = (params) =>
  Notify.create({
    position: "top",
    timeout: 2500,
    textColor: "white",
    actions: [{ icon: "close", color: "white" }],
    color: "red-4",
    ...params,
  })

export const success = (params) =>
  Notify.create({
    position: "top",
    timeout: 2500,
    textColor: "white",
    actions: [{ icon: "close", color: "white" }],
    color: "green-4",
    ...params,
  })

export const warning = (params) =>
  Notify.create({
    position: "top",
    timeout: 2500,
    textColor: "white",
    actions: [{ icon: "close", color: "white" }],
    color: "amber-7",
    ...params,
  })

export const info = (params) =>
  Notify.create({
    position: "top",
    timeout: 2500,
    textColor: "white",
    actions: [{ icon: "close", color: "white" }],
    color: "info",
    ...params,
  })

export default {
  success,
  error,
  warning,
  info,
  install: (app) => {
    app.config.globalProperties.$toast = {
      success,
      error,
      warning,
      info,
    }
  },
}
