import CategoryAPI from "@/api/categories"
import ProductAPI from "@/api/orders/products"
import LRU from "lru-cache"

interface CacheOptions {
  dedupingInterval?: number
  fetcher: (key: string) => Promise<any>
  baseKey: string
}

interface LoadOptions {
  force?: boolean
}

const CACHE = new LRU({ max: 256 })

const defaultOptions: CacheOptions = {
  dedupingInterval: 60 * 1000,
  fetcher: async () => {},
  baseKey: "",
}

export const STATE = {
  error: Symbol("error"),
  idle: Symbol("idle"),
  loading: Symbol("loading"),
  revalidating: Symbol("revalidating"),
}

export const clearCache = () => CACHE.clear()

export const useSWRVCache = (newKey: string, customOptions: CacheOptions) => {
  const options: CacheOptions = {
    ...defaultOptions,
    ...customOptions,
  }

  const cacheKey = `${options.baseKey}:${newKey}`
  const cacheKeyDedup = `${cacheKey}_dedup`
  const cachedResponse = CACHE.get(cacheKey)

  const response =
    cachedResponse ||
    reactive({
      data: undefined,
      error: undefined,
      revalidate: undefined,
      state: undefined,
    })

  if (!cachedResponse) CACHE.set(cacheKey, response)

  const load = async (
    loadOptions = {
      force: false,
    }
  ) => {
    try {
      if (!loadOptions.force && CACHE.get(cacheKeyDedup)) return

      CACHE.set(cacheKeyDedup, true, { ttl: options.dedupingInterval })

      response.state = response.data ? STATE.revalidating : STATE.loading
      response.data = Object.freeze(await customOptions.fetcher(newKey))
    } catch (error) {
      CACHE.del(cacheKeyDedup)
      response.error = error
      response.state = STATE.error
    }
  }

  load()

  response.load = () => load()

  response.revalidate = () => load({ force: true })

  return readonly(response)
}
