// const importComponent = (componentPathFromViews) => () =>
//   import(
//     /* webpackChunkName: "account" */ `@/views/account/${componentPathFromViews}`
//   )

import PasswordEdit from "@/views/account/PasswordEdit.vue"
import PasswordReset from "@/views/account/PasswordReset.vue"
import AcceptInvite from "@/views/account/AcceptInvite.vue"
import ProfileEdit from "@/views/account/ProfileEdit.vue"

export default [
  {
    path: "edit-password",
    name: "editPassword",
    component: PasswordEdit,
    meta: {
      auth: true,
    },
  },
  {
    path: "reset-password",
    name: "resetPassword",
    component: PasswordReset,
    props: (route) => ({
      userId: route.query.user_id,
      token: route.query.token,
    }),
  },
  {
    path: "accept-invite",
    name: "accept-invite",
    component: AcceptInvite,
    props: (route) => ({ token: route.query.token }),
    meta: {},
  },
  {
    path: "edit-profile",
    name: "edit-profile",
    component: ProfileEdit,
    props: true,
    meta: {
      auth: true,
    },
  },
]
