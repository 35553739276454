import { BaseApi } from "@/api"
const Terminal = {
  setPin: (oldPin, newPin) =>
    BaseApi.post("/terminal/pin/set/", { oldPin, newPin }),
  setHospitalityMode: (hospitalityMode) =>
    BaseApi.post("/terminal/hospitality-mode/", { hospitalityMode }),
  pair: (serialNumber, deviceUuid, paxMerchantId, confirmed=true) =>
    BaseApi.post(`/pair/${serialNumber}/`, {
      deviceUuid, paxMerchantId, confirmed
    }),
}

export default Terminal
