import { formatPrice } from "@/utils/currency"
import { useShoppingCartStore } from "@/pinia/shoppingCart.js"

export function useQuickTill() {
  const shoppingCart = useShoppingCartStore()

  const addedItems = ref<Array<number>>([])
  const currentItem = ref(0)

  const lineItems = computed(() => [
    ...addedItems.value,
    ...(currentItem.value !== 0 ? [currentItem.value] : []),
  ])

  const formattedTotalCost = computed(() =>
    formatPrice(lineItems.value.reduce((sum, x) => sum + +x, 0))
  )

  const addItem = (repeats: number) => {
    addedItems.value.push(...new Array(repeats).fill(currentItem.value))
    currentItem.value = 0
  }

  const removeLastItem = () => {
    addedItems.value.pop()
  }

  const clearItems = () => {
    addedItems.value = []
    currentItem.value = 0
  }

  const addItemsToCart = (item: Object) => {
    for (let lineItem of lineItems.value) {
      shoppingCart.addItem({
        ...item,
        priceOverride: +lineItem,
        options: {},
      })
    }
  }

  return {
    currentItem: currentItem,
    addedItems: addedItems,
    lineItems: lineItems,
    formattedTotalCost,
    addItem,
    removeLastItem,
    addItemsToCart,
    clearItems,
  }
}
