<template>
  <QDialog
    ref="dialogRef"
    :no-backdrop-dismiss="modalLoading"
    :no-esc-dismiss="modalLoading"
    @hide="onDialogHide"
  >
    <CreateOrderForm @ok="emit('ok')" />
  </QDialog>
</template>

<script setup>
import { useDialogPluginComponent } from "quasar"

const emit = defineEmits(["ok", "hide"])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent()
</script>

<style lang="scss" module></style>
