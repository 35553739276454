import VuexPersist from "vuex-persist"
import auth from "./auth"
import employee from "./employee"
import client from "./client"
import products from "./products"
import tables from "./tables"
import attributes from "./attributes"
import menuTimes from "./menuTimes"
import terminal from "./terminal"

import { createStore } from "vuex"

// const vuexPersist = new VuexPersist({
//   key: "vee-store",
//   storage: sessionStorage,
//   modules: ["auth", "client", "employee", "permissions"],
// })

const localStoragePersist = new VuexPersist({
  key: "vee-store",
  storage: localStorage,
  modules: [],
})

export default createStore({
  plugins: [localStoragePersist.plugin],
  state: () => ({}),
  mutations: {},
  actions: {},
  modules: {
    auth,
    employee,
    client,
    products,
    tables,
    attributes,
    menuTimes,
    terminal,
  },
})
