import Endpoint from "@/api/endpoint"
import { BaseApi } from "@/api"
import humps from "humps"

const relativeUrl = "/report"
const Reports = new Endpoint({
  relativeUrl,
})

Reports.topProducts = (params) =>
  BaseApi.post(`${relativeUrl}/top_products/`, params)

Reports.transactionReport = (params) =>
  BaseApi.get(`${relativeUrl}/transactionReport/`, {
    headers: { "content-type": "application/x-www-form-urlencoded" },
    params: humps.decamelizeKeys(params),
  })

Reports.getTransactionCsvData = (params) =>
  BaseApi.post(`${relativeUrl}/transactionReport/`, params)

Reports.transactionReportAsCsv = (params) =>
  BaseApi.post(`${relativeUrl}/transactionReport/as_csv/`, params)

Reports.emailTransactionReport = (params) =>
  BaseApi.post(`${relativeUrl}/transactionReport/email_report/`, params)

Reports.todayReport = () => BaseApi.get(`${relativeUrl}/today/`)

export default Reports
