<!-- prop modelValue is a date string of the form "yyyy/MM/dd" -->

<template>
  <QInput
    :model-value="modelValue"
    :rules="dateRules"
    :class="$style.input"
    :label="label"
    mask="date"
    dense
    hide-bottom-space
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template v-slot:append>
      <QIcon name="event" class="cursor-pointer">
        <QPopupProxy
          ref="qDateProxy"
          transition-show="scale"
          transition-hide="scale"
        >
          <QDate
            :model-value="modelValue"
            :options="dateIsSelectable"
            no-unset
            @update:model-value="$emit('update:modelValue', $event)"
          >
            <div class="row items-center justify-end">
              <QBtn v-close-popup label="Close" color="primary" flat />
            </div>
          </QDate>
        </QPopupProxy>
      </QIcon>
    </template>
  </QInput>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    dateIsSelectable: {
      type: Function,
      default: (dateString) => true,
    },
  },
  data() {
    return {
      dateRules: [(v) => !v || /^-?\d+\/[01]\d\/[0-3]\d$/.test(v)],
    }
  },
}
</script>

<style lang="scss" module>
.input {
  flex: 1;
  min-width: 110px;
  max-width: 240px;
  margin: 5px;
}
</style>
