<template>
  <slot
    v-bind="{
      online: online,
      presenceKey: tickTockKey,
    }"
  >
  </slot>
</template>

<script setup>
import { Notify } from "quasar"
import { useOnline } from "@vueuse/core"

const offlineListener = ref()
const onlineListener = ref()
const online = useOnline()
const refreshPrompt = ref()
const offlineNotification = ref()
const updateListener = ref()
const tickTockKey = ref(0)
const refreshing = ref(false)

watch(
  () => online.value,
  (newValue, oldValue) => {
    if (newValue === false) {
      showOfflineNotification()
    } else if (newValue === true && oldValue === false) {
      showPromptToRefresh()
    }
  }
)

onMounted(() => {
  if ("serviceWorker" in navigator) {
    updateListener.value = document.addEventListener(
      "swUpdated",
      showUpdateNotification,
      { once: true }
    )

    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (refreshing.value) return
      refreshing.value = true
      window.location.reload()
    })
  }
})
const showOfflineNotification = () => {
  if (refreshPrompt.value) {
    refreshPrompt.value()
    refreshPrompt.value = undefined
  }
  offlineNotification.value = Notify.create({
    message: "Offline",
    // TODO: change this, implement bg-opposite, text-opposite classes or w/e.
    color: "black",
    timeout: 0,
  })
}
const showPromptToRefresh = () => {
  if (offlineNotification.value) {
    offlineNotification.value()
    offlineNotification.value = undefined
  }
  refreshPrompt.value = Notify.create({
    message: "Connected",
    actions: [
      {
        icon: "refresh",
        color: "white",
        handler: handleRefresh,
      },
    ],
    // TODO: change this to something nicer.
    color: "positive",
    timeout: 0,
  })
}
const showUpdateNotification = (event) => {
  const registration = event.detail
  Notify.create({
    message: "App updated! Click here to apply update.",
    actions: [
      {
        icon: "refresh",
        color: "white",
        handler: () => handleActivateUpdate(registration),
      },
    ],
    color: "black",
    timeout: 0,
  })
}
const handleActivateUpdate = (registration) => {
  registration.waiting.postMessage({ type: "SKIP_WAITING" })
}
const handleRefresh = () => {
  // swaps between 1 and 0, don't need anything more.
  tickTockKey.value = +!tickTockKey.value

  if (refreshPrompt.value) {
    refreshPrompt.value()
    refreshPrompt.value = undefined
  }
}
</script>
