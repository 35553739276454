import { HTTP as AndroidHTTP } from "@ionic-native/http"
import { createEventHook } from "@vueuse/core"
import { Storage } from "@capacitor/storage"

const STATE = {
  initializing: "initializing",
  ready: "ready",
  validating: "validating",
  sending: "sending",
  confirmed: "confirmed",
}

type PaxTransactionRequestType = "REFUND" | "SALE" | "REVERSAL"

interface PaxRequest {
  transType: PaxTransactionRequestType
  amountTrans: number
  language: string
}

interface PaxRequestRefund extends PaxRequest {
  retrievalReferenceNumber: string
}

interface PaxRequestSale extends PaxRequest {
  amountGratuity: number
  amountCashback: number
  reference: string
  uti: string
}

interface PaxTransactionStatus {
  statusValue: number
  statusDescription: string
}

interface PaxTransactionResponse {
  transType: PaxTransactionRequestType
  transApproved: boolean
  transCancelled: boolean
  uti: string
  amountTrans: number
  amountGratuity: number
  amountCashback: number
  cvmSignRequired: boolean
  cvmPinVerified: boolean
  transCurrencyCode: string
  terminalId: string
  merchantId: string
  softwareVersion: string
  receiptNumber: number
  retrievalReferenceNumber: string
  paymentId: string
  shortPaymentId: string
  responseCode: string
  stan: string
  authorisationCode: string
  merchantTokenId: string
  cardPan: string
  cardExpiryDate: string
  cardStartDate: string
  cardScheme: string
  cardPanSequenceNumber: string
  cardType: string
  emvAid: string
  emvTsi: string
  emvTvr: string
  emvCardholderName: string
  Statuses: Array<PaxTransactionStatus>
}

export const usePaxDeviceRequest = () => {
  const state = ref()
  const tid = ref<string>()
  const uti = ref<string>()

  const isInitializing = ref<boolean>(false)
  const isInitialized = ref<boolean>(false)

  const initialize = async () => {
    if (isInitialized.value) return
    try {
      await AndroidHTTP.setServerTrustMode("nocheck")
      AndroidHTTP.setDataSerializer("json")
      const { value } = await Storage.get({ key: "serialNumber" })
      tid.value = value || undefined
      isInitialized.value = true
      return
    } finally {
      isInitializing.value = false
    }
  }

  const isSending = ref<boolean>(false)
  const isSent = ref<boolean>(false)
  const sendErrorHook = createEventHook()
  const sendResultHook = createEventHook()
  const sendRequest = async (params: PaxRequestRefund | PaxRequestSale) => {
    isSending.value = true
    try {
      await initialize()

      const response = await AndroidHTTP.post(
        `https://localhost:8080/POSitiveWebLink/1.0.0/rest/transaction?tid=${tid.value}`,
        params,
        { Authorization: `Bearer 6945595921271780` }
      )
      const data: PaxTransactionResponse = JSON.parse(response.data)
      uti.value = data.uti
      isSent.value = true
      sendResultHook.trigger(data)
    } catch (error) {
      sendErrorHook.trigger(error)
    } finally {
      isSending.value = false
    }
  }

  sendResultHook.on(() => validateRequest())

  const isValidating = ref<boolean>(false)
  const isValidated = ref<boolean>(false)
  const validateErrorHook = createEventHook()
  const validateResultSuccessHook = createEventHook()
  const validateResultFailureHook = createEventHook()
  const validateRequest = async () => {
    isValidating.value = true
    try {
      const response = await AndroidHTTP.get(
        `https://localhost:8080/POSitiveWebLink/1.0.0/rest/transaction`,
        {
          uti: uti.value,
          tid: tid.value,
        },
        { Authorization: `Bearer 6945595921271780` }
      )

      if (response.status === 200) {
        isValidated.value = true
        // Transaction complete
        const data: PaxTransactionResponse = JSON.parse(response.data)
        if (data.transApproved) {
          validateResultSuccessHook.trigger(data)
        } else {
          validateResultFailureHook.trigger(data)
        }
      } else if (response.status === 206) {
        validateRequest()
      }
    } catch (error) {
      validateErrorHook.trigger(error)
    } finally {
      isValidating.value = false
    }
  }

  const anyLoading = computed<boolean>(
    () => isSending.value || isValidating.value
  )
  const cancellable = computed(() => {
    /**
     * Would be good to expose this
     *
     * Something like:
     *
     * sent and verified success: false
     * sent and verified failure: true
     * sent and verifying: false
     * sending: false
     * not sent: true
     *
     * */

    return false
  })

  return reactive({
    sendRequest,
    validateRequest,
    onSendResult: sendResultHook.on,
    onSendError: sendErrorHook.on,
    onValidateResultSuccess: validateResultSuccessHook.on,
    onValidateResultFailure: validateResultFailureHook.on,
    onValidateError: validateErrorHook.on,
    anyLoading,
    isSending: readonly(isSending),
    isValidating: readonly(isValidating),
  })
}
