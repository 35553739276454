<template>
  <QCard flat :class="$style.card">
    <template v-if="loading">
      <BaseLoadingSpinner />
    </template>
    <template v-else-if="step === 1 && paymentType === ''">
      <div class="q-pa-sm">
        <CustomerDetailsForm
          :errors="customerFormErrors"
          @submit="createOrder"
        />
      </div>
    </template>
    <template v-else-if="step === 2">
      <template v-if="paymentType === 'card'">
        <div class="q-pa-sm">
          <div :class="$style.heading"> Receive Card Payment </div>
          <template v-if="sendingPaymentFailed || paymentFailed">
            <QBtn
              color="primary"
              unelevated
              size="19px"
              :class="$style.button"
              :loading="sendingPaymentLoading"
              :disable="sendingPaymentLoading"
              @click="sendPaymentToDevice"
            >
              Retry Payment
            </QBtn>
          </template>
          <template v-else-if="paymentSent">
            <QBtn
              color="primary"
              unelevated
              size="19px"
              :class="$style.button"
              :loading="verifyLoading"
              :disable="verifyLoading"
              @click="retrievePaymentStatus"
            >
              Verify Payment
            </QBtn>
          </template>
          <template v-else>
            <BaseLoadingSpinner />
          </template>
          <!-- Disabled if payment has been sent and hasn't failed -->
          <QBtn
            color="primary"
            unelevated
            label="Cancel"
            :class="$style.button"
            :disable="
              anyDeviceRequestLoading ||
              (paymentSent && !(sendingPaymentFailed || paymentFailed))
            "
            @click="cancel"
          />
        </div>
      </template>
      <template v-else-if="paymentType === 'cash'">
        <div :class="$style.cardTop">
          <div :class="$style.calcHeading"> CASH PAYMENT </div>
          <div :class="$style.cardTopLine">
            <div> ORDER: </div>
            <div> #{{ currentOrderObject.orderNumber }} </div>
          </div>
          <div :class="$style.cardTopLine">
            <div> TOTAL COST: </div>
            <div>
              {{ formatCost(currentOrderObject.totalCost) }}
            </div>
          </div>
          <div :class="[$style.cardTopLine, $style.greyText]">
            <div> CASH TENDERED: </div>
            <div> {{ formatCost(cashTendered) }} </div>
          </div>
          <div :class="$style.cardTopLine">
            <div> Change: </div>
            <div>
              {{
                formatCost(
                  Math.max(0, cashTendered - currentOrderObject.totalCost)
                )
              }}
            </div>
          </div>
        </div>

        <CalculatorInput v-model="cashTendered" pound-buttons />

        <div class="text-center q-pa-xs">
          <QBtn
            color="primary"
            unelevated
            size="19px"
            label="PAY NOW"
            :class="$style.payButton"
            @click="pay({ type: 'cash', amount: cashTendered })"
          />
          <QBtn
            unelevated
            label="Cancel"
            size="14px"
            :class="$style.cancelButton"
            @click="cancel"
          />
        </div>
      </template>
      <template v-else>
        <div :class="$style.heading"> Error with Payment Type </div>
      </template>
    </template>
    <template v-else-if="step === 3">
      <div class="q-pa-sm">
        <OrderPopoverReceipt :order="currentOrderObject" @close="$emit('ok')" />
      </div>
    </template>
    <template v-else>
      <div class="q-pa-sm">
        <div :class="$style.heading"> Error Creating Order </div>

        <QBtn
          color="primary"
          unelevated
          label="Cancel"
          :class="$style.button"
          @click="cancel"
        />
      </div>
    </template>

    <!-- <form
      id="checkout-form"
      ref="checkoutForm"
      action="https://gw1.tponlinepayments.com/hosted/modal/"
      method="post"
      :class="$style.checkoutFormHidden"
    >
      <template v-for="(value, key) in checkoutFormFields">
        <input :key="key" type="hidden" :name="key" :value="value" />
      </template>
    </form> -->
  </QCard>
</template>

<script setup>
import Orders from "@/api/orders/orders"
import { Capacitor } from "@capacitor/core"
import { Storage } from "@capacitor/storage"
import * as Sentry from "@sentry/vue";
import { HTTP as AndroidHTTP } from "@ionic-native/http"

import { useShoppingCartStore } from "@/pinia/shoppingCart"
const shoppingCart = useShoppingCartStore()

import { useCashierStore } from "@/pinia/cashier"
const cashierStore = useCashierStore()

import toast from "@/toast"

const emit = defineEmits(["ok", "loading-change"])

const cashTendered = ref(0)
const loading = ref(false)
const step = ref(1)
const id = ref()
const customerFormErrors = ref({})
const paymentType = ref("")
const currentOrderObject = ref({})

const sendingPaymentFailed = ref(false)
const paymentSent = ref(false)
const sendingPaymentLoading = ref(false)
const verifyLoading = ref(false)
const paymentFailed = ref(false)
const uti = ref()

const orderItems = computed(() => {
  return shoppingCart.orderItems
})
const anyDeviceRequestLoading = computed(() => {
  return verifyLoading.value || sendingPaymentLoading.value
})
const conditionalCardStyle = computed(() => {
  return (step.value === 2) & (paymentType.value === "cash")
    ? "border-radius: 35px !important; background: linear-gradient(#cccccc 0%, #cccccc 25%, white 25%, white 100%); width: 300px;"
    : "border-radius: 35px !important; width: 95%;"
})

watch(
  () => loading.value,
  (value) => {
    emit("loading-change", value)
  },
  {
    immediate: true,
  }
)
const cancel = async () => {
  try {
    await Orders.cancel(currentOrderObject.value.id)
    emit("ok")
  } catch(error) {
    Sentry.captureException(error)
  }
}
const pay = async (params) => {
  try {
    const { data } = await Orders.pay(currentOrderObject.value.id, params)
    currentOrderObject.value = data
    if (shoppingCart.method === "in-store") {
      const { data: fulfilledOrder } = await Orders.fulfill(
        currentOrderObject.value.id
      )
      currentOrderObject.value = fulfilledOrder
    }
    shoppingCart.reset()
    step.value = 3
  } catch(error) {
    Sentry.captureException(error)
  }
}

const formatCost = (cost) => {
  return `£${(cost / 100).toFixed(2)}`
}
const sendPaymentToDevice = async () => {
  sendingPaymentFailed.value = false
  paymentSent.value = false
  sendingPaymentLoading.value = true

  if (Capacitor.getPlatform() !== "android") {
    return
  }

  try {
    const params = {
      transType: "SALE",
      amountTrans: currentOrderObject.value.totalCost,
      amountGratuity: 0,
      amountCashback: 0,
      reference: "TEST CARD",
      language: "en_GB",
      uti: currentOrderObject.value.id,
    }

    AndroidHTTP.setDataSerializer("json")

    await new Promise((resolve, reject) =>
      AndroidHTTP.setServerTrustMode(
        "nocheck",
        () => resolve(),
        () => reject()
      )
    )

    const { value: tid } = await Storage.get({ key: "serialNumber" })

    const response = await new Promise((resolve, reject) =>
      AndroidHTTP.post(
        `https://localhost:8080/POSitiveWebLink/1.0.0/rest/transaction?tid=${tid}`,
        params,
        { Authorization: `Bearer 6945595921271780` },
        (response) => resolve(response),
        (error) => reject(error)
      )
    )

    const data = JSON.parse(response.data)
    uti.value = data.uti

    paymentSent.value = true
    retrievePaymentStatus()
  } catch (error) {
    Sentry.captureException(error)

    console.error(error)
    sendingPaymentFailed.value = true
  } finally {
    sendingPaymentLoading.value = false
  }
}
const createOrder = async (detailsParams, paymentType_) => {
  loading.value = true
  const params = {
    ...detailsParams,
    orderItems: orderItems.value,
    priceAdjustments: [],
    source: "tpos",
    payment_type: paymentType_,
    cashier: cashierStore.currentCashier.username,
  }

  try {
    const { data } = await Orders.create(params)
    loading.value = false

    id.value = data.id
    currentOrderObject.value = data
    paymentType.value = paymentType_
    step.value = 2
    if (paymentType_ === "card") {
      await sendPaymentToDevice()
    } else {
      console.log("cash payment")
    }

    paymentType.value = paymentType_

    toast.success({
      message: "Order Created",
    })
  } catch (error) {
    if (error.response) {
      customerFormErrors.value = error.response.data
      toast.error({
        message:
          customerFormErrors.value.postcode || "Please check your details!",
      })
    } else {
      Sentry.captureException(error)
      
      toast.error({
        message: "Sorry, something went wrong making your request!",
      })
    }
  } finally {
    loading.value = false
  }
}
const retrievePaymentStatus = async () => {
  paymentFailed.value = false
  verifyLoading.value = true

  try {
    const { value: tid } = await Storage.get({ key: "serialNumber" })

    await new Promise((resolve, reject) =>
      AndroidHTTP.setServerTrustMode(
        "nocheck",
        () => resolve(),
        () => reject()
      )
    )

    const response = await new Promise((resolve, reject) =>
      AndroidHTTP.get(
        `https://localhost:8080/POSitiveWebLink/1.0.0/rest/transaction`,
        {
          uti: uti.value,
          tid: tid,
        },
        { Authorization: `Bearer 6945595921271780` },
        (response_) => resolve(response_),
        (error) => reject(error)
      )
    )

    if (response.status === 200) {
      // Transaction complete
      const data = JSON.parse(response.data)
      if (data.transApproved) {
        await Orders.pay(currentOrderObject.value.id, {
          uti: uti.value,
          type: "pax_device",
          retrievalReferenceNumber: data.retrievalReferenceNumber,
          cardNumberMask: data.cardPan,
        })
        const { data: fulfilledOrder } = await Orders.fulfill(
          currentOrderObject.value.id
        )
        currentOrderObject.value = fulfilledOrder
        shoppingCart.reset()
        step.value = 3
      } else {
        toast.error({
          message: "Payment failed.",
        })
        paymentFailed.value = true
      }
    } else if (response.status === 206) {
      retrievePaymentStatus()
      // Transaction still pending
    }
  } catch (error) {
    Sentry.captureException(error)
    
    console.log(error)
    toast.error({
      message: "Failed to verify payment",
    })
  } finally {
    verifyLoading.value = false
  }
}
</script>

<style lang="scss" module>
.card {
  width: 95%;
  max-width: 300px;
  border-radius: 2rem !important;
}

.cardTop {
  padding: 0.5rem 1rem;
  background-color: #ccc;
}

.cardTopLine {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.25rem;
  font-size: $text-md;
  font-weight: 600;
  color: white;
}

.greyText {
  color: #707070;
}

.heading {
  margin: 30px 0;
  font-size: 25px;
  color: #e5332a;
  text-align: center;
}

.button {
  width: 100%;
  margin-bottom: 20px;
  font-family: "chevin-pro";
  font-weight: 700;
  border-radius: 15px;
}

.payButton {
  width: 80%;
  font-family: "chevin-pro";
  font-weight: 700;
  border-radius: 15px;

  :global(.q-btn__wrapper) {
    min-height: 0;
  }
}

.cancelButton {
  width: 65%;
  margin-top: 0.5rem;
  font-family: "chevin-pro";
  font-size: 19px;
  font-weight: 700;
  color: white;
  background-color: #9aa6ad;
  border-radius: 15px;

  :global(.q-btn__wrapper) {
    min-height: 0;
  }
}

.checkoutFormHidden {
  display: none;
}

.payWithCardButton {
  width: 100%;
  margin: 0.5rem 0;
}

.calc {
  width: 260px;
  margin: 15px auto 10px;
}

.calcContainer {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 300px;
  padding: 0 20px;
}

.calcButton {
  width: 55px;
  height: 55px;
  font-size: 20px;
  font-weight: 600;
  color: #707070;
  background: #fafafa;
  border-radius: 5px;
  box-shadow: 4px 4px 8px 2px #ccc;
}

.left {
  float: left;
  padding: 3px 0;
}

.right {
  float: right;
  padding: 3px 0;
}

.details {
  font-size: 21px;
}

.greyDetails {
  font-size: 18px;
  font-weight: 600;
  color: white;
}

.calcHeading {
  margin-bottom: 0.5rem;
  font-size: 22px;
  font-weight: 700;
  color: #707070;
  text-align: center;
}
</style>
