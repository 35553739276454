<template>
  <TillInputField :label="label">
    <QInput
      v-bind="$attrs"
      dense
      filled
      bottom-slots
      @keydown.enter.stop="$event.target.blur()"
    >
      <template #after>
        <slot name="after" />
      </template>
    </QInput>
  </TillInputField>
</template>

<script>
export default {
  inheritAttrs: false,
}
</script>

<script setup>
const props = defineProps({
  label: {
    type: String,
    default: "",
  },
})
</script>

<style lang="scss" module>
.label {
}
</style>
