<template>
  <QCard flat :class="$style.cardStyle">
    <QForm greedy class="fit" @submit="createCategory">
      <DialogLayout @cancel="emit('cancel')">
        <template #title>
          {{ createOrEdit }}
        </template>
        <div :class="$style.innerContainer">
          <template v-if="loading">
            <BaseLoadingSpinner />
          </template>
          <template v-else>
            <TillInputText
              v-model="form.name"
              label="Category Name"
              :rules="rules.name"
            />

            <TillInputSelect
              v-model="form.parentCategoryId"
              options-dense
              :options="categoryOptions"
              label="Parent Category"
              emit-value
              map-options
            />

            <BaseInputColor
              v-model="form.backgroundColour"
              :rules="rules.colour"
              label="Background Colour"
            />
          </template>
        </div>

        <template #bottom>
          <QCardActions align="center" class="q-pa-sm" vertical>
            <QBtn
              color="primary"
              unelevated
              :class="$style.button"
              type="submit"
            >
              Save Category
            </QBtn>

            <template v-if="isEdit">
              <QBtn
                color="primary"
                unelevated
                label="Delete Category"
                :class="$style.button"
                @click="checkDeleteCategory"
              />
            </template>
          </QCardActions>
        </template>
      </DialogLayout>
    </QForm>
  </QCard>
</template>

<script setup>
import Category from "@/api/categories"
import DialogLayout from "@/layouts/DialogLayout.vue"
import CategoryAPI from "@/api/categories"
import toast from "@/toast"
import { useCategoryStore } from "@/pinia/categories"

import { useQuasar } from "quasar"
const $q = useQuasar()

const categoryStore = useCategoryStore()
const style = useCssModule()

const props = defineProps({
  initialData: { type: Object, default: () => ({}) },
})
const emit = defineEmits(["loading-change", "update", "cancel"])

const loading = ref(false)

const parentCategory = ref([])

const categoryOptions = computed(() => categoryStore.asOptions())

const form = ref({
  name: undefined,
  parentCategoryId: null,
  backgroundColour: "#65626b",
})

const id = computed(() => props.initialData.id)
const slug = computed(() => props.initialData.slug)
const isEdit = computed(() => !!id.value)

const createOrEdit = computed(() => {
  return props.initialData.id ? "Edit Category" : "Create Category"
})

const rules = computed(() => {
  const colorRegex = /#[\dA-Za-z|]{6}/
  return {
    name: [
      (v) => !!v || "Name is required",
      (v) => v.length < 64 || "Name is too long (maximum 64 characters)",
    ],
    colour: [(v) => colorRegex.test(v) || "Incorrect Hex Colour Format"],
  }
})
watch(
  () => loading.value,
  (value) => {
    emit("loading-change", value)
  },
  {
    immediate: true,
  }
)
onMounted(() => {
  form.value = Object.fromEntries(
    Object.entries(form.value).map(([key, value]) => [
      key,
      props.initialData[key] || value,
    ])
  )
})

const createCategory = async () => {
  loading.value = true
  try {
    const params = form.value

    await (id.value
      ? Category.partialUpdate(slug.value, params)
      : Category.create(params))
    emit("update")
  } catch {
    toast.error({
      message: "Something went wrong creating category",
    })
  } finally {
    loading.value = false
  }
}
const checkDeleteCategory = () => {
  $q.dialog({
    title: "Are you sure?",
    message: "Deleting a category cannot be undone.",
    cancel: {
      outline: true,
      flat: true,
      "text-color": "#e5332a",
    },
    ok: {
      outline: true,
      flat: true,
      "text-color": "#e5332a",
    },
    persistent: true,
    class: style.dialog,
  })
    .onOk(() => {
      deleteCategory()
    })
    .onCancel(() => {})
    .onDismiss(() => {})
}
const deleteCategory = async () => {
  loading.value = true
  try {
    await Category.delete(slug.value)
    emit("update")
  } catch {
    toast.error({
      message: "Something went wrong deleting category",
    })
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss" module>
.cardStyle {
  width: 100%;
  max-width: 30rem;
  height: 100%;
  height: clamp(20rem, 100%, 40rem);
  max-height: 40rem;
}

.button {
  width: 100%;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.5rem;
  font-size: $text-md;
  font-weight: 400;
}

.innerContainer {
  padding: 0.5rem;
}
</style>
