<template>
  <RouterLink :to="{ name: 'tillCashierLogin' }" :class="$style.routerLink">
    <div :class="$style.cashierBadge">
      {{ cashier.username }}
    </div>
  </RouterLink>
</template>

<script setup>
const props = defineProps({
  cashier: {
    type: Object,
    required: true,
  },
})

const { cashier } = toRefs(props)

const backgroundColor = computed(() => cashier.colour || "#c04a89")
</script>

<style lang="scss" module>
.cashierBadge {
  padding: 0.375rem 0.75rem;
  font-size: $text-base-size;
  color: white;
  cursor: pointer;
  background-color: v-bind("backgroundColor");
  border-radius: 0.25rem;
}

.routerLink {
  text-decoration: none;
}
</style>
