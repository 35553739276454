import Menus from "@/api/orders/menus"
import DateUtils from "@/utils/dateUtils"

export default {
  namespaced: true,
  state: () => ({
    nextMenuTime: undefined,
  }),
  mutations: {
    SET_FIELDS(state, { nextMenuTime }) {
      state.nextMenuTime = nextMenuTime
    },
    RESET_FIELDS(state) {
      state.nextMenuTime = undefined
    },
    SET_LOADING(state) {
      state.loading = true
    },
    SET_LOADING_FINISHED(state) {
      state.loading = false
    },
  },
  actions: {
    set({ commit }, client) {
      commit("SET_FIELDS", client)
    },
    async fetchProducts({ commit }) {
      commit("SET_LOADING")
      try {
        const {
          data: { result: nextMenuISO },
        } = await Menus.nextActive()

        const nextMenuTime = DateUtils.parseISO(nextMenuISO)

        commit("SET_FIELDS", {
          nextMenuTime,
        })
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit("SET_LOADING_FINISHED")
      }
    },
    flush({ commit }) {
      commit("RESET_FIELDS")
    },
  },
}
