<template>
  <template v-if="isLoading">
    <BaseLoadingSpinner />
  </template>
  <template v-else>
    <!-- <TestWebLink /> -->
    <div :class="$style.scroll">
      <div :class="$style.grid">
        <template v-for="category in categories" :key="category.slug">
          <div :class="$style.categoryDiv">
            <TillButton
              :top-color="category.backgroundColour"
              @click="categoryClick(category)"
            >
              <template v-slot:top>
                <QItemLabel lines="2">{{ category.name }}</QItemLabel>
              </template>
            </TillButton>
          </div>
        </template>
        <template v-if="edit">
          <div :class="$style.categoryDiv">
            <TillButton top-color="#faa61a" @click="categoryCreate">
              <template v-slot:top>
                <QAvatar icon="add" size="lg" text-color="white" />
              </template>
            </TillButton>
          </div>
        </template>
      </div>
      <div
        v-if="(categories.length > 0 && products.length > 0) || edit"
        style="padding: 5px"
      >
        <QSeparator />
      </div>
      <div :class="$style.grid">
        <template v-for="product in products" :key="product.id">
          <ProductListItem
            :product="product"
            :color="product.color || currentCategory.backgroundColour"
            :edit="edit"
            style="position: relative"
            @edit="productEdit"
          />
        </template>
        <template v-if="edit">
          <ProductListItem
            :product="{ name: 'Add Product' }"
            color="#faa61a"
            :edit="edit"
            @edit="productCreate"
          />
        </template>
      </div>
    </div>
    <div :class="$style.payButtonContainer">
      <QBtn
        color="primary"
        unelevated
        :class="$style.payButton"
        :disable="!shoppingCart.isValid"
        @click="onPayClick"
      >
        Pay {{ shoppingCart.totalCostAsString }}
      </QBtn>
    </div>
  </template>
</template>

<script setup>
import CategoryAPI from "@/api/categories"
import ProductAPI from "@/api/orders/products"
import { usePageTitle } from "@/composables/pageTitle"

import { useCashierStore } from "@/pinia/cashier.js"
import { useTillCategories } from "@/composables/tillCategories"
import ProductFormModal from "@/components/ProductFormModal.vue"
import CategoryFormModal from "@/components/CategoryFormModal.vue"

import { useEventBus } from "@vueuse/core"

import { useQuasar } from "quasar"

const cashierStore = useCashierStore()

import { useShoppingCartStore } from "@/pinia/shoppingCart"
import { useCategoryStore } from "@/pinia/categories"

const categoryStore = useCategoryStore()
const shoppingCart = useShoppingCartStore()

const $q = useQuasar()

const router = useRouter()
const route = useRoute()

const { setPageTitle } = usePageTitle()
setPageTitle("Till")

const props = defineProps({
  cart: { type: Boolean, default: false },
  edit: { type: Boolean, default: false },
  currentCategorySlug: {
    type: [String, null],
    default: undefined,
  },
  navList: {
    type: Array,
    default: () => [],
  },
})

defineEmits(["pay", "checkout"])

const isLoading = ref()

const currentCategorySlug = toRef(props, "currentCategorySlug")

const categoryClick = (category) => {
  if (props.edit) {
    categoryEdit(category)
  } else {
    categorySelect(category)
  }
}

const categoryCreate = () => {
  $q.dialog({
    component: CategoryFormModal,
    componentProps: {
      initialData: {
        parentCategoryId: currentCategory.value?.id || null,
      },
    },
  }).onOk(() => {
    categoryStore.reset()
    validateFetcherData()
  })
}

const categoryEdit = (category) => {
  $q.dialog({
    component: CategoryFormModal,
    componentProps: {
      initialData: {
        ...category,
      },
    },
  }).onOk(() => {
    categoryStore.reset()
    validateFetcherData()
  })
}
const productCreate = () => {
  $q.dialog({
    component: ProductFormModal,
    componentProps: {
      initialData: {
        parentCategoryId: currentCategory.value?.id || null,
      },
    },
  }).onOk(() => validateFetcherData())
}
const productEdit = (product) => {
  $q.dialog({
    component: ProductFormModal,
    componentProps: {
      initialData: {
        ...product,
      },
    },
  }).onOk(() => validateFetcherData())
}

const categoryLoading = ref(false)
const currentCategory = computed(() => fetcherData.value?.currentCategory || {})
const categories = computed(() => fetcherData.value?.categories || [])
const products = computed(() => fetcherData.value?.products || [])

const { data: fetcherData, revalidate } = useTillCategories(
  currentCategorySlug.value
)
const validateFetcherData = () => {
  revalidate.value()
}

const categorySelect = (category) => {
  categoryLoading.value = true
  router.push(`${route.path}/${category.slug}`)
}

const payEventBus = useEventBus("payButton")
const onPayClick = () => {
  payEventBus.emit("on")
}
</script>

<style lang="scss" module>
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 1rem 0.25rem;
  padding: 0 0.25rem;
}

.payButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4rem;
  margin-top: auto;
}

.payButton {
  flex-shrink: 0;
  width: 75%;
  font-family: "chevin-pro";
  font-size: 19px;
  font-weight: 700;
  border-radius: 15px;
}

.categoryDiv {
  width: 100%;
}

.scroll {
  height: calc(100% - 4rem);
  overflow: auto;
}
</style>
