import { defineStore } from "pinia"
import { formatPrice } from "@/utils/currency"
import { nanoid } from "nanoid"

export const costOfItem = ({ product, options, priceOverride }) => {
  return product.hasVariablePrice || product.quickTill
    ? priceOverride
    : product.price +
        Object.values(options)
          .flat()
          .reduce((accumulator, { price }) => accumulator + price, 0)
}

export const costOfItemAsString = (item) => formatPrice(costOfItem(item))

const createOrderItem = (item) => ({
  ...item,
  rootCategoryName: item.product.rootCategoryName,
})

export const useShoppingCartStore = defineStore("shoppingCart", () => {
  const itemsMap = ref(new Map())

  const table = ref()

  /**
   * Expects options as an array.
   *
   */
  const addItem = (item) => {
    itemsMap.value.set(nanoid(), createOrderItem(item))
  }

  const editItem = (itemId, newItem) => {
    if (itemsMap.value.has(itemId)) {
      itemsMap.value.set(itemId, createOrderItem(newItem))
    }
  }

  const removeItem = (itemId) => {
    if (itemsMap.value.has(itemId)) {
      itemsMap.value.delete(itemId)
    }
  }

  const clearItems = () => {
    itemsMap.value.clear()
  }

  const reset = () => {
    clearItems()
  }

  const items = computed(() =>
    itemsMap.value
      ? [...itemsMap.value.entries()].map(([internalId, item]) => ({
          ...item,
          internalId,
        }))
      : []
  )

  const productIdCountMap = computed(() => {
    return items.value.reduce(
      (accumulator, { product }) =>
        accumulator.set(
          product.id,
          accumulator.get(product.id) ? accumulator.get(product.id) + 1 : 1
        ),
      new Map()
    )
  })

  const productIdCount = computed(() => (productId) => {
    return productIdCountMap.value.get(productId)
  })

  /**
   * Intended just for when we submit the shopping cart.
   */
  const orderItems = computed(() => {
    return items.value.map(({ product, options, priceOverride }) => ({
      priceOverride: priceOverride,
      productId: product.id,
      optionalItemIds: options
        ? Object.values(options)
            .flat()
            .map(({ id }) => id)
        : [],
    }))
  })

  const itemsByCategory = computed(() => {
    const keyedByCategoryName = items.value.reduce((accumulator, item) => {
      const categoryName = item.rootCategoryName || "Top Level Products"
      return {
        ...accumulator,
        [categoryName]: accumulator[categoryName]
          ? [...accumulator[categoryName], item]
          : [item],
      }
    }, {})

    return Object.entries(keyedByCategoryName).map(([key, value]) => ({
      label: key,
      items: value,
      price: value.reduce(
        (accumulator, value_) => accumulator + costOfItem(value_),
        0
      ),
    }))
  })

  const totalCostInPence = computed(() => {
    return items.value.reduce(
      (accumulator, item) => accumulator + costOfItem(item),
      0
    )
  })
  const totalCostAsString = computed(() => formatPrice(totalCostInPence.value))

  const hasItems = computed(() => numberOfItems.value > 0)

  const isValid = computed(() => !!totalCostInPence.value)

  const numberOfItems = computed(() => items.value.length)

  const method = ref()

  const setMethod = (methodName) => {
    method.value = methodName
  }

  return {
    items: readonly(items),
    orderItems,
    addItem,
    totalCostInPence,
    totalCostAsString,
    hasItems,
    clearItems,
    itemsByCategory,
    isValid,
    numberOfItems,
    editItem,
    removeItem,
    reset,
    method: readonly(method),
    setMethod,
    productIdCount,
  }
})
