<template>
  <QForm class="q-gutter-y-sm column" @submit="onSubmit">
    <QInput
      v-model="merchantIdentifier"
      label="Merchant Identifier"
      :error="!!errors.merchantIdentifier"
      :error-message="errors.merchantIdentifier"
      :rules="rules.merchantIdentifier"
    />
    <QInput
      v-model="form.email"
      color="primary"
      label="Email address"
      name="email"
      bottom-slots
      :rules="rules.email"
    >
    </QInput>
    <template v-if="resettingPassword">
      <div class="row">
        <QBtn
          label="Go back to log in"
          color="primary"
          no-caps
          flat
          type="submit"
          @click="toggleReset"
        ></QBtn>

        <QBtn
          label="Reset Password"
          color="primary"
          no-caps
          class="q-ml-auto"
          align="right"
          :loading="isSubmitting"
          :disabled="isSubmitting"
          @click="startResetPassword"
        ></QBtn>
      </div>
    </template>
    <template v-else>
      <QInput
        v-model="form.password"
        color="primary"
        label="Password"
        name="password"
        bottom-slots
        :type="showPassword ? 'text' : 'password'"
      >
        <template v-slot:append>
          <QIcon
            :name="showPassword ? 'visibility' : 'visibility_off'"
            class="cursor-pointer"
            @click="showPassword = !showPassword"
          />
        </template>
      </QInput>

      <p :class="$style.forgottenPasswordText">
        Forgotten your password?
        <span :class="$style.link" @click="toggleReset">
          Click here to reset it
        </span>
      </p>

      <QBtn
        label="Login"
        color="primary"
        no-caps
        class="q-ml-auto"
        type="submit"
        align="right"
        :loading="isSubmitting"
        :disabled="isSubmitting"
      ></QBtn>
    </template>
  </QForm>
</template>

<script setup>
import Password from "@/api/password"
import Auth from "@/api/auth"
import toast from "@/toast"
const router = useRouter()

const showPassword = ref(false)
const resettingPassword = ref(false)
const toggleReset = () => {
  resettingPassword.value = !resettingPassword.value
}

const form = ref({
  email: undefined,
  password: undefined,
})

const rules = ref({
  merchantIdentifier: [(v) => !!v || "Merchant identifier is required"],
  email: [(v) => !!v || "Email is required"],
})
const errors = ref({})
const isSubmitting = ref(false)
const merchantIdentifier = ref()
onMounted(() => {
  merchantIdentifier.value = sessionStorage.getItem("merchantIdentifier")
})

const onSubmit = async () => {
  isSubmitting.value = true
  sessionStorage.setItem("merchantIdentifier", merchantIdentifier.value)
  try {
    const res = await Auth.logIn(form.value)
    router.push("/till")
  } catch (error) {
    if (error?.response?.status === 404) {
      errors.value.merchantIdentifier = "Merchant not found"
    } else if (error?.response?.status === 400) {
      toast.error({
        message: "Incorrect email or password.",
      })
    }
    console.log(error)
  }
  isSubmitting.value = false
}

const startResetPassword = async () => {
  isSubmitting.value = true
  try {
    await Password.triggerReset(form.value.email)
    toast.success({
      message: "Password reset email sent. Please check your email.",
    })
    resettingPassword.value = false
  } catch {}
  isSubmitting.value = false
}
</script>

<style module lang="scss">
.link {
  cursor: pointer;

  @include textCol("blue");
}

.forgottenPasswordText {
  font-size: $text-sm;
}
</style>
