<template>
  <QDialog ref="dialogRef" @hide="onDialogHide">
    <BaseDialogCard :class="$style.card">
      <template
        v-if="
          isPreparing || paxDeviceRefundRequest.anyLoading || isUpdatingBackend
        "
      >
        <BaseLoadingSpinner />
      </template>
      <template v-else>
        <template v-if="!pinValidated">
          <QForm class="q-gutter-y-sm" @submit="prepareRefund">
            <QInput v-model="pin" type="number" label="Refund Pin" autofocus />
            <QBtn
              color="primary"
              unelevated
              class="q-ml-auto block"
              type="submit"
            >
              Continue
            </QBtn>
          </QForm>
        </template>
        <template v-else>
          <QForm class="q-gutter-y-sm" @submit="executeRefund">
            <p> Maximum refund: {{ formatCost(maximumRefund) }} </p>
            <QInput
              v-model="amountToRefundModel"
              label="Amount to refund"
              :rules="amountRules"
              no-error-icon
              mask="#.##"
              fill-mask="0"
              reverse-fill-mask
              input-class="text-right"
              prefix="£"
              unmasked-value
              inputmode="numeric"
              autofocus
            >
            </QInput>
            <QBtn
              type="submit"
              color="primary"
              unelevated
              class="q-ml-auto block"
            >
              Refund
            </QBtn>
          </QForm>
        </template>
      </template>
    </BaseDialogCard>
  </QDialog>
</template>

<script>
import { useDialogPluginComponent } from "quasar"
</script>

<script setup>
import Orders from "@/api/orders/orders.js"
import toast from "@/toast"
import { usePaxDeviceRequest } from "@/composables/paxDeviceRequest"
import { useQuasar } from "quasar"

const $q = useQuasar()
const props = defineProps({
  orderId: {
    type: String,
    required: true,
  },
})
const emit = defineEmits(["ok", "hide"])

const paxDeviceRefundRequest = usePaxDeviceRequest()
paxDeviceRefundRequest.onValidateResultSuccess((data) =>
  handleVerifiedRefund({ amount: data.amountTrans })
)
paxDeviceRefundRequest.onValidateResultFailure(() =>
  toast.error({ message: "Failed to verify refund" })
)

paxDeviceRefundRequest.onValidateError((error) => {
  console.error(error)
  toast.error({
    message: "Failed to verify payment",
  })
})

paxDeviceRefundRequest.onSendError((error) => {
  console.error(error)
  toast.error({
    message: "Failed to send refund request to device",
  })
})

const pin = ref("")
const pinValidated = ref(false)
const retrievalReferenceNumber = ref("")
const amountGross = ref()
const amountToRefund = ref(0)
const amountRefundedSoFar = ref(0)
const paymentType = ref()
const isUpdatingBackend = ref(false)
const handleVerifiedRefund = async ({ amount }) => {
  isUpdatingBackend.value = true
  try {
    await Orders.handleCompletedPartialRefund(props.orderId, {
      amountRefunded: amount,
    })
    toast.success({
      message: "Refund processed",
    })
    onDialogOK()
  } catch {
    toast.error({
      message: "Refund completed but failed to update, please retry",
    })
  } finally {
    isUpdatingBackend.value = false
  }
}

const handlePaxRefund = () =>
  paxDeviceRefundRequest.sendRequest({
    transType: "REFUND",
    amountTrans: amountToRefund.value,
    language: "en_GB",
    retrievalReferenceNumber: retrievalReferenceNumber.value,
  })

const handleCashRefund = () => {
  handleVerifiedRefund({ amount: amountToRefund.value })
}
const executeRefund = () => {
  if (paymentType.value === "cash") {
    handleCashRefund()
  } else {
    handlePaxRefund()
  }
}

const amountToRefundModel = computed({
  get: () => `${amountToRefund.value}`,
  set: (value) => {
    amountToRefund.value = +value
  },
})
const amountRules = computed(() => {
  return [
    (v) => (!!v && v > 0) || "Amount to refund must be a positive number",
    (v) =>
      v <= maximumRefund.value ||
      `Can not refund more than ${formatCost(maximumRefund.value)}`,
  ]
})
const maximumRefund = computed(() => {
  return amountGross.value - amountRefundedSoFar.value
})

const isPreparing = ref(false)
const prepareRefund = async () => {
  isPreparing.value = true
  try {
    const { data } = await Orders.prepareRefund(props.orderId, {
      pin: pin.value,
    })
    retrievalReferenceNumber.value = data.retrievalReferenceNumber
    amountGross.value = data.amountGross
    amountRefundedSoFar.value = data.amountRefunded
    paymentType.value = data.paymentType
    pinValidated.value = true
  } catch (error) {
    toast.error({
      message: error.response.data,
    })
  }
  isPreparing.value = false
}

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent()

const formatCost = (cost) => {
  return `£${(cost / 100).toFixed(2)}`
}
</script>

<style lang="scss" module>
.card {
  padding: 0.5rem;
}
</style>
