<template>
  <QDialog ref="dialogRef" @hide="onDialogHide">
    <QCard flat :class="$style.card">
      <QCardSection>
        <h1 :class="$style.title">Change Pin</h1>
        <QForm :class="$style.form" @submit="onSubmit">
          <QInput
            v-model="oldPin"
            color="primary"
            filled
            dense
            label="Old PIN"
            name="oldPin"
            bottom-slots
            autocomplete="off"
            type="number"
            pattern="[0-9]*"
            :rules="pinRules"
            :input-class="[$style.numberInput, !showOldPin && $style.hidePin]"
            :error="!!errors.oldPin"
            :error-message="errors.oldPin"
            length="255"
          >
            <template v-slot:append>
              <QIcon
                :name="showOldPin ? 'visibility' : 'visibility_off'"
                class="cursor-pointer"
                @click="showOldPin = !showOldPin"
              />
            </template>
          </QInput>
          <QInput
            v-model="newPin"
            color="primary"
            filled
            dense
            label="New PIN"
            name="currentPin"
            bottom-slots
            :rules="pinRules"
            :input-class="[$style.numberInput, !showNewPin && $style.hidePin]"
            type="number"
            autocomplete="off"
            length="255"
          >
            <template v-slot:append>
              <QIcon
                :name="showNewPin ? 'visibility' : 'visibility_off'"
                class="cursor-pointer"
                @click="showNewPin = !showNewPin"
              />
            </template>
          </QInput>
          <QBtn
            color="primary"
            label="Change"
            type="submit"
            :loading="submitting"
            :class="$style.button"
          />
        </QForm>
      </QCardSection>
    </QCard>
  </QDialog>
</template>

<script>
import { useDialogPluginComponent } from "quasar"
</script>

<script setup>
import Terminal from "@/api/terminal.js"

import toast from "@/toast"

const emit = defineEmits(["ok", "hide"])

const errors = ref({})
const oldPin = ref("")
const newPin = ref("")
const submitting = ref(false)
const showOldPin = ref(false)
const showNewPin = ref(false)
const pinRules = ref([
  (v) => !!v || "Please enter a PIN",
  (v) => v.length < 255 || "Pin must be fewer than 255 digits",
])

const onSubmit = async () => {
  try {
    await Terminal.setPin(oldPin.value, newPin.value)
  } catch (error) {
    if (error.response.status === 400) {
      errors.value = error.response.data
    }

    return
  }

  toast.success({
    message: "PIN updated!",
  })
  onDialogOK()
}

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent()
</script>

<style lang="scss" module>
.card {
  width: 300px;
  border-radius: 35px !important;
}

.button {
  width: 100%;
  margin: 8px 0;
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  border-radius: 15px;
}

.title {
  margin-bottom: 16px;
  font-size: $text-lg;
  font-weight: 500;
  line-height: 1em;
  text-align: center;
}

.form {
  :global(.q-field__bottom) {
    padding: 4px;
  }
}

.numberInput {
  appearance: textfield;

  &::-webkit-outer-spin-button {
    margin: 0;
    appearance: none;
  }
}

.hidePin {
  -webkit-text-security: disc;
}
</style>
