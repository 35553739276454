<template>
  <QForm ref="paymentForm" greedy @submit="submitOrder">
    <QTabs v-model="tab" indicator-color="#B20B63" style="color: #b20b63">
      <QTab
        v-if="methodList.length > 1"
        name="method"
        label="Method"
        icon="list"
        :class="$style.tab"
      />
      <QTab
        v-if="methodList.length > 1 || method !== 'in-store'"
        name="details"
        label="Details"
        icon="person"
        :class="$style.tab"
      />
      <QTab name="payment" label="Payment" icon="payment" :class="$style.tab" />
    </QTabs>

    <template v-if="tab === 'method'">
      <!-- <div :class="$style.heading"> Method Select </div> -->

      <template v-for="methodOption in methodList" :key="methodOption[1]">
        <QBtn
          color="primary"
          unelevated
          :label="methodOption[1]"
          :class="$style.button"
          @click="methodSelect(methodOption[0])"
        />
      </template>
    </template>
    <template v-else-if="tab === 'details'">
      <br v-if="!['home delivery', 'click and collect'].includes(deliverTo)" />
      <!-- <div :class="$style.heading"> Customer Details </div> -->

      <QSelect
        v-if="method === 'eat-in'"
        v-model="table"
        dense
        options-dense
        filled
        :options="tables"
        label="Table"
        bottom-slots
        :rules="rules.table"
      />

      <template
        v-if="['home delivery', 'click and collect'].includes(deliverTo)"
      >
        <QField
          :value="orderDateTimeSlotDate"
          :rules="rules.orderDateTimeSlotDate"
          :error="!!errors.orderDatetimeSlot"
          :error-message="errors.orderDatetimeSlot"
          borderless
          :input-class="$style.orderDateTimeSlotValue"
          hide-bottom-space
        >
          <template v-slot:control>
            <div class="q-gutter-x-xs" style="">
              <QBtn
                :label="orderDateTimeSlotDate"
                color="primary"
                name="settings_remote"
                :class="$style.button"
                style="margin: 4px 0"
              >
                <QPopupProxy style="color: white; background: #b20b63">
                  <div
                    class="row items-centre q-col-gutter-xs"
                    style="background: white"
                  >
                    <template v-if="deliverTo === 'home delivery'">
                      <div
                        v-for="(slot, index) in homeDeliverySlots"
                        :key="index"
                        class="col-4 q-pa-xs"
                      >
                        <QBtn
                          v-close-popup
                          class="full-width full-height q-py-md"
                          style="color: white; background: #b20b63"
                          size="md"
                          :disabled="slot.totalTimeslotsAvailable === 0"
                          :label="formatLabelDate(slot.date)"
                          @click="setSelectedDate(slot)"
                        />
                      </div>
                    </template>

                    <template v-else-if="deliverTo === 'click and collect'">
                      <div
                        v-for="(slot, index) in clickAndCollectSlots"
                        :key="index"
                        class="col-4 q-pa-xs"
                      >
                        <QBtn
                          v-close-popup
                          class="full-width full-height q-py-md"
                          style="
                            color: white;
                            background: #b20b63;
                            border-radius: 10px;
                          "
                          size="md"
                          :label="formatLabelDate(slot.date)"
                          :disabled="slot.totalTimeslotsAvailable === 0"
                          @click="setSelectedDate(slot)"
                        />
                      </div>
                    </template>
                  </div>
                </QPopupProxy>
              </QBtn>

              <QBtn
                v-if="selectedSlot !== undefined"
                :label="orderDatetimeSlotTime"
                color="primary"
                name="settings_remote"
                :class="$style.button"
                style="margin: 4px 0"
              >
                <QPopupProxy>
                  <div
                    class="row items-centre q-col-gutter-xs"
                    style="background: white"
                  >
                    <div
                      v-for="(timeslot, index2) in selectedSlot.timeslots"
                      :key="index2"
                      class="col-4 q-pa-xs"
                    >
                      <QBtn
                        v-close-popup
                        class="full-width full-height q-py-md"
                        size="md"
                        style="
                          color: white;
                          background: #b20b63;
                          border-radius: 10px;
                        "
                        :label="
                          timeslot.startTime.substring(
                            0,
                            timeslot.startTime.length - 3
                          )
                        "
                        :disabled="timeslot.numberAvailable === 0"
                        @click="setSelectedTimeSlot(timeslot)"
                      />
                    </div>
                  </div>
                </QPopupProxy>
              </QBtn>
            </div>
          </template>
        </QField>
      </template>

      <template v-if="deliverTo === 'home delivery'">
        <div :class="$style.addressContainer">
          <QInput
            v-model="address"
            :class="$style.address"
            dense
            filled
            label="Delivery address"
            :rules="rules.address"
            :input-class="$style.input"
            bottom-slots
          />

          <QInput
            v-model="postcode"
            :class="$style.postcode"
            dense
            filled
            label="Postcode"
            :rules="rules.postcode"
            :input-class="$style.input"
            bottom-slots
          />
        </div>
      </template>

      <QInput
        v-model="name"
        dense
        filled
        label="Name"
        :input-class="$style.input"
        :rules="rules.name"
        bottom-slots
      />
      <QInput
        v-model="phone"
        dense
        filled
        type="tel"
        label="Phone"
        :input-class="$style.input"
        :rules="rules.phone"
        :error="
          errors.customer && errors.customer.phone && !!errors.customer.phone[0]
        "
        :error-message="
          errors.customer && errors.customer.phone && errors.customer.phone[0]
        "
        bottom-slots
      />
      <QInput
        v-model="email"
        dense
        filled
        type="email"
        label="Email"
        :rules="rules.email"
        :input-class="$style.input"
        bottom-slots
      />

      <QInput
        v-model="orderNotes"
        dense
        outlined
        type="textarea"
        label="Notes"
        :rules="rules.email"
        :input-class="$style.input"
        bottom-slots
        maxlength="255"
        rows="2"
        input-style="resize: none;"
        counter
      >
        <template v-slot:hint> </template>
      </QInput>

      <QBtn
        color="primary"
        unelevated
        label="To Payment"
        :class="$style.button"
        @click="detailsCheck"
      />
    </template>
    <template v-else-if="tab === 'payment'">
      <!-- <div :class="$style.heading"> Payment </div> -->
      <QBtn
        color="primary"
        unelevated
        label="Card Machine"
        :class="$style.button"
        :disable="isNotAndroid"
        @click="onPaymentTypeSelect('card')"
      />
      <QBtn
        color="primary"
        unelevated
        label="Cash"
        :class="$style.button"
        @click="onPaymentTypeSelect('cash')"
      />
      <div style="text-align: center">
        <QBtn
          v-if="methodList.length > 1 || method !== 'in-store'"
          unelevated
          label="Back"
          :class="$style.cancelButton"
          @click="tab = 'details'"
        />
      </div>
    </template>
  </QForm>
</template>

<script setup>
import { date as quasarDateLibrary } from "quasar"

import WhoAmI from "@/api/whoAmI"
import HomeDeliverySlotsAPI from "@/api/orders/homeDeliverySlots"
import ClickAndCollectSlotsAPI from "@/api/orders/clickAndCollectSlots"
import { Capacitor } from "@capacitor/core"
import { useStore } from "vuex"
const store = useStore()

import { useShoppingCartStore } from "@/pinia/shoppingCart"
const shoppingCart = useShoppingCartStore()

const props = defineProps({
  errors: {
    type: Object,
    default: () => ({}),
  },
})
const emit = defineEmits(["submit"])

const tab = ref("method")
const method = ref("")
const collectionOptions = ref([])
const days = ref(["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"])

const paymentType = ref("")

const selectedSlot = ref()
const homeDeliverySlots = ref([])
const clickAndCollectSlots = ref([])

const datetime_slot = ref()

const customerId = ref()
const name = ref()
const phone = ref()
const email = ref()
const over_18 = ref(false)
const orderNotes = ref()
const table = ref()
const deliverTo = ref()
const postcode = ref()
const address = ref()
const waitTime = ref()
const time = ref()
const disableDeliverTo = ref(false)
const selectedTimeSlot = ref()
const isNotAndroid = computed(() => {
  return Capacitor.getPlatform() !== "android"
})
const orderDateTimeSlotDate = computed(() => {
  return !selectedSlot.value || !selectedSlot.value.date
    ? "Select Date"
    : formatDate(selectedSlot.value.date)
})
const orderDatetimeSlotTime = computed(() => {
  if (!datetime_slot.value) return "Time"
  let thedate = new Date(datetime_slot.value)
  let formattedString = quasarDateLibrary.formatDate(thedate, "HH:mm")
  return formattedString
})

const tables = computed(() => {
  return store.getters["tables/listActiveTableNames"]
})
const rules = computed(() => {
  return {
    // Must be list of functions that either evaluate to true or the error message to be displayed.
    table: [(v) => !!v || "Table is required"],
    terms: [(v) => v || "You must agree to the terms & conditions to continue"],
    address: [(v) => !!v || "Address is required"],
    postcode: [(v) => !!v || "Postcode is required"],
    deliverTo: [(v) => !!v || "Delivery method is required"],
    orderDateTimeSlotDate: [
      (v) =>
        !["home delivery", "click and collect"].includes(deliverTo.value) ||
        !!v ||
        "Date for collection/delivery is required",
    ],
    orderDatetimeSlotTime: [
      (v) => {
        return ["home delivery", "click and collect"].includes(deliverTo.value)
          ? !!v || "Time for collection/delivery is required"
          : true
      },
    ],
    // name: [(v) => !!v || "Name is required"],
    // phone: [(v) => !!v || "Phone is required"],
    // email: [(v) => !!v || "Email is required"],
  }
})

const methodList = computed(() => {
  return [
    ...(store.state.client.featureEatIn ? [["eat-in", "Eat In"]] : []),

    ...(store.state.client.featureInStore ? [["in-store", "In Store"]] : []),

    ...(store.state.terminal.hospitalityMode
      ? [
          ...(store.state.client.featureTakeaway
            ? [["takeaway", "Takeaway"]]
            : []),
          ...(store.state.client.featureHomeDelivery
            ? [["home-delivery", "Home Delivery"]]
            : []),
          ...(store.state.client.featureClickAndCollect
            ? [["click-and-collect", "Click and Collect"]]
            : []),
        ]
      : []),
  ]
})

const getCollectionOptions = () => {
  collectionOptions.value = []
  deliverTo.value = undefined
  disableDeliverTo.value = false
  datetime_slot.value = undefined
  selectedSlot.value = undefined

  if (store.state.client.featureRestaurantMode) {
    let options = [{ label: "Collect from store", value: "collection" }]
    if (store.state.client.featureDelivery) {
      let deliveryLabel = "Delivery"
      if (
        (!store.state.client.deliveryCostCutoff &&
          store.state.client.deliveryCostCutoff !== 0) ||
        shoppingCart.totalCostInPence < store.state.client.deliveryCostCutoff
      ) {
        deliveryLabel += ` (+£${(store.state.client.deliveryCost / 100).toFixed(
          2
        )})`
      }
      options.push({ label: deliveryLabel, value: "delivery" })
    }

    collectionOptions.value = options
  }
  if (method.value === "home-delivery") {
    deliverTo.value = "home delivery"
    disableDeliverTo.value = true
    collectionOptions.value = [
      { label: "Home Delivery", value: "home delivery" },
    ]
  }

  if (method.value === "click-and-collect") {
    deliverTo.value = "click and collect"
    disableDeliverTo.value = true
    collectionOptions.value = [
      { label: "Click and Collect", value: "click and collect" },
    ]
  }
}

const methodSelect = (newMethod) => {
  method.value = newMethod
  shoppingCart.setMethod(newMethod)
  getCollectionOptions()
  tab.value = "details"
  if (method.value === "in-store") {
    tab.value = "payment"
  }
}

watch(
  () => methodList.value,
  (value) => {
    if (value.length === 1) {
      methodSelect(value.at(0).at(0))
    } else {
      method.value = value.at(0)
      shoppingCart.setMethod(value.at(0))
    }
  },
  {
    immediate: true,
  }
)

onMounted(() => {
  getHomeDeliverySlots()
  getClickAndCollectSlots()
  getWaitTime()
})

const paymentForm = ref()
const detailsCheck = async () => {
  if (!paymentForm.value) return
  const success = await paymentForm.value.validate()
  if (success) {
    tab.value = "payment"
  }
}

const formatLabelDate = (thedate) => {
  let theDate = new Date(thedate)
  let day = days.value[theDate.getDay()]
  let formatted = quasarDateLibrary.formatDate(theDate, "DD/MM")
  return day + " " + formatted
}

const formatDate = (thedate) => {
  let theDate = new Date(thedate)
  let day = days.value[theDate.getDay()]
  let formatted = quasarDateLibrary.formatDate(theDate, "DD/MM")
  return day + " " + formatted
}

const setSelectedTimeSlot = (timeSlot) => {
  time.value = timeSlot.startTime
  selectedTimeSlot.value = timeSlot

  let timeStamp = new Date(selectedSlot.value.date + "T" + time.value)
  let formattedString = quasarDateLibrary.formatDate(
    timeStamp,
    "YYYY-MM-DDTHH:mm:ss.SSSZ"
  )
  datetime_slot.value = formattedString
}
const setSelectedDate = (homeDeliverySlot) => {
  datetime_slot.value = undefined
  selectedSlot.value = homeDeliverySlot
}
const getHomeDeliverySlots = async () => {
  await HomeDeliverySlotsAPI.list().then((response) => {
    homeDeliverySlots.value = response.data
  })
}
const getClickAndCollectSlots = async () => {
  await ClickAndCollectSlotsAPI.list().then((response) => {
    clickAndCollectSlots.value = response.data
  })
}

const onPaymentTypeSelect = (paymentType) => {
  if (!deliverTo.value) {
    deliverTo.value = method.value === "eat-in" ? "table service" : "in store"
  }

  const params = {
    customer: {
      ...(customerId.value && { customerId: customerId.value }),
      name: name.value,
      email: email.value,
      phone: phone.value,
      over_18: over_18.value,
      address: address.value,
      postcode: postcode.value,
    },
    notes: orderNotes.value,
    table: table.value,
    deliverTo: deliverTo.value,
    orderDatetimeSlot: datetime_slot.value,
  }
  emit("submit", params, paymentType)
}
const submitOrder = () => {
  if (!deliverTo.value) {
    deliverTo.value = method.value === "eat-in" ? "table service" : "in store"
  }

  const params = {
    customer: {
      ...(customerId.value && { customerId: customerId.value }),
      name: name.value,
      email: email.value,
      phone: phone.value,
      over_18: over_18.value,
      address: address.value,
      postcode: postcode.value,
    },
    notes: orderNotes.value,
    table: table.value,
    deliverTo: deliverTo.value,
    orderDatetimeSlot: datetime_slot.value,
  }
  emit("submit", params, paymentType.value)
}

const getWaitTime = async () => {
  try {
    const {
      data: { client },
    } = await WhoAmI()
    waitTime.value = client.waitTime
  } catch {}
}
</script>

<style lang="scss" module>
.heading {
  margin: 20px 0;
  font-size: 25px;
  color: #e5332a;
  text-align: center;
}

.cardStyle {
  width: 90%;
  border: 2px solid #e5332a;
}

.addressContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.address {
  display: inline-block;
  flex: 3;
}

.postcode {
  display: inline-block;
  flex: 2;
  margin-left: 1rem;
}

.button {
  width: 100%;
  margin: 8px 0;
  font-family: "chevin-pro";
  font-size: 16px;
  font-weight: 700;
  border-radius: 15px;
}

.checkoutFormHidden {
  display: none;
}

.checkbox {
  margin-bottom: 16px;
}

.terms {
  cursor: pointer;

  @include textCol("primary");
}

.orderDateTimeSlotKey {
  font-size: $text-base-size;
  color: black;
  opacity: 0.87;
}

.cancelButton {
  width: 65%;
  margin: 0.5rem auto 0;
  font-family: "chevin-pro";
  font-size: 13px;
  font-weight: 700;
  color: white;
  background-color: #9aa6ad;
  border-radius: 15px;

  :global(.q-btn__wrapper) {
    min-height: 0;
  }
}

.tab {
  border-radius: 6px;

  :global(.q-tab__label) {
    font-weight: 600;
    color: #65626b;
  }

  :global(.q-tab__indicator) {
    height: 6px;
    border-radius: 0 0 6px 6px;
  }
}
</style>
