<template>
  <OrderList :filters="computedFilters" is-live>
    <template v-slot:filters>
      <QInput
        v-model="filters.search"
        placeholder="Search"
        outlined
        dense
        style="flex: 2; margin-right: 5px"
        color="grey"
      >
        <template v-slot:append>
          <QIcon
            name="close"
            class="cursor-pointer"
            @click="filters.search = ''"
          />
        </template>
      </QInput>
      <QBtn
        label="Filters"
        style="flex: 1"
        dense
        unelevated
        color="grey-6"
        @click="showFilterOptions"
      />
    </template>

    <template v-slot:filter-chips>
      <template v-if="filters.readyOnly">
        <QChip
          removable
          label="READY ONLY"
          color="blue"
          @remove="filters.readyOnly = false"
        />
      </template>
      <template v-if="filters.date">
        <QChip
          removable
          :label="filters.date"
          color="negative"
          @remove="filters.date = undefined"
        />
      </template>
    </template>

    <template v-slot:order="{ order, refreshData }">
      <OrderListItemCollection :order="order" @update="refreshData" />
    </template>
  </OrderList>
</template>

<script setup>
import OrderListFilterDialogCollection from "@/components/OrderListFilterDialogCollection.vue"
import { usePageTitle } from "@/composables/pageTitle.js"
const { setPageTitle } = usePageTitle()
setPageTitle("Collection")

import { useQuasar } from "quasar"
const $q = useQuasar()

const filters = ref({})
const computedFilters = computed(() => ({
  date: filters.value.date,
  deliverTo: ["collection"],
  status: [
    "ready",
    ...(filters.value.readyOnly ? [] : ["collected", "fulfilled"]),
  ],
}))

const showFilterOptions = () => {
  $q.dialog({
    component: OrderListFilterDialogCollection,

    componentProps: {
      ...filters.value,
    },
  }).onOk((newFilters) => {
    filters.value = newFilters
  })
}
</script>

<style lang="scss" module></style>
