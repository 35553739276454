import CategoryAPI from "@/api/categories"
import ProductAPI from "@/api/orders/products"
import LRU from "lru-cache"

const CACHE = new LRU({ max: 256 })

const defaultOptions = {
  dedupingInterval: 60 * 1000,
}

export const STATE = {
  error: Symbol("error"),
  idle: Symbol("idle"),
  loading: Symbol("loading"),
  revalidating: Symbol("revalidating"),
}

export const clearCache = () => CACHE.clear()

const categoryDataFetcher = async (categorySlug) => {
  let products_, categories_, categoryData_
  if (!categorySlug) {
    ;[{ data: categories_ }, { data: products_ }] = await Promise.all([
      CategoryAPI.list(),
      ProductAPI.list({
        version: "1",
      }),
    ])
    categoryData_ = {}
  } else {
    ;({
      data: {
        categoryChildren: categories_,
        productChildren: products_,
        ...categoryData_
      },
    } = await CategoryAPI.retrieve(categorySlug))
  }

  return {
    products: products_,
    categories: categories_,
    currentCategory: categoryData_,
  }
}

export const useTillCategories = (newKey, customOptions) => {
  const options = {
    ...defaultOptions,
    ...customOptions,
  }

  const cacheKey = `categories:${newKey}`
  const cacheKeyDedup = `${cacheKey}_dedup`
  const cachedResponse = CACHE.get(cacheKey)

  const response =
    cachedResponse ||
    reactive({
      data: undefined,
      error: undefined,
      revalidate: undefined,
      state: undefined,
    })

  if (!cachedResponse) CACHE.set(cacheKey, response)

  const load = async (loadOptions = {}) => {
    try {
      if (!loadOptions.force && CACHE.get(cacheKeyDedup)) return

      CACHE.set(cacheKeyDedup, true, { ttl: options.dedupingInterval })

      response.state = response.data ? STATE.revalidating : STATE.loading
      response.data = Object.freeze(await categoryDataFetcher(newKey))
    } catch (error) {
      CACHE.del(cacheKeyDedup)
      response.error = error
      response.state = STATE.error
    }
  }

  load()

  response.revalidate = () => load({ force: true })

  return toRefs(readonly(response))
}
