<template>
  <QForm
    class="q-gutter-y-sm"
    :class="$style.form"
    enctype="multipart/form-data"
    @submit="handleSubmit"
  >
    <PhotoInput v-model="form.photo" label="Change Profile Picture" />
    <BaseFormInput
      v-model="form.firstName"
      label="First Name"
      :rules="rules['firstName']"
      :error="errors.firstName && errors.firstName.length > 0"
      :error-message="errors.firstName && errors.firstName[0]"
      lazy-errors
      maxlength="20"
      bottom-slots
    />
    <BaseFormInput
      v-model="form.lastName"
      label="Last Name"
      lazy-errors
      :rules="rules['lastName']"
      :error="errors.lastName && errors.lastName.length > 0"
      :error-message="errors.lastName && errors.lastName[0]"
      maxlength="20"
      bottom-slots
    />
    <BaseFormInput
      v-model="form.phone"
      label="Phone Number"
      :rules="rules['phone']"
      lazy-errors
      :error="errors.phone && errors.phone.length > 0"
      bottom-slots
      :error-message="errors.phone && errors.phone[0]"
    />

    <!-- Fixes browser autofill problem. Browser finds password field
    then looks at previous fields to find something that might be a login;
    this was finding the phone number field and making an incorrect assumption.
    Next line fixes that for now, but ideally we add in disabled email field so
    password managers can capture the password and email correctly. -->
    <input type="text" style="display: none" />

    <BaseFormInput
      v-if="passwordRequired"
      v-model="form.password"
      label="Password"
      :rules="rules.password"
      :error="errors.password && errors.password.length > 0"
      :error-message="errors.password && errors.password[0]"
      bottom-slots
      type="password"
    />
    <BaseFormInput
      v-if="passwordRequired"
      v-model="form.passwordConfirm"
      label="Confirm Password"
      :rules="rules.passwordConfirm"
      :error="errors.passwordConfirm && errors.passwordConfirm.length > 0"
      :error-message="errors.passwordConfirm && errors.passwordConfirm[0]"
      bottom-slots
      type="password"
    />

    <QField
      ref="toggle"
      :model-value="form.terms"
      :rules="rules.terms"
      borderless
      dense
    >
      <template v-slot:control>
        <QCheckbox
          v-model="form.terms"
          color="secondary"
          :rules="rules.terms"
        />
        <div class="col">
          By checking this you confirm that you have read and agree to the
          <a :class="$style.terms" @click="showTermsDialog">
            Terms and Conditions
          </a>
        </div>
      </template>
    </QField>

    <FormActions :loading="submitting" @cancel="handleCancel">
      <template v-slot:submit-label>
        <slot name="submit-label"> </slot>
      </template>
    </FormActions>
  </QForm>
</template>

<script setup>
import TermsDialog from "@/components/forms/TermsDialog.vue"

import { useQuasar } from "quasar"
const $q = useQuasar()

const props = defineProps({
  initial: {
    type: Object,
    default: () => ({}),
  },
  errors: {
    type: Object,
    default: () => ({}),
  },
  passwordRequired: {
    type: Boolean,
    default: false, // Boolean always default false please.
  },
  submitting: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(["submit", "cancel"])
const form = ref({
  firstName: undefined,
  lastName: undefined,
  phone: undefined,
  photo: undefined,
  password: undefined,
  passwordConfirm: undefined,
  registration: undefined,
  postcode: undefined,
  terms: false,
})
const rules = computed(() => {
  return {
    terms: [
      (v) => !!v || "You must agree to the terms & conditions to continue",
    ],
    firstName: [(v) => !!v || "First Name is required"],
    lastName: [(v) => !!v || "Last Name is required"],
    // company: [(v) => !!v || "Company is required"],
    phone: [],
    ...(props.passwordRequired
      ? {
          password: [(v) => !!v || "Password is required"],
          passwordConfirm: [
            (v) => v === form.value.password || "Passwords must match",
          ],
        }
      : {}),
  }
})
watch(
  () => props.initial,
  (value) => {
    form.value = { ...form.value, ...value }
  },
  { immediate: true, deep: true }
)

const showTermsDialog = () => {
  $q.dialog({
    component: TermsDialog,
  })
    .onOk(() => {
      console.log("!OKAY")
      form.value.terms = true
    })
    .onCancel(() => {
      // form.value.terms = false
    })
    .onDismiss(() => {
      // form.values.terms = false
    })
}
const handleSubmit = () => {
  let formData = new FormData()
  const { photo, tags, ...jsonData } = {
    ...form.value,
  }
  for (const k of Object.keys(jsonData))
    jsonData[k] && formData.append(k, jsonData[k])

  tags && tags.length > 0 && formData.append("tags", tags)
  photo && !isURL(photo) && formData.append("photo", photo)

  emit("submit", formData)
}
const handleCancel = () => {
  emit("cancel")
}
const isURL = (photo) => {
  if (typeof photo !== "string") return
  return photo.startsWith("/") || photo.startsWith("http")
}
</script>

<style lang="scss" module>
.terms {
  cursor: pointer;

  @include textCol("primary");
}

.form {
  max-width: 25rem;
  margin: auto;
}

.avatar {
  display: block !important;
  margin: auto;
}

.photoUploadInput {
  display: none;
}

.image {
  object-fit: cover;
}
</style>
