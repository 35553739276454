<template>
  <QCard flat :class="$style.cardStyle">
    <QForm greedy :class="$style.form" @submit="createProduct">
      <DialogLayout @cancel="emit('cancel')">
        <template #title>
          {{ createOrEdit }}
        </template>
        <div :class="$style.innerContainer">
          <template v-if="loading">
            <BaseLoadingSpinner />
          </template>
          <template v-else>
            <TillInputText
              v-model="form.name"
              label="Product Name*"
              :rules="rules.name"
            />

            <TillInputSelect
              v-model="form.parentCategoryId"
              options-dense
              :options="categoryOptions"
              label="Parent Category"
              emit-value
              map-options
            />

            <TillInputText v-model="form.description" label="Description" />

            <div :class="$style.priceInputRow">
              <TillInputText
                v-model.number="form.price"
                label="Price*"
                :rules="rules.price"
                class="full-width"
                mask="#.##"
                fill-mask="0"
                reverse-fill-mask
                input-class="text-right"
                prefix="£"
                unmasked-value
                inputmode="numeric"
              >
                <template #after>
                  <label :class="$style.variablePriceInputLabel">
                    {{ form.hasVariablePrice ? "Variable" : "Fixed" }}

                    <QToggle v-model="form.hasVariablePrice" size="sm" />
                  </label>
                </template>
              </TillInputText>
            </div>

            <BaseInputColor
              v-model="form.selfColor"
              :rules="rules.color"
              label="Background Colour"
            />

            <!-- <QInput
            v-model="form.measure"
            dense
            filled
            label="Measure (e.g. 25ml)"
            :input-class="$style.input"
            bottom-slots
          /> -->

            <TillInputText
              v-model.number="form.stock"
              type="number"
              label="Stock"
              :rules="rules.stock"
            />

            <template v-if="showVatInput">
              <TillInputVat v-model="form.vatRate" />
            </template>
          </template>
        </div>

        <template #bottom>
          <QCardActions align="center" class="q-pa-sm" vertical>
            <QBtn
              color="primary"
              unelevated
              :class="$style.button"
              type="submit"
            >
              Save product
            </QBtn>

            <template v-if="isEdit">
              <QBtn
                color="primary"
                unelevated
                label="Delete Product"
                :class="$style.button"
                @click="checkDeleteProduct"
              />
            </template>
          </QCardActions>
        </template>
      </DialogLayout>
    </QForm>
  </QCard>
</template>

<script setup>
import Product from "@/api/orders/products"
import DialogLayout from "@/layouts/DialogLayout.vue"
import CategoryAPI from "@/api/categories"
import { useCategoryStore } from "@/pinia/categories"
import { useStore } from "vuex"

import { useQuasar } from "quasar"

import toast from "@/toast"

const $q = useQuasar()
const store = useStore()
const categoryStore = useCategoryStore()
const style = useCssModule()

const props = defineProps({
  initialData: { type: Object, default: () => ({}) },
})

const emit = defineEmits(["loading-change", "update", "cancel"])

const loading = ref(false)

const parentCategory = ref({})
const categoryOptions = computed(() => categoryStore.asOptions())

const attributeList = ref([])

const showVatInput = computed(() => store.state.client.vatRegistered)

const form = ref({
  name: undefined,
  parentCategoryId: null,
  description: undefined,
  price: 0,
  hasVariablePrice: false,
  measure: undefined,
  stock: 0,
  vatRate: null,
  selfColor: undefined,
})
const id = computed(() => props.initialData.id)
const isEdit = computed(() => !!id.value)

const createOrEdit = computed(() => {
  return isEdit.value ? "Edit Product" : "Create Product"
})

const rules = computed(() => {
  const colourRegex = /#[a-z]*/
  return {
    name: [
      (v) => !!v || "Name is required",
      (v) => v.length < 64 || "Name is too long (maximum 64 characters)",
    ],
    price: [(v) => v >= 0 || "Price can't be negative"],
    stock: [(v) => v >= 0 || "Stock can't be negative"],
    menuIds: [(v) => v.length > 0 || "Must select at least 1 time slot"],
    colour: [(v) => colourRegex.test(v) || "Incorrect Hex Colour Format"],
  }
})

watch(
  () => loading.value,
  (value) => {
    emit("loading-change", value)
  },
  { immediate: true }
)

onMounted(async () => {
  loading.value = true
  await getAttributeList()
  loadInitialData()
  loading.value = false
})
const loadInitialData = async () => {
  form.value = Object.fromEntries(
    Object.entries(form.value).map(([key, value]) => [
      key,
      props.initialData[key] || value,
    ])
  )
}

const getAttributeList = async () => {
  await store.dispatch("attributes/fetchAttributes")
  attributeList.value = await store.getters["attributes/listAttributes"]()
}

const createProduct = async () => {
  loading.value = true
  try {
    const params = form.value

    await (isEdit.value
      ? Product.partialUpdate(id.value, params)
      : Product.create(params))

    emit("update")
  } catch {
    toast.error({
      message: "Something went wrong creating product",
    })
  } finally {
    loading.value = false
  }
}
const checkDeleteProduct = () => {
  $q.dialog({
    title: "Are you sure?",
    message: "Deleting a product cannot be undone.",
    cancel: {
      outline: true,
      flat: true,
      "text-color": "#e5332a",
    },
    ok: {
      outline: true,
      flat: true,
      "text-color": "#e5332a",
    },
    persistent: true,
    class: style.dialog,
  })
    .onOk(() => {
      deleteProduct()
    })
    .onCancel(() => {})
    .onDismiss(() => {})
}
const deleteProduct = async () => {
  loading.value = true
  try {
    await Product.delete(id.value)
    emit("update")
  } catch {
    toast.error({
      message: "Something went wrong deleting product",
    })
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss" module>
.cardStyle {
  width: 100%;
  max-width: 30rem;
  height: 100%;
  height: clamp(20rem, 100%, 40rem);
}

.innerContainer {
  padding: 0.5rem;
}

.button {
  width: 100%;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.5rem;
  font-size: $text-md;
  font-weight: 400;
}

.form {
  width: 100%;
  height: 100%;
}

.priceInputRow {
  display: flex;
  flex-wrap: nowrap;
  align-items: bottom;
  justify-content: space-between;
}

.variablePriceInputLabel {
  width: 3rem;
  font-size: $text-xs;
  text-align: center;
}
</style>
