import MenuTimes from "@/api/orders/menuTimes"

export default {
  namespaced: true,
  state: () => ({
    menuTimes: [],
  }),
  mutations: {
    SET_FIELDS(state, { menuTimes }) {
      state.menuTimes = menuTimes
    },
  },
  actions: {
    async fetchMenuTimes({ commit }) {
      try {
        let {
          data: { results: menuTimes },
        } = await MenuTimes.list()
        commit("SET_FIELDS", { menuTimes: menuTimes })
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
  getters: {
    listMenuTimes: (state) => () => {
      return state.menuTimes
    },
  },
}
