<template>
  <QCard :class="$style.card">
    <DialogLayout @cancel="emit('cancel')">
      <template #title>
        <template v-if="orderHasLoaded">
          {{ `Order #${order.orderNumber}` }}
        </template>
      </template>
      <QTabs
        v-model="selectedTab"
        :class="$style.tabs"
        :active-class="$style.activeTab"
        dense
        no-caps
      >
        <template v-for="tab in tabs" :key="tab.name">
          <QTab :name="tab.name">
            {{ tab.label }}
          </QTab>
        </template>
      </QTabs>
      <QTabPanels v-model="selectedTab" :class="$style.tabPanels">
        <template v-for="tab in tabs" :key="tab.name">
          <QTabPanel :name="tab.name">
            <template v-if="orderHasLoaded">
              <Component
                :is="tab.component"
                :order="order"
                @refresh="retrieveOrder"
              />
            </template>
          </QTabPanel>
        </template>
      </QTabPanels>
    </DialogLayout>
  </QCard>
</template>

<script setup>
import OrderPopoverOrderDetails from "@/components/OrderPopoverOrderDetails.vue"
import OrderPopoverReceipt from "@/components/OrderPopoverReceipt.vue"
import Orders from "@/api/orders/orders"
import OrderPopoverPaymentDetails from "@/components/OrderPopoverPaymentDetails.vue"
import DialogLayout from "@/layouts/DialogLayout.vue"
const props = defineProps({
  orderId: {
    type: [Number, String],
    required: true,
  },
  initialTab: {
    type: String,
    default: "orderDetails",
  },
  order: {
    type: Object,
    default: undefined,
  },
})

const emit = defineEmits(["hide", "cancel"])

const tabs = [
  {
    name: "orderDetails",
    label: "Order Details",
    component: OrderPopoverOrderDetails,
  },
  {
    name: "receipt",
    label: "Receipt",
    component: OrderPopoverReceipt,
  },
  {
    name: "payment",
    label: "Payment",
    component: OrderPopoverPaymentDetails,
  },
]

const selectedTab = ref(props.initialTab)

const orderIsLoading = ref(false)
const orderHasLoaded = ref(false)
const order = ref({})
const retrieveOrder = async () => {
  orderIsLoading.value = true
  try {
    const { data } = await Orders.retrieve(props.orderId, { all: true })
    order.value = data
    orderHasLoaded.value = true
  } catch (error) {
    emit("cancel")
    throw error
  } finally {
    orderIsLoading.value = false
  }
}
onMounted(() => {
  if (props.order) {
    orderHasLoaded.value = true
    order.value = props.order
  } else {
    retrieveOrder()
  }
})
</script>

<style lang="scss" module>
.card {
  width: 100%;
  max-width: 30rem;
  height: 100%;
  height: clamp(20rem, 100%, 40rem);
}

.tabs {
  --local-color: 178 11 99;

  width: 100%;
  min-width: 20rem;
  height: 2.5rem;
  font-size: $text-base-size;
  color: rgb(178 11 99);
  color: rgb(var(--local-color) / 87%);
}

.activeTab {
  color: rgb(var(--local-color) / 100%);
}

.tabPanels {
  height: calc(100% - 2.5rem);
}

.actions {
  height: 3rem;
}
</style>
