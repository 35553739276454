import { defineStore } from "pinia"
import { useSWRVCache, STATE as CacheState } from "@/composables/useSWRVCache"
import { BaseApi } from "@/api"

const fetchQuickTillProducts = async () => {
  const { data } = await BaseApi.get("/orders/quick-till-products/")
  return data
}

export const useQuickTillProducts = defineStore("quickTillProducts", () => {
  const isLoading = computed(() =>
    [CacheState.loading, CacheState.revalidating].includes(cache.state)
  )

  const cache = useSWRVCache("", {
    fetcher: fetchQuickTillProducts,
    baseKey: "quickTillProducts",
  })
  const data = computed(() => cache.data)

  const getById = computed(() =>
    Array.isArray(data.value)
      ? (id) => data.value.find((product) => product.id === id)
      : () => undefined
  )

  // TODO: re-write as plugin
  const reloadFromCache = cache.load

  return {
    data,
    isLoading,
    getById,
    reloadFromCache,
    revalidate: cache.revalidate,
  }
})
