<template>
  <QDrawer
    :model-value="modelValue"
    :width="270"
    show-if-above
    :breakpoint="1230"
    bordered
    overlay
    elevated
    :class="$style.drawer"
    persistent
    @update:model-value="emit('update:modelValue', $event)"
  >
    <QScrollArea class="fit">
      <QList separator :class="$style.drawerText">
        <template v-for="item in routes" :key="item.label">
          <DrawerItem v-bind="item" />
        </template>
      </QList>
    </QScrollArea>
  </QDrawer>
</template>

<script setup>
import Alerts from "@/api/alerts"

import { useStore } from "vuex"
import { useTillConfig } from "@/composables/useTillConfig"
const store = useStore()

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(["update:modelValue"])
const unseenAlertCount = ref(0)
const { featureQuickTill, featureFullTill } = toRefs(useTillConfig())
const route = useRoute()

const userHasReportsAccess = computed(() => {
  return (
    ["reporting", "owner", "superadmin"].some((v) =>
      store.state.auth.groups.includes(v)
    ) || !!store.state.auth.deviceSerialNumber
  )
})
const routes = computed(() => {
  let output = [
    ...(!(import.meta.env.VUE_APP_LEGACY === "true")
      ? [
          ...(featureFullTill
            ? [
                {
                  to: "/till",
                  label: "Till",
                  icon: "shopping_cart",
                },
              ]
            : []),
          ...(featureQuickTill
            ? [
                {
                  to: "/quick-till",
                  label: "Quick Till",
                  icon: "point_of_sale",
                },
              ]
            : []),
        ]
      : [
          {
            to: "/",
            label: "Home",
            icon: "o_home",
          },
          ...(store.state.terminal.hospitalityMode ||
          import.meta.env.VUE_APP_LEGACY === "true"
            ? [
                {
                  to: "/kitchen",
                  label: "Kitchen",
                  icon: "restaurant_menu",
                },
              ]
            : []),
        ]),

    {
      to: "/orders",
      label: "Orders",
      icon: "format_list_bulleted",
    },
    ...((store.state.client.featureClickAndCollect ||
      store.state.client.featureTakeaway) &&
    (store.state.terminal.hospitalityMode ||
      import.meta.env.VUE_APP_LEGACY === "true")
      ? [
          {
            to: "/collection",
            label: "Collection",
            icon: "storefront",
          },
        ]
      : []),
    ...((store.state.client.featureHomeDelivery ||
      store.state.client.featureDelivery) &&
    (store.state.terminal.hospitalityMode ||
      import.meta.env.VUE_APP_LEGACY === "true")
      ? [
          {
            to: "/delivery",
            label: "Delivery",
            icon: "o_directions_car",
          },
        ]
      : []),

    ...(userHasReportsAccess.value
      ? [
          {
            to: "/reports",
            label: "Reports",
            icon: "bar_chart",
            notificationCount: unseenAlertCount.value,
            children: [
              {
                to: "/reports/today",
                label: "Today",
              },   
              {
                to: "/reports/revenue",
                label: "Transactions",
              },
              {
                to: "/reports/top-sellers",
                label: "Products",
              },
              {
                to: "/reports",
                label: "Alerts",
              },
            ],
          },
        ]
      : []),
  ]

  return output
})
watch(
  () => props.modelValue,
  (value) => value && getAlertCount()
)

watch(
  () => route,
  (to, from) => {
    emit("update:modelValue", false)
  },
  { deep: true }
)

const getAlertCount = async () => {
  if (!userHasReportsAccess.value) return

  try {
    const { data } = await Alerts.unseenCount()
    unseenAlertCount.value = data
  } catch {
    unseenAlertCount.value = 0
  }
}
</script>

<style lang="scss" module>
.drawerText {
  font-size: 1.25rem;
  font-weight: 400;
}

.drawer {
  color: #222;

  @include dark-mode {
    color: white;
    background-color: $dark-elevation-7;
  }
}
</style>
