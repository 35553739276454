import Endpoint from "@/api/endpoint"
import { BaseApi } from "@/api"
const relativeUrl = "/orders/categories"
const Categories = new Endpoint({
  relativeUrl,
})
Categories.Categories = () => BaseApi.get(`${relativeUrl}`)
Categories.listCategories = () => BaseApi.get(`${relativeUrl}/list`)
Categories.listAllCategories = () => BaseApi.get(`${relativeUrl}/list_all`)
Categories.getCategories = (cat) => BaseApi.get(`${relativeUrl}/retrieve/${cat}`)
Categories.editCategory = (id, params) => BaseApi.post(`${relativeUrl}/${id}/edit/`, params)

export default Categories
