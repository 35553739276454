import Terminal from "@/api/terminal"

export default {
  namespaced: true,
  state: {
    hospitalityMode: false,
  },
  mutations: {
    SET_HOSPITALITY_MODE(state, value) {
      // state.hospitalityMode = value
    },
  },
  actions: {
    async toggleHospitalityMode({ commit, state }) {
      const { data } = await Terminal.setHospitalityMode(!state.hospitalityMode)
      commit("SET_HOSPITALITY_MODE", data.hospitalityMode)
    },
    setHospitalityMode({ commit }, value) {
      commit("SET_HOSPITALITY_MODE", value)
    },
  },
}
