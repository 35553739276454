<template>
  <BaseLoadingSpinner />
</template>

<script setup>
import { logInWithToken } from "@/api/auth"
import { useRouteQuery } from "@vueuse/router"
import toast from "@/toast"
const router = useRouter()
const token = useRouteQuery("token")
const merchantIdentifier = useRouteQuery("merchant")

const isLoggingIn = ref(false)
const handleLogIn = async () => {
  isLoggingIn.value = true
  try {
    sessionStorage.setItem("merchantIdentifier", merchantIdentifier.value)
    const res = await logInWithToken({ token: token.value })
    router.push("/till")
  } catch {
    toast.error({
      message:
        "Failed to log in, link may have expired. Please request a new one.",
    })
    router.push("/request-login")
  } finally {
    isLoggingIn.value = false
  }
}

onMounted(() => handleLogIn())
</script>

<style lang="scss" module></style>
