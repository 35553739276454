<template>
  <div :class="$style.scroll">
    <template v-if="cashierStore.isLoading">
      <BaseLoadingSpinner />
    </template>
    <template v-else>
      <div :class="$style.categoryContainer">
        <template
          v-for="cashierOption in cashierStore.cashiers"
          :key="cashierOption.name"
        >
          <div>
            <TillButton
              :top-color="cashierOption.colour"
              @click="onCashierClick(cashierOption)"
            >
              <template v-slot:top>
                {{ cashierOption.username }}
              </template>
            </TillButton>
          </div>
        </template>
        <template v-if="props.edit">
          <TillButton top-color="#faa61a" @click="createCashier">
            <template v-slot:top>
              <QAvatar icon="add" size="lg" text-color="white" />
            </template>
          </TillButton>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
}
</script>

<script setup>
import { usePageTitle } from "@/composables/pageTitle"
import CashierFormModal from "@/components/CashierFormModal.vue"
import { useQuasar } from "quasar"

import { useCashierStore } from "@/pinia/cashier.js"
const cashierStore = useCashierStore()
const $q = useQuasar()
const { setPageTitle } = usePageTitle()
const route = useRoute()
setPageTitle("Cashier Log In")

const props = defineProps({
  cart: { type: Boolean, default: false },
  edit: { type: Boolean, default: false },
  tileView: { type: Boolean, default: false },
  productDivStyle: { type: String, default: "" },
  navList: { type: Array, default: () => [] },
})

// onMounted(() => cashierStore.cashierLogOut())

const onCashierClick = (cashier) => {
  if (props.edit) {
    $q.dialog({
      component: CashierFormModal,
      componentProps: {
        initial: cashier,
      },
    }).onOk(() => cashierStore.listCashiers())
  } else {
    cashierStore.cashierLogIn(cashier, {
      next: route.query.next || { name: "tillCategoryView" },
    })
  }
}

const createCashier = () => {
  $q.dialog({
    component: CashierFormModal,
    componentProps: {},
  }).onOk(() => cashierStore.listCashiers())
}

onMounted(() => cashierStore.ensureLoaded())
</script>

<style lang="scss" module>
.categoryContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 1rem 0.25rem;
  padding: 0 0.25rem;
}

.categoryDiv {
  width: 100%;
}

.scroll {
  height: 100%;
  overflow: auto;
}

.categoryTitleStyle {
  padding: 0 12px;
  margin-bottom: 1rem;
  font-family: "chevin-pro";
  font-size: 2.5rem;
  font-weight: 600;
  color: #65626b;
}
</style>
