<template>
  <QDialog ref="dialogRef" :maximized="!isDesktop" @hide="onDialogHide">
    <CategoryForm
      v-bind="$attrs"
      @close="onDialogCancel"
      @loading-change="setLoading"
      @update="onDialogOK"
      @cancel="onDialogCancel"
    />
  </QDialog>
</template>

<script>
import { useDialogPluginComponent, useQuasar } from "quasar"
</script>

<script setup>
const isLoading = ref(false)
const setLoading = (value) => {
  isLoading.value = value
}
const emit = defineEmits(["ok", "hide"])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent()

const $q = useQuasar()
const isDesktop = $q.screen.gt.md
</script>
