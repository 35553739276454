<template>
  <div :class="$style.container">
    <template v-if="multiple">
      <template v-for="option in options" :key="option.id">
        <QCheckbox
          :val="option.id"
          :model-value="internalCheckboxModel"
          :class="$style.inputItem"
          :style="conditionalCheckboxStyle(option.id)"
          :color="conditionalColor(option.id)"
          keep-color
          @update:model-value="handleCheckboxInput"
        >
          <QItem style="padding: 0">
            <QItemSection>
              <QItemLabel lines="1" style="text-align: left">
                {{ option.label }}
              </QItemLabel>
            </QItemSection>
            <QItemSection side>
              <QItemLabel
                v-if="option.price"
                :style="conditionalPriceColor(option.id)"
                :class="$style.optionChoicePrice"
              >
                {{ formatPriceModifier(option.price) }}
              </QItemLabel>
            </QItemSection>
          </QItem>
        </QCheckbox>
      </template>
    </template>
    <template v-else>
      <template v-for="option in options" :key="option.id">
        <QRadio
          :val="option.id"
          :model-value="internalRadioModel"
          :class="$style.inputItem"
          :style="conditionalRadioStyle(option.id)"
          :color="conditionalColor(option.id)"
          keep-color
          @update:model-value="handleRadioInput"
        >
          <QItem style="padding: 0">
            <QItemSection>
              <QItemLabel lines="1" style="text-align: left">
                {{ option.label }}
              </QItemLabel>
            </QItemSection>
            <QItemSection side>
              <QItemLabel
                v-if="option.price"
                :style="conditionalPriceColor(option.id)"
                :class="$style.optionChoicePrice"
              >
                {{ formatPriceModifier(option.price) }}
              </QItemLabel>
            </QItemSection>
          </QItem>
        </QRadio>
      </template>
    </template>
  </div>
</template>

<script setup>
const props = defineProps({
  multiple: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
  options: {
    type: Array,
    default: () => [],
  },
})
const emit = defineEmits(["update:modelValue"])

const internalCheckboxModel = ref([])
const internalRadioModel = ref()
const internalOptions = ref([])

watch(
  () => props.modelValue,
  (value) => {
    if (props.multiple) {
      internalCheckboxModel.value = value.map(({ id }) => id)
    } else {
      internalRadioModel.value = value.map(({ id }) => id)[0]
    }
  },
  {
    immediate: true,
    deep: true,
  }
)

onMounted(() => {
  internalOptions.value = [...props.options]
})
const conditionalColor = (id) => {
  if (
    id === internalRadioModel.value ||
    internalCheckboxModel.value.includes(id)
  ) {
    return "white"
  }
  return "primary"
}
const conditionalPriceColor = (id) => {
  if (
    id === internalRadioModel.value ||
    internalCheckboxModel.value.includes(id)
  ) {
    return "color: white;"
  }
  return "color: grey;"
}
const conditionalRadioStyle = (id) => {
  if (id === internalRadioModel.value) {
    return "background-color: #B20B63; color: white;"
  }
  return "background-color: #ededed; color: #B20B63;"
}
const conditionalCheckboxStyle = (id) => {
  if (internalCheckboxModel.value.includes(id)) {
    return "background-color: #B20B63; color: white;"
  }
  return "background-color: #ededed; color: #B20B63;"
}
const handleCheckboxInput = (value) => {
  const optionObjs = value.map((id) => {
    return props.options.find((option) => option.id === id)
  })
  emit("update:modelValue", optionObjs)
}
const handleRadioInput = (value) => {
  const optionObject = props.options.find(({ id }) => id === value)
  emit("update:modelValue", [optionObject])
}
const formatPriceModifier = (price) => {
  const priceString = `£${(Math.abs(price) / 100).toFixed(2)}`
  return price > 0 ? `+${priceString}` : `-${priceString}`
}
</script>

<style lang="scss" module>
.container {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-around;
}

.inputItem {
  display: flex !important;
  height: 2rem;
  margin: 10px 0;
  font-weight: 600;
  border-radius: 10px;

  :global .q-checkbox__label {
    width: calc(100% - 3rem);
  }

  :global .q-radio__label {
    width: calc(100% - 3rem);
  }
}

.optionChoicePrice {
  padding-right: 5px;
  font-size: $text-sm;
}
</style>
