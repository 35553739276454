<template>
  <QDialog ref="dialogRef" :maximized="!isDesktop">
    <QCard flat :class="$style.cardStyle">
      <DialogLayout @cancel="onDialogCancel">
        <template #title> Select Desktop User </template>
        <div :class="$style.container">
          <div :class="$style.listScroll">
            <QList :class="$style.list" separator>
              <template v-for="user in userOptions" :key="user.id">
                <QItem :class="[$style.item]" clickable @click="emitUser(user)">
                  {{ user.email }}
                </QItem>
              </template>
            </QList>
          </div>
        </div>
      </DialogLayout>
    </QCard>
  </QDialog>
</template>

<script>
import { useDialogPluginComponent, useQuasar } from "quasar"
</script>

<script setup>
import DialogLayout from "@/layouts/DialogLayout.vue"

const props = defineProps({
  userOptions: {
    type: Object,
    required: true,
  },
})
const emit = defineEmits(["ok", "hide"])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent()

const $q = useQuasar()
const isDesktop = $q.screen.gt.md

const emitUser = (user) => {
  onDialogOK(user)
}
</script>

<style lang="scss" module>
.cardStyle {
  width: 100%;
  max-width: 30rem;
  height: 100%;
  height: clamp(20rem, 100%, 40rem);
  max-height: 40rem;
}

.item {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: $text-base-size;
  cursor: pointer;
  border-radius: 0.25rem;
}

.selectContainer {
  padding-right: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.price {
  padding-top: 1rem;
  font-size: 20px;
  font-weight: bold;
  color: #b20b63;
  text-align: center;
}

.optionLabel {
  margin-bottom: 8px;
  font-size: $text-md;
  font-weight: bold;
  color: #b20b63;
}

.priceInput {
  font-size: $text-lg;
  font-weight: 600;
}

.bottom {
  height: 6rem;
  padding: 0;
}

.form {
  height: 100%;
}

.listScroll {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.addNewButton {
  display: block;
  width: 100%;
  color: rgb(178 11 99);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: inherit;
  max-height: inherit;
  padding: 0.25rem;
}
</style>
