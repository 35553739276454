import { defineStore } from "pinia"
import { useSWRVCache, STATE as CacheState } from "@/composables/useSWRVCache"
import { BaseApi } from "@/api"

import { useQuickTillProducts } from "@/composables/useQuickTillProducts"

const fetchQuickTillPositions = async () => {
  const { data } = await BaseApi.get("/orders/quick-till-positions/")
  return data
}

export const useQuickTillPositions = defineStore("quickTillPositions", () => {
  const quickTillProducts = useQuickTillProducts()
  const isLoading = computed(() =>
    [CacheState.loading, CacheState.revalidating].includes(cache.state)
  )

  const cache = useSWRVCache("", {
    fetcher: fetchQuickTillPositions,
    baseKey: "quickTillPositions",
  })
  const data = computed<Array | undefined>(() => cache.data)

  const getById = (id) =>
    Array.isArray(data.value)
      ? data.value.find((position) => position.positionId === id)
      : undefined

  const withProducts = computed(() =>
    Array.isArray(data.value)
      ? data.value.map((position) => ({
          ...position,
          product: quickTillProducts.getById(position.productId),
        }))
      : []
  )

  const isUpdating = ref(false)
  const update = async (positionId: number, params) => {
    isUpdating.value = true
    try {
      const res = await BaseApi.put(
        `/orders/quick-till-positions/${positionId}/`,
        params
      )
      cache.revalidate()
      isUpdating.value = false
    } catch (error) {
      throw error
    } finally {
      isUpdating.value = false
    }
  }

  // TODO: re-write as plugin
  const reloadFromCache = cache.load

  return {
    data,
    isLoading,
    getById,
    update,
    isUpdating: readonly(isUpdating),
    withProducts,
    reloadFromCache,
    revalidate: cache.revalidate,
  }
})
