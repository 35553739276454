<template>
  <div :class="$style.container" class="fit">
    <h5 class="q-mb-sm">Sales Report for all products</h5>

    <div :class="$style.filterRow">
      <QInput
        v-model="fromDate"
        :rules="dateRules"
        :class="$style.dateInput"
        label="From Date"
        mask="date"
        dense
        hide-bottom-space
      >
        <template v-slot:append>
          <QIcon name="event" class="cursor-pointer">
            <QPopupProxy
              ref="qDateProxy"
              transition-show="scale"
              transition-hide="scale"
            >
              <QDate v-model="fromDate" :options="fromDateRules" no-unset>
                <div class="row items-center justify-end">
                  <QBtn v-close-popup label="Close" color="primary" flat />
                </div>
              </QDate>
            </QPopupProxy>
          </QIcon>
        </template>
      </QInput>
      <QInput
        v-model="toDate"
        :rules="dateRules"
        :class="$style.dateInput"
        label="To Date"
        mask="date"
        dense
        hide-bottom-space
      >
        <template v-slot:append>
          <QIcon name="event" class="cursor-pointer">
            <QPopupProxy
              ref="qDateProxy"
              transition-show="scale"
              transition-hide="scale"
            >
              <QDate v-model="toDate" :options="toDateRules" no-unset>
                <div class="row items-center justify-end">
                  <QBtn v-close-popup label="Close" color="primary" flat />
                </div>
              </QDate>
            </QPopupProxy>
          </QIcon>
        </template>
      </QInput>

      <QBtn
        :class="$style.filterButton"
        size="md"
        color="primary"
        @click="handleFilterClick"
      >
        go
      </QBtn>
    </div>

    <div :class="$style.summary">
      <QChip size="md"
        >{{ numberOfDays }} {{ numberOfDays === 1 ? "Day" : "Days" }}</QChip
      >
      <QChip size="md">{{ formatPrice(totalRevenue) }}</QChip>
      <QChip size="md">{{ totalSales }} Items</QChip>
    </div>

    <QTable :rows="products" :columns="columns" :filter="filter" row-key="id">
      <template v-if="!android" v-slot:top-right>
        <QBtn
          color="primary"
          icon-right="archive"
          label="Export to csv"
          no-caps
          @click="exportTable"
        />
      </template>
    </QTable>
  </div>
</template>

<script>
export default {
  title: "Products",
}
</script>

<script setup>
import ReportsAPI from "@/api/reports"
import { format, subDays, parse, differenceInDays, parseISO } from "date-fns"
import { exportFile, useQuasar } from "quasar"
import { Capacitor } from "@capacitor/core"
const $q = useQuasar()

const parseEngDate = (dateString) => parse(dateString, "yyyy/MM/dd", new Date())
const parseDate = (d) => parseEngDate(d).toISOString().slice(0, -1)

function wrapCsvValue(value, formatFunction) {
  let formatted = formatFunction !== void 0 ? formatFunction(value) : value

  formatted =
    formatted === void 0 || formatted === null ? "" : String(formatted)

  formatted = formatted.split('"').join('""')
  return `"${formatted}"`
}

const products = ref([])
const columns = ref([
  // {
  //   label: "Category",
  //   name: "parentCategoryName",
  //   field: "parentCategoryName",
  //   sortable: true,
  // },
  {
    label: "Product Name",
    name: "name",
    field: "name",
    sortable: true,
  },
  // {
  //   label: "Measure",
  //   name: "measure",
  //   field: "measure",
  //   sortable: true,
  // },
  {
    label: "Sales Value",
    name: "salesValue",
    field: "amountTaken",
    type: "Number",
    sortable: true,
    format: (value) => `£${(value / 100).toFixed(2)}`,
  },
  {
    label: "Total Sales",
    name: "sales",
    field: "sales",
    type: "Number",
    sortable: true,
  },
])
const loading = ref(true)
const fromDate = ref(format(subDays(new Date(), 7), "yyyy/MM/dd"))
const toDate = ref(format(new Date(), "yyyy/MM/dd"))
const numberOfDays = ref(7)
const dateRules = ref([(v) => !v || /^-?\d+\/[01]\d\/[0-3]\d$/.test(v)])
const filter = ref("")
const android = computed(() => Capacitor.getPlatform() === "android")

onMounted(() => {
  getTopProducts()
})
const getTopProducts = async () => {
  loading.value = true

  await ReportsAPI.topProducts({
    fromDate: parseDate(fromDate.value),
    toDate: parseDate(toDate.value),
  }).then((response) => {
    products.value = response.data.products

    const { fromDate: fromDate_, toDate: toDate_ } = response.data
    numberOfDays.value = differenceInDays(
      parseISO(toDate_),
      parseISO(fromDate_)
    )
  })

  loading.value = false
}

const totalRevenue = computed(() => {
  return products.value.reduce(
    (accumulator, product) => accumulator + product.amountTaken,
    0
  )
})

const totalSales = computed(() => {
  return products.value.reduce(
    (accumulator, product) => accumulator + product.sales,
    0
  )
})
const handleFilterClick = () => {
  getTopProducts()
}
const exportTable = () => {
  // naive encoding to csv format
  const content = [
    ...columns.value.map((col) => wrapCsvValue(col.label)),
    ...products.value.map((row) =>
      columns.value
        .map((col) =>
          wrapCsvValue(
            typeof col.field === "function"
              ? col.field(row)
              : row[col.field === void 0 ? col.name : col.field],
            col.format
          )
        )
        .join(",")
    ),
  ].join("\r\n")

  const exportStatus = exportFile(
    "product-sales-report.csv",
    content,
    "text/csv"
  )

  if (exportStatus !== true) {
    $q.notify({
      message: "Browser denied file download...",
      color: "negative",
      icon: "warning",
    })
  }
}
const formatPrice = (value) => {
  return `£${(value / 100).toFixed(2)}`
}
const toDateRules = (date) => {
  return date <= format(new Date(), "yyyy/MM/dd") && date >= fromDate.value
}
const fromDateRules = (date) => {
  return date <= toDate.value
}
</script>
<style lang="scss" module>
.container {
  padding: 0.25rem;

  :global(.q-table__top) {
    padding: 12px 16px;
  }
}

.filterRow {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.filterButton {
  align-self: center;
  margin-left: 5px;
}

.dateInput {
  flex: 1;
  min-width: 110px;
  max-width: 240px;
  margin: 5px;
}

.summary {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 0;
  font-size: $text-lg;
}
</style>
