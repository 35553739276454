<template>
  <div :class="$style.card">
    <EmployeeForm
      :errors="errors"
      :initial="user"
      :submitting="submitting"
      @submit="handleSubmit"
      @cancel="handleCancel"
    >
      <template v-slot:submit-label> Edit Profile </template>
    </EmployeeForm>
  </div>
</template>

<script>
export default {
  title: "Edit Profile",
}
</script>

<script setup>
import Users from "@/api/users"

const router = useRouter()

import toast from "@/toast"

const user = ref({})
const errors = ref({})
const submitting = ref(false)

onMounted(async () => {
  let { data } = await Users.retrieveSelf()
  user.value = data
})

const handleSubmit = async (validatedForm) => {
  submitting.value = true
  const params = validatedForm
  try {
    await Users.editSelf(params)
    toast.success({
      message: "Profile has been changed",
    })
    router.push("/")
  } catch (error) {
    errors.value = error.response.data
    toast.error({
      message: "Something went wrong. Please check the form and try again.",
    })
  } finally {
    submitting.value = false
  }
}
const handleCancel = async () => {
  router.push("/")
}
</script>

<style lang="scss" module>
.card {
  width: 100%;
  max-width: 35rem;
  padding: 0.25rem;
}
</style>
