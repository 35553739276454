import axios from "axios"
import humps from "humps"

import store from "@/store"

const apiRoot = import.meta.env.VUE_APP_BASE_API

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
axios.defaults.xsrfCookieName = "csrftoken"
axios.defaults.addTrailingSlash = true

const decamelizeThatDoesntBreakFiles = (object) => {
  if (object && !(object instanceof File)) {
    if (Array.isArray(object)) {
      return object.map((item) => decamelizeThatDoesntBreakFiles(item))
    }
    if (object instanceof FormData) {
      let formData = new FormData()
      for (const [key, value] of object.entries()) {
        formData.append(humps.decamelize(key), value)
      }
      return formData
    }
    if (object instanceof Date) {
      return object
    }
    if (typeof object === "object") {
      return Object.fromEntries(
        Object.keys(object).map((next) => [
          humps.decamelize(next),
          decamelizeThatDoesntBreakFiles(object[next]),
        ])
      )
    }
  }
  return object
}

axios.defaults.transformResponse = [
  ...axios.defaults.transformResponse,
  (data) =>
    humps.camelizeKeys(data, function (key, convert) {
      return /^[\d-]+$/.test(key) ? key : convert(key)
    }),
]

axios.defaults.transformRequest = [
  (data) => decamelizeThatDoesntBreakFiles(data),
  ...axios.defaults.transformRequest,
]

const BaseApi = axios.create({
  baseURL: apiRoot,
  withCredentials: true,
})

const TokenApi = axios.create({
  baseURL: `${apiRoot}token/`,
})

const AuthApi = axios.create({
  baseURL: `${apiRoot}/`,
  withCredentials: true,
})

const isBadSessionError = (errorResponse) => {
  return errorResponse.status === 403
}

BaseApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      return Promise.reject(error)
    }
    if (isBadSessionError(error.response)) {
      store.dispatch(
        "auth/logOut",
        "You do not have permission to perform this action"
      )
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

BaseApi.interceptors.request.use(
  (config) => {
    const screenUUID = localStorage.getItem("screen-uuid")
    if (screenUUID) {
      config.headers.Authorization = `Screen ${screenUUID}`
    }
    const merchantIdentifier = sessionStorage.getItem("merchantIdentifier")
    if (merchantIdentifier) {
      config.headers["X-DT-SCHEMA"] = merchantIdentifier
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

AuthApi.interceptors.request.use(
  (config) => {
    const merchantIdentifier = sessionStorage.getItem("merchantIdentifier")
    if (merchantIdentifier) {
      config.headers["X-DT-SCHEMA"] = merchantIdentifier
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export { BaseApi, TokenApi, AuthApi }
