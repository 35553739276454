import "@capacitor/core"
// Always have this import above the App and router imports
import "@/styles/quasar.scss"

import App from "./App.vue"
import router from "./router"
import store from "./store"
import toast from "./toast"
import { createPinia } from "pinia"

import { registerSW } from "virtual:pwa-register"

import materialIcons from "quasar/icon-set/material-icons.js"
import lang from "quasar/lang/en-GB.js"
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css"
import "@quasar/extras/material-icons/material-icons.css"
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css"
import { Quasar, Dialog, LoadingBar, Notify, ClosePopup, Loading } from "quasar"

import datePlugin from "./datePlugin"
import "./registerServiceWorker"

import * as SentryCapacitor from "@sentry/capacitor"
import * as SentryVue from "@sentry/vue"
import { Integrations as SentryIntegrations } from "@sentry/tracing"

import "core-js/es/array/at"

const app = createApp(App)

app.use(Quasar, {
  config: {
    dark: false,
    loadingBar: {
      color: "secondary",
    },
  },
  directives: {
    ClosePopup,
  },
  plugins: {
    Dialog,
    LoadingBar,
    Notify,
    Loading,
  },
  lang: lang,
  iconSet: materialIcons,
})

app.use(createPinia())

app.use(datePlugin)

if (import.meta.VUE_APP_TEST === "e2e") {
  // Ensure tests fail when Vue emits an error.
  app.config.errorHandler = window.Cypress.cy.onUncaughtException
}

app.use(toast)

if (import.meta.env.NODE_ENV !== "development") {
  const versionNumber = "1.1.47.8"
  const releaseName = `tpos-${import.meta.env.MODE}@${versionNumber}`
  const sentryOptions = {
    logErrors: true,
    trackComponents: true,
    attachProps: true,
    release: releaseName
  }
  const dsn = "https://323e59a6f3514e6f9d9993c3f44c45d5@o1338945.ingest.sentry.io/6610601"

  if (Capacitor.getPlatform() === 'android') {
    SentryCapacitor.init({
      dsn,
      init: SentryVue.init
    })
    app.mixin(SentryVue.createTracingMixins(sentryOptions))
    SentryVue.attachErrorHandler(app, sentryOptions)
  } else { 
    SentryVue.init({
      app,
      dsn,
      integrations: [
        new SentryIntegrations.BrowserTracing({
          routingInstrumentation: SentryVue.vueRouterInstrumentation(router),
        }),
      ],
      ...sentryOptions,
    })
  }
}

import { usePageTitle } from "@/composables/pageTitle.js"
const isFunction = (object) => typeof object === "function"
const { setPageTitle } = usePageTitle()
app.mixin({
  created() {
    const title =
      this.$options.title &&
      (isFunction(this.$options.title)
        ? this.$options.title.call(this, this)
        : this.$options.title)
    title && setPageTitle(title)
  },
})

app.use(router)
app.use(store)

app.mount("#app")

if (import.meta.env.VUE_APP_TEST === "e2e") {
  window.__app__ = app
}

const updateSW = registerSW({
  onNeedRefresh() {
    Notify.create({
      message: "App updated! Click here to apply update.",
      actions: [
        {
          icon: "refresh",
          color: "white",
          handler: () => updateSW(),
        },
      ],
      color: "black",
      timeout: 0,
    })
  },
  onRegisterError(error) {
    console.error(error)
  },
  onRegistered(registration) {
    console.log(registration)
  },
})

import { defineCustomElements } from "@ionic/pwa-elements/loader" // <== NEW
import { Capacitor } from "@capacitor/core"

defineCustomElements(window) // <== NEW

export default {
  app,
}
