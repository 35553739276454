<template>
  <div :class="$style.backgroundContainer">
    <QPullToRefresh @refresh="refreshEntirePage">
      <div :class="$style.welcome">
        <div :class="$style.logo">
          <img src="/img/tp_plus_logo.png" />
        </div>
        <div :class="$style.card">
          <QTabs v-model="tab" dense align="justify" narrow-indicator>
            <QTab name="email" label="Email Link" />
            <QTab name="password" label="Password" />
          </QTabs>
          <QTabPanels v-model="tab" animated :class="$style.tabs">
            <QTabPanel name="email">
              <PortalRequestLoginLink />
            </QTabPanel>
            <QTabPanel name="password"> <PortalPasswordLogin /> </QTabPanel>
          </QTabPanels>
        </div>
      </div>
    </QPullToRefresh>
  </div>
</template>

<script>
export default {
  title: "Log In",
}
</script>

<script setup>
const tab = ref('email')

const refreshEntirePage = (done) => {
  location.reload()
  done()
}
</script>

<style lang="scss" module>
.backgroundContainer {
  height: 100vh;
  background-repeat: repeat;
}

.card {
  min-width: 18.75rem;
  max-width: 30rem;
  margin-top: 2rem;
  color: black;
  color: #313149;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(229.99deg, #ee7d00 0%, #e85124 43.48%, #c74b87 100%)
      border-box;
  background-color: white;
  border: 2px solid transparent;
  border-radius: 7px;
}
.welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: radial-gradient(circle, rgb(255 255 255 / 0%) 0%, #fff 90%);

  @include mq(tablet) {
    padding: 20px;
  }
}

.logo {
  max-width: 30rem;
}

.tabs {
  border-radius: 7px;
  overflow: hidden;
}
</style>
