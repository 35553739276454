<template>
  <QForm
    class="q-gutter-y-sm q-pa-md"
    :class="$style.form"
    @submit="handleSubmit"
  >
    <BaseFormInput
      v-model="form.problem"
      :rules="rules['problem']"
      lazy-errors
      :error="errors.problem && errors.problem.length > 0"
      :error-message="errors.problem && errors.problem[0]"
      type="textarea"
      :class="$style.supportQuery"
    />
    <FormActions :loading="submitting" @cancel="handleCancel">
      <template v-slot:submit-label>
        <slot name="submit-label"></slot>
      </template>
    </FormActions>
  </QForm>
</template>

<script setup>
import { useStore } from "vuex"
const store = useStore()

const props = defineProps({
  errors: {
    type: Object,
    default: () => ({}),
  },
  initial: {
    type: Object,
    default: () => ({}),
  },
  submitting: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(["submit", "cancel"])

const form = ref({
  name: undefined,
  email: undefined,
  url: undefined,
  problem: undefined,
})

const rules = computed(() => ({}))

onMounted(() => {
  // load the current user variables.
  form.value.name = store.getters["employee/fullName"]
  form.value.email = store.state.employee.email
  form.value.url = window.location.href
})
const handleSubmit = () => {
  emit("submit", form.value)
}
const handleCancel = () => {
  emit("cancel")
}
</script>

<style lang="scss" module>
.form {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.supportQuery {
  flex: 1;
  max-height: 25rem;

  :global(.q-field__control) {
    flex: 1;
  }
}
</style>
