<template>
  <div :class="$style.container">
    <div :class="$style.scroll">
      <template v-if="alerts.length === 0">
        <div :class="$style.noAlertsMessage">No alerts to display</div>
      </template>

      <template v-for="alert in alerts" :key="alert.id">
        <BusinessAlert
          :class="[alert.seen ? '' : $style.unseenAlert, $style.alert]"
          :alert="alert"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Alerts from "@/api/alerts"

export default {
  title: "Alerts",
  props: {},
  data() {
    return {
      alerts: [],
    }
  },
  async mounted() {
    await this.fetchAlerts()
  },
  methods: {
    async fetchAlerts() {
      try {
        const {
          data: { results },
        } = await Alerts.list()
        this.alerts = results
      } catch {}
    },
  },
}
</script>

<style lang="scss" module>
.container {
  width: 100%;
  height: 100%;
  padding: 0.25rem;
}

.alert {
  margin: 4px 0;
}

.unseenAlert {
  background-color: #00a2ff4f;
  border-radius: 7px;
}

.noAlertsMessage {
  text-align: center;
}

.scroll {
  height: 100%;
  overflow: auto;
}
</style>
